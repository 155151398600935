import { IApplication } from "@shared/interfaces/Application";
import { useState } from "react";
import { QcReviewTriggerName, loanService } from "@services/loan.service";
import { Button } from "react-bootstrap";
import { enableQcPassFail } from "@shared/helpers/application.helper";
import ChoiceModal from "@shared/components/ChoiceModal";
import { toasterService } from "@services/toaster.service";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";

const choices = [
  {
    title: "No",
    value: "no",
    variant: "secondary",
  },
  {
    title: "Yes",
    value: "yes",
    variant: "primary",
  },
];

interface QcReviewOptions {
  busy: boolean;
  id: string | null;
  review: QcReviewTriggerName | null;
  open: boolean;
}

export const QcPassFail = ({
  data,
  reload,
}: {
  data: IApplication | undefined;
  reload: () => void;
}) => {
  const user = useAppSelector(appUserSelector);

  const [qcReviewOptions, setQcReviewOptions] = useState<QcReviewOptions>({
    busy: false,
    id: null,
    review: null,
    open: false,
  });

  const hideConfirmationModal = () => {
    setQcReviewOptions((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleQcReview = async () => {
    if (!qcReviewOptions.review || !data?.appNumber) {
      return;
    }
    try {
      setQcReviewOptions((prev) => ({
        ...prev,
        busy: true,
      }));
      await loanService.addQcReview(
        {
          review: qcReviewOptions.review,
        },
        data?.appNumber
      );
      toasterService.success("QC Review added successfully");
    } finally {
      setQcReviewOptions({
        busy: false,
        id: null,
        review: null,
        open: false,
      });
      reload();
    }
  };

  if (!enableQcPassFail(data, user)) {
    // Only PA and FI should be able access Pass QC and Fail QC
    return null;
  }

  return (
    <div className="d-flex mt-4 gap-3">
      <Button
        className=""
        variant="primary"
        type="button"
        onClick={() => {
          setQcReviewOptions({
            busy: false,
            id: data?.appNumber || null,
            review: QcReviewTriggerName.Pass_QC_Review,
            open: true,
          });
        }}
        disabled={qcReviewOptions?.busy}
      >
        Pass QC
      </Button>
      <Button
        className=""
        variant="danger"
        type="button"
        onClick={() => {
          setQcReviewOptions({
            busy: false,
            id: data?.appNumber || null,
            review: QcReviewTriggerName.Fail_QC_Review,
            open: true,
          });
        }}
        disabled={qcReviewOptions?.busy}
      >
        {/* Need to work on the design of FailQC button */}
        Fail QC
      </Button>
      <ChoiceModal
        choices={choices}
        onChoose={(choice) => {
          if (choice === "no") {
            return hideConfirmationModal();
          }
          if (qcReviewOptions.id) {
            handleQcReview();
          }
        }}
        disabled={qcReviewOptions.busy}
        onHide={() => {
          hideConfirmationModal();
        }}
        show={qcReviewOptions.open}
        title={`Confirm QC Evaluation`}
      >
        Are you sure you want to{" "}
        <strong>
          {qcReviewOptions.review === QcReviewTriggerName.Pass_QC_Review
            ? "Pass"
            : "Fail"}{" "}
          QC
        </strong>{" "}
        for Application {qcReviewOptions?.id}?
      </ChoiceModal>
    </div>
  );
};
