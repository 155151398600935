export enum ApplicationStatus {
  PreQual = "PreQual",
  FullApplication = "Full Application",
  Application = "Application",
  Stipulations = "Submit Stipulations",
  UploadID = "Upload ID",
  UploadContract = "Upload Contract",
  IssueDocs = "Signed Loan Docs",
  WaitingOnDocs = "Waiting On Docs",
  QCReview = "QC Review",
  NTPReview = "NTP Review",
  NTPIssued = "NTP Issued",
}

export enum ApplicationType {
  PreQual = "PreQual",
  Full = "Full",
}

export enum DecisionStatus {
  AutoDeclined = "Auto Declined",
  PrequalDeclined = "Prequal Declined",
  Prequalified = "Prequalified",
  NotPrequalified = "Not Prequalified",
  Declined = "Declined",
  AutoApproved = "Auto Approved",
  Approved = "Approved",
  Refer = "Refer",
  Pending = "Pending",
  RecommendApprove = "Recommend Approve",
  NoDecisionRun = "No Decision Run",
  Expired = "Expired",
  Requested = "Requested",
}

export enum ApplicantStatus {
  Primary = "Primary",
  CoApplicant = "CoApplicant",
}
export enum ApplicantType {
  Borrower = "Borrower",
  CoBorrower = "Co-Borrower",
}
