import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import {
  CITIZENSHIP,
  MOBILE_MASK,
  MONTHS_ARRAY,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import { MyInputMask } from "@shared/components/MyInputMask";
import { useHistory, useParams } from "react-router-dom";
import { loanService } from "@services/loan.service";
import {
  maskMobile,
  unmaskMobile,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { editApplicantAction } from "@store/slices/loan.slice";
import { modifyNewRequestSchema } from "@shared/schema/request";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import { IApplication } from "@shared/interfaces/Application";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";

const ModifyApplicant = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();
  const [requestDetail, setRequestDetail] = useState<IApplication>();
  const history = useHistory();
  const { control, handleSubmit, watch, reset, setValue } = useForm({
    resolver: yupResolver(modifyNewRequestSchema),
  });

  const onSubmit = (event: any) => {
    const commonLoanInfo =
      requestDetail?.loanInformation?.currentLoanVersion?.commonLoanInfo;
    const applicantAddresses = [];
    const currentAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: event.postalCode,
      addressLine1: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };
    applicantAddresses.push(currentAddress);
    if (event.isMailingAdressSame) {
      applicantAddresses.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Mailing",
          label: "Mailing",
          value: 3,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.mailingZipCode,
        addressLine1: event.mailingAddressLine1,
        city: event.mailingCity,
        state: event.mailingState,
      });
    }
    const employment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      employerName: event.employerName,
      monthsEmployed: event.monthsEmployed ? Number(event.monthsEmployed) : 0,
      yearsEmployed: event.yearsEmployed ? Number(event.yearsEmployed) : 0,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };
    const model = {
      content: {
        jsonType: "Applicant",
        applicantType: {
          name: "primary",
          label: "primary",
          value: 1,
        },
        lastName: event.lastName,
        firstName: event.firstName,
        email: event.email,
        isUSCitizen: event.isUSCitizen === "Yes" ? true : false,
        xIsPermanentResident:
          event.isUSCitizen === "Yes"
            ? null
            : event.xIsPermanentResident === "Yes"
            ? true
            : false,
        mobilePhone: unmaskMobile(event.mobilePhone),
        addresses: applicantAddresses,
        employments: [employment],
        xInstallationHomeOwner: 1,

        internalClientId: requestDetail?.internalClientId,
        applicationId: requestDetail?.primaryApplicant?.applicationId,
        applicantId: requestDetail?.primaryApplicant?.applicantId,
      },
      redecisionRequested: 0,
    };

    const loanModel = {
      loanInformation: {
        loanVersions: [
          {
            totalCashPrice:
              requestDetail?.loanInformation?.currentLoanVersion
                ?.amountFinanced,
            commonLoanInfo: {
              xprogramtype: commonLoanInfo?.xprogramtype,
              xInstallationAddressLine:
                commonLoanInfo?.xInstallationAddressLine,
              xInstallationCity: commonLoanInfo?.xInstallationCity,
              xInstallationState: commonLoanInfo?.xInstallationState,
              xInstallationZipCode: commonLoanInfo?.xInstallationZipCode,
              xInstalationAddressSame: commonLoanInfo?.xInstalationAddressSame
                ? true
                : false,
              xStatedInstallationHomeOwner:
                commonLoanInfo?.xStatedInstallationHomeOwner ||
                commonLoanInfo?.xInstalaltionHomeOwner,
              installationResidency: commonLoanInfo?.installationResidency,
            },
          },
        ],
      },
      xInstallationAddressLine: commonLoanInfo?.xInstallationAddressLine,
      xInstallationCity: commonLoanInfo?.xInstallationCity,
      xInstallationState: commonLoanInfo?.xInstallationState,
      xInstallationZipCode: commonLoanInfo?.xInstallationZipCode,
      xInstalationAddressSame: commonLoanInfo?.xInstalationAddressSame
        ? true
        : false,
      xStatedInstallationHomeOwner:
        commonLoanInfo?.xStatedInstallationHomeOwner ||
        commonLoanInfo?.xInstalaltionHomeOwner,
      installationResidency: commonLoanInfo?.installationResidency,
    };
    dispatch(
      editApplicantAction({
        model,
        loanInfo: loanModel,
        appNumber: id,
        history,
        path: "/new-application/confirmation",
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      setRequestDetail(response?.data?.data);
      const primaryAddress =
        response?.data?.data?.primaryApplicant?.addresses.find(
          (x) => x.addressType.label === "Primary"
        );
      const mailingAddress =
        response?.data?.data?.primaryApplicant?.addresses.find(
          (x) => x.addressType.label === "Mailing"
        );
      const applicant = response?.data?.data?.primaryApplicant;
      const coApplicant = response?.data?.data?.coapplicant;

      reset({
        firstName: applicant?.firstName,
        lastName: applicant?.lastName,
        email: applicant?.email,
        mobilePhone: maskMobile(applicant?.mobilePhone || ""),
        isUSCitizen: applicant?.isUSCitizen ? "Yes" : "No",
        ...(typeof applicant?.isUSCitizen == "boolean" &&
          !applicant?.isUSCitizen && {
            xIsPermanentResident: applicant?.xIsPermanentResident
              ? "Yes"
              : "No",
          }),
        grossIncome: applicant?.currentEmployments[0]?.grossIncome,
        xDiffAddressThanApplicant: applicant?.xDiffAddressThanApplicant
          ? "Yes"
          : "No",
        addressLine1: primaryAddress?.addressLine1,
        city: primaryAddress?.city,
        state: primaryAddress?.state,
        postalCode: primaryAddress?.postalCode,
        yearsAtResidence: primaryAddress?.yearsAtResidence,
        monthsAtResidence: primaryAddress?.monthsAtResidence,

        employerName: applicant?.employments[0]?.employerName,
        yearsEmployed: Number(applicant?.employments[0]?.yearsEmployed),
        monthsEmployed: Number(applicant?.employments[0]?.monthsEmployed),
        mailingAddressLine1: mailingAddress ? mailingAddress?.addressLine1 : "",
        mailingCity: mailingAddress ? mailingAddress?.city : "",
        mailingState: mailingAddress ? mailingAddress?.state : "",
        mailingZipCode: mailingAddress ? mailingAddress?.postalCode : "",
        isMailingAdressSame: mailingAddress ? true : false,
        coApplicantEmail: coApplicant?.email,
      });
    };
    getRequestData(id);
  }, [id, reset]);

  const isMailingAdressSame = watch("isMailingAdressSame");
  const isUSCitizen = watch("isUSCitizen");

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={requestDetail as IApplication} />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">Modify Applicant</h3>
        <hr className="text-primary height-2px" />
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Applicant Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="First Name"
              control={control}
              name="firstName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="Last Name"
              control={control}
              name="lastName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormTextField
              placeholder="Email"
              control={control}
              name="email"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <MyInputMask
              control={control}
              defaultValue={""}
              name="mobilePhone"
              mask={MOBILE_MASK}
              placeholder="Mobile Phone"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormSelect
              list={CITIZENSHIP}
              control={control}
              name="isUSCitizen"
              placeholder="Are you a US citizen"
            />
          </Col>
          {isUSCitizen === "No" && (
            <Col xs={12} md={6} className="mt-3">
              <UseFormSelect
                list={CITIZENSHIP}
                control={control}
                name="xIsPermanentResident"
                placeholder="Are you a Permanent Resident?"
              />
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Current Address</h4>
          </Col>
          <Col xs={12}>
            <GoogleAutoComplete
              placeholder="Street"
              control={control}
              name="addressLine1"
              onSelect={(address) => {
                setValue("addressLine1", address.street);
                setValue("city", address.city);
                setValue("state", address.state);
                setValue("postalCode", address.zipCode);
              }}
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="City"
              control={control}
              name="city"
            />
          </Col>
          <Col xs={6} md={3} className="mt-3">
            <UseFormTextField
              placeholder="State"
              control={control}
              name="state"
            />
          </Col>
          <Col xs={6} md={3} className="mt-3">
            <UseFormTextField
              placeholder="Zip Code"
              control={control}
              name="postalCode"
            />
          </Col>
        </Row>
        <Row className="mt-3 d-flex align-items-center">
          <Col xs={4} sm={4} lg={3}>
            <label className="form-check-label">
              Time with Current Residence:
            </label>
          </Col>
          <Col xs={6} sm={4} lg={3}>
            <UseFormSelect
              list={YEARS_ARRAY}
              control={control}
              name="yearsAtResidence"
              placeholder="Years"
              placeholderValue={""}
            />
          </Col>
          <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
            <UseFormSelect
              list={MONTHS_ARRAY}
              control={control}
              name="monthsAtResidence"
              placeholder="Months"
              placeholderValue={""}
            />
          </Col>
        </Row>

        <Row>
          {typeof isMailingAdressSame != "undefined" && (
            <Col xs={12} className="mt-3">
              <UseFormCheck
                control={control}
                controlName="isMailingAdressSame"
                content="My Mailing address is different than my current address."
              />
            </Col>
          )}
          {isMailingAdressSame && (
            <>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Mailing Address</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="mailingAddressLine1"
                  onSelect={(address) => {
                    setValue("mailingAddressLine1", address.street);
                    setValue("mailingCity", address.city);
                    setValue("mailingState", address.state);
                    setValue("mailingZipCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="mailingCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="mailingState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="mailingZipCode"
                />
              </Col>
            </>
          )}
        </Row>

        <Row className="mt-4">
          <Col xs={12} sm={6}>
            <h4 className="font-bold">Annual Income</h4>
            <UserFormCurrencyField
              placeholder="Gross Annual Income"
              control={control}
              name="grossIncome"
              type="number"
              disabled
            />
          </Col>
          <p className="mt-2">
            Please enter annual income, which may include any income that is
            verifiable and reported to the Government, such as employment
            income, social security, pensions, etc.
          </p>
        </Row>
        <Row>
          <Col xs={12}>
            <h4 className="font-bold">
              Employer Information (If Retired, type Retired)
            </h4>
          </Col>
          <Col xs={12}>
            <UseFormTextField
              placeholder="Employer Name"
              control={control}
              name="employerName"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="mt-3">
            <div className="d-flex align-items-center flex-wrap">
              <label className="form-check-label me-3">
                Time with Current Employer / Length of Time Retired:
              </label>
              <div className="d-flex gap-3">
                <div className="width-150px">
                  <UseFormSelect
                    list={YEARS_ARRAY}
                    control={control}
                    name="yearsEmployed"
                    placeholder="Years"
                  />
                </div>
                <div className="width-150px">
                  <UseFormSelect
                    list={MONTHS_ARRAY}
                    control={control}
                    name="monthsEmployed"
                    placeholder="Months"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default ModifyApplicant;
