import { useAuthUserContext } from "@contexts/AuthContext";
import NumberFormat from "@shared/components/NumberFormat";
import { UserAuthStatus } from "@shared/enums/UserState";
import { getProductNameForOfferCard } from "@shared/helpers/application.helper";

import { useQuery } from "@shared/helpers/global.helper";
import { IApplication } from "@shared/interfaces/Application";
import { FullAppOfferSkeleton } from "@shared/skeletons/FullAppOfferSkeleton";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  getRequestAction,
  loanRequestSelector,
  setRequestData,
} from "@store/slices/loan.slice";
import { useLayoutEffect } from "react";
import { Col, Button, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const Confirmation = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();
  let query = useQuery();
  const { authUserStatus } = useAuthUserContext();
  const requestDetail = useAppSelector(
    loanRequestSelector
  ) as IApplication | null;
  const offer =
    requestDetail?.proposedLoanOffer?.offers &&
    requestDetail?.proposedLoanOffer?.offers?.length > 0
      ? requestDetail?.proposedLoanOffer?.offers?.[0]
      : null;

  let token: any = query.get("token");

  useLayoutEffect(() => {
    dispatch(setRequestData(null));
    dispatch(getRequestAction(id));
  }, [dispatch, id]);

  const { productName, productNameInBrackets } = getProductNameForOfferCard(
    offer?.xprogramtype || ""
  );

  return (
    <Col xs={12} className="d-flex justify-content-center mx-auto">
      {!requestDetail ? (
        <FullAppOfferSkeleton />
      ) : (
        <div className="full-app-confirmation-container">
          <p className="text-center">
            <span className="highlight">Congratulations, </span>
            <span className="message">you have been </span>
            <span className="highlight">approved </span>
            <span className="message">for a solar loan</span>
            <span className="highlight">!</span>
          </p>
          <div className="box mt-3">
            <div className="title-container">
              <p className="title">{productName}</p>
              {productNameInBrackets && (
                <p className="card-with-message">{productNameInBrackets}</p>
              )}
              <div className="p-2" />
              <p className="prequalified-for-text d-inline">
                Loan Amount:{" "}
                <p className="amount d-inline">
                  <NumberFormat amount={offer?.maxAmount} />
                </p>
              </p>
            </div>
            <div className="summary-table">
              <Table>
                <thead>
                  <tr>
                    <th className="term-for-full-app">Term</th>
                    <th>Rate</th>
                    <th>
                      Monthly
                      <br />
                      Payment
                    </th>
                    <th className="custom-width-1">
                      Monthly <br />
                      Pmt If Tax <br /> Credit Applied*
                    </th>
                    <th className="custom-width-spacer"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {offer?.xHasIntroPeriod === false && (
                      <td className="term term-for-full-app">
                        Months 1-{offer?.term}
                      </td>
                    )}
                    {offer?.xHasIntroPeriod === true && (
                      <td className="term term-for-full-app">
                        Months 1-{offer?.xIntroTerm}
                      </td>
                    )}

                    <td>
                      <NumberFormat
                        amount={offer?.xInitialRate}
                        isCurrency={false}
                        symbol="%"
                      />
                    </td>
                    <td>
                      <NumberFormat amount={offer?.payment} />
                    </td>
                    <td>
                      <NumberFormat amount={offer?.payment} />
                    </td>
                  </tr>
                  {offer?.xHasIntroPeriod === true && (
                    <tr>
                      <td className="term term-for-full-app">
                        Months {Number(offer?.xIntroTerm) + 1}-
                        {offer?.xFinalPmtDiff === true && (
                          <span> {offer?.term - 1}</span>
                        )}
                        {offer?.xFinalPmtDiff === false && (
                          <span>{offer?.term}</span>
                        )}
                      </td>
                      <td>
                        <NumberFormat
                          amount={offer?.xFinalRate}
                          isCurrency={false}
                          symbol="%"
                        />
                      </td>
                      <td>
                        <NumberFormat amount={offer?.xFullyAmortizedPayment} />
                      </td>
                      <td>
                        <NumberFormat
                          amount={offer?.xFullyAmortizedPaymentWithV}
                        />
                      </td>
                    </tr>
                  )}
                  {offer?.xFinalPmtDiff === true && (
                    <tr>
                      <td className="term">Final Pmt</td>
                      <td>-</td>
                      <td>
                        <NumberFormat
                          amount={offer?.xFinalPaymentWithNoVoluntary}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          amount={offer?.xFinalPaymentWithVoluntary}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="sub-box">
              <p>
                *Assumes tax credit of 30% of loan amount is applied to
                prinicipal by {offer?.xOfferDiscLang}.
              </p>
              <p>Actual Payments may vary.</p>
            </div>
          </div>

          <div className="text-center mt-4">
            <h5 className="mt-2">Approval is valid for 180 days.</h5>
          </div>
          {authUserStatus === UserAuthStatus.Authorized && (
            <div className="mt-5 d-flex justify-content-center mb-5">
              <Link to={`/request-detail/${id}`}>
                <Button>See Next Steps</Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </Col>
  );
};

export default Confirmation;
