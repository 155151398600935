import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UseFormDatePicker } from "@shared/components/UserFormDatePicker";
import {
  CITIZENSHIP,
  INSTALLATION_RESIDENCY,
  MOBILE_MASK,
  MONTHS_ARRAY,
  OWNERSHIP_LIST,
  SSN_MASK,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import {
  maskMobile,
  removeSpaces,
  unmaskMobile,
  onSubmitError,
  getProductsList,
  createUrl,
} from "@shared/helpers/global.helper";
import { switchPrequalToFullApp } from "@store/slices/loan.slice";
import { loanService } from "@services/loan.service";
import { MyInputMask } from "@shared/components/MyInputMask";
import { newRequestSchema } from "@shared/schema/request";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import UserConsent from "@shared/components/UserConsent";
import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { IApplication } from "@shared/interfaces/Application";
import { appUserSelector } from "@store/slices/app.slice";
import { useAuthUserContext } from "@contexts/AuthContext";
import { formatDateForDl4Submission } from "@utils/date";
import { getPrequalifiedOfferFromAppData } from "@shared/helpers/application.helper";
import ConvertApplicationGuard from "@shared/components/ConvertApplicationGuard";

const ConvertToFullApplication = () => {
  const dispatch = useAppDispatch();
  const [requestDetail, setRequestDetail] = useState<IApplication>();
  const history = useHistory();
  const location = useLocation() as {
    pathname: string;
    state: {
      prequalifiedOfferId: number;
    };
  };
  const prequalifiedOfferId = location?.state?.prequalifiedOfferId;
  const user = useAppSelector(appUserSelector);
  const { authUserStatus } = useAuthUserContext();

  let { id } = useParams<{ id: string }>();

  const redirectToApplicationSummary = useCallback(() => {
    history.push(createUrl(`/request-detail/${id}`));
  }, [history, id]);

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    resolver: yupResolver(newRequestSchema),
  });
  const onSubmit = (event: any) => {
    const applicants = [];
    const applicantAddresses = [];

    const currentAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: event.postalCode,
      addressLine1: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };
    applicantAddresses.push(currentAddress);
    if (event.isMailingAdressSame) {
      applicantAddresses.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Mailing",
          label: "Mailing",
          value: 3,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.mailingZipCode,
        addressLine1: event.mailingAddressLine1,
        city: event.mailingCity,
        state: event.mailingState,
      });
    }
    const applicanEmployment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      monthsEmployed: event.monthsEmployed ? Number(event.monthsEmployed) : 0,
      yearsEmployed: event.yearsEmployed ? Number(event.yearsEmployed) : 0,
      employerName: event.employerName,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };
    const coBorrowerEmployment = {
      employmentType: {
        name: "Seconday",
        label: "Seconday",
        value: 1,
      },
      grossIncome: Number(event.coBorrowerGrossIncome),
      monthsEmployed: event.coBorrowerMonths
        ? Number(event.coBorrowerMonths)
        : 0,
      yearsEmployed: event.coBorrowerYear ? Number(event.coBorrowerYear) : 0,
      employerName: event.coBorrowerEmployerName,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };

    const primaryApplicantModel = {
      jsonType: "Applicant",
      applicantType: 1,
      lastName: event.lastName,
      firstName: event.firstName,
      email: event.email,
      isUSCitizen: event.isUSCitizen === "Yes" ? true : false,
      xIsPermanentResident:
        event.isUSCitizen === "Yes"
          ? null
          : event.xIsPermanentResident === "Yes"
          ? true
          : false,
      mobilePhone: unmaskMobile(event.mobilePhone),
      addresses: applicantAddresses,
      employments: [applicanEmployment],
      xInstallationHomeOwner: 1,

      internalClientId: requestDetail?.internalClientId,
      applicationId: requestDetail?.primaryApplicant?.applicationId,
      applicantId: requestDetail?.primaryApplicant?.applicantId,
    };
    applicants.push(primaryApplicantModel);

    if (event.hasCoApplicant === "Yes") {
      let coBorrowerAddress;
      if (event.xDiffAddressThanApplicant === "No") {
        coBorrowerAddress = {
          ownershipType: {
            name: "Other",
            label: "Other",
            value: 4,
          },
          addressType: {
            name: "Primary",
            label: "Primary",
            value: 1,
          },
          addressFormat: {
            name: "Postal Standard",
            label: "Postal Standard",
            value: 5,
          },
          postalCode: event.coBorrowerPostalCode,
          addressLine1: event.coBorrowerAddressLine1,
          city: event.coBorrowerCity,
          state: event.coBorrowerState,
          yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
          monthsAtResidence: Number(event.coBorrowerMonthsAtResidence),
        };
      }
      const coApplicantModel = {
        applicantType: 2,
        firstName: event.coBorrowerFirstName,
        lastName: event.coBorrowerLastName,
        email: event.coBorrowerEmail,
        ...(!event.initialCoBorrowerData && {
          // As we will be appending values from backend for edit
          SSN: removeSpaces(event.coBorrowerssn),
          dateOfBirth: formatDateForDl4Submission(event.coBorrowerdob),
        }),
        isUSCitizen: event.coBorrowerusCitizen === "Yes" ? true : false,
        xIsPermanentResident:
          event.coBorrowerusCitizen === "Yes"
            ? null
            : event.coBorrowerxIsPermanentResident === "Yes"
            ? true
            : false,
        mobilePhone: unmaskMobile(event.coBorrowerMobilePhone),
        addresses:
          event.xDiffAddressThanApplicant === "Yes"
            ? applicantAddresses
            : [coBorrowerAddress],
        employments: [coBorrowerEmployment],
        xInstallationHomeOwner: 1,
        xDiffAddressThanApplicant:
          event.xDiffAddressThanApplicant === "Yes" ? false : true,

        internalClientId: requestDetail?.internalClientId,
        applicationId: requestDetail?.coapplicant?.applicationId,
        applicantId: requestDetail?.coapplicant?.applicantId,
      };
      applicants.push(coApplicantModel);
    }

    const loanModel = {
      applicants: applicants,
      loanInformation: {
        loanVersions: [
          {
            totalCashPrice: parseInt(event.loanAmount),
            commonLoanInfo: {
              xprogramtype: Number(event?.loanType),

              xInstallationAddressLine: xInstalationAddressSame
                ? event.xInstallationAddressLine
                : event.addressLine1,
              xInstallationCity: xInstalationAddressSame
                ? event.xInstallationCity
                : event.city,
              xInstallationState: xInstalationAddressSame
                ? event.xInstallationState
                : event.state,
              xInstallationZipCode: xInstalationAddressSame
                ? event.xInstallationZipCode
                : event.postalCode,
              xInstalationAddressSame: !event.xInstalationAddressSame,
              xStatedInstallationHomeOwner: Number(
                event.xStatedInstallationHomeOwner
              ),
              installationResidency: event.installationResidency || 1,
            },
          },
        ],
      },
      xInstallationAddressLine: xInstalationAddressSame
        ? event.xInstallationAddressLine
        : event.addressLine1,
      xInstallationCity: xInstalationAddressSame
        ? event.xInstallationCity
        : event.city,
      xInstallationState: xInstalationAddressSame
        ? event.xInstallationState
        : event.state,
      xInstallationZipCode: xInstalationAddressSame
        ? event.xInstallationZipCode
        : event.postalCode,
      xInstalationAddressSame: !event.xInstalationAddressSame,
      xStatedInstallationHomeOwner: Number(event.xStatedInstallationHomeOwner),
      installationResidency: event.installationResidency || 1,
    };
    dispatch(
      switchPrequalToFullApp({
        loanInfo: loanModel,
        appNumber: id,
        prequalifiedOfferId,
        history,
        path: "/new-application/confirmation",
        isEditOfCoborrower: event.initialCoBorrowerData,
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);

      const applicationData = response?.data?.data;
      setRequestDetail(applicationData);
      // Validate if prequalifiedOfferId is not in the prequalifiedOffers array then redirect to application summary page
      const prequalifiedOffer = getPrequalifiedOfferFromAppData(
        applicationData,
        prequalifiedOfferId
      );

      if (!prequalifiedOffer) {
        redirectToApplicationSummary();
      }

      const primaryData = applicationData?.primaryApplicant;
      const coBorrowerData = applicationData?.coapplicant;
      const loanInformation = applicationData?.loanInformation;
      const primaryAddress = primaryData?.addresses[0];
      const mailingAddress = primaryData?.addresses[1];
      const coBorrowerAddress = coBorrowerData?.addresses?.[0];

      reset({
        isModify: true,
        loanAmount: prequalifiedOffer?.amount,
        loanType: prequalifiedOffer?.xprogramtype,
        firstName: primaryData?.firstName,
        lastName: primaryData?.lastName,
        email: primaryData?.email,
        mobilePhone: maskMobile(primaryData?.mobilePhone || ""),
        // isUSCitizen: primaryData?.isUSCitizen ? 'Yes' : 'No',
        ...(typeof primaryData?.isUSCitizen == "boolean" &&
          !primaryData?.isUSCitizen && {
            xIsPermanentResident: primaryData?.xIsPermanentResident
              ? "Yes"
              : "No",
          }),

        addressLine1: primaryAddress?.addressLine1,
        city: primaryAddress?.city,
        state: primaryAddress?.state,
        postalCode: primaryAddress?.postalCode,
        yearsAtResidence: primaryAddress?.yearsAtResidence,
        monthsAtResidence: primaryAddress?.monthsAtResidence,

        grossIncome: primaryData?.currentEmployments[0]?.grossIncome,

        xInstalationAddressSame: primaryData?.xMailingAddress ? true : false,
        xInstallationAddressLine: primaryData?.xMailingAddress,
        xInstallationCity: primaryData?.xMailingCity,
        xInstallationState: primaryData?.xMailingState,
        xInstallationZipCode: primaryData?.xMailingPostalCode,
        installationResidency:
          loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.installationResidency || 0,

        mailingAddressLine1: mailingAddress ? mailingAddress?.addressLine1 : "",
        mailingCity: mailingAddress ? mailingAddress?.city : "",
        mailingState: mailingAddress ? mailingAddress?.state : "",
        mailingZipCode: mailingAddress ? mailingAddress?.postalCode : "",
        isMailingAdressSame: mailingAddress ? true : false,

        employerName: primaryData?.employments[0]?.employerName,
        yearsEmployed: primaryData?.employments[0]?.yearsEmployed,
        monthsEmployed: primaryData?.employments[0]?.monthsEmployed,

        coBorrowerFirstName: coBorrowerData ? coBorrowerData?.firstName : "",
        coBorrowerLastName: coBorrowerData ? coBorrowerData?.lastName : "",
        coBorrowerEmail: coBorrowerData ? coBorrowerData?.email : "",
        coBorrowerMobilePhone: coBorrowerData
          ? maskMobile(coBorrowerData?.mobilePhone)
          : "",
        coBorrowerusCitizen: coBorrowerData
          ? coBorrowerData?.isUSCitizen
            ? "Yes"
            : "No"
          : "",
        ...(typeof primaryData?.isUSCitizen == "boolean" &&
          !primaryData?.isUSCitizen && {
            xIsPermanentResident: primaryData?.xIsPermanentResident
              ? "Yes"
              : "No",
          }),

        coBorrowerGrossIncome: coBorrowerData
          ? coBorrowerData?.currentEmployments[0]?.grossIncome
          : 0,
        hasCoApplicant: coBorrowerData ? "Yes" : "No",
        xDiffAddressThanApplicant: coBorrowerData?.xDiffAddressThanApplicant
          ? "No"
          : "Yes",
        coBorrowerAddressLine1: coBorrowerAddress?.addressLine1 ?? "",
        coBorrowerCity: coBorrowerAddress?.city ?? "",
        coBorrowerState: coBorrowerAddress?.state ?? "",
        coBorrowerPostalCode: coBorrowerAddress?.postalCode ?? "",
        coBorrowerYearsAtResidence: coBorrowerAddress?.yearsAtResidence,
        coBorrowerMonthsAtResidence: coBorrowerAddress?.monthsAtResidence,

        coBorrowerEmployerName: coBorrowerData
          ? coBorrowerData?.employments[0]?.employerName
          : "",
        coBorrowerYear: coBorrowerData
          ? coBorrowerData?.employments[0]?.yearsEmployed
          : 0,
        coBorrowerMonths: coBorrowerData
          ? coBorrowerData?.employments[0]?.monthsEmployed
          : 0,
        initialCoBorrowerData: coBorrowerData ? true : false,
        // If there is no initialCoBorrowerData data, then we would be asking for DOB and SSN of co-borrower
        // If co-borrower data is already submitted, then we would not ask/display DOB and SSN
      });
    };
    reset({
      isMailingAdressSame: undefined,
      xDiffAddressThanApplicant: undefined,
    });
    if (id && prequalifiedOfferId) {
      getRequestData(id);
    } else {
      redirectToApplicationSummary();
      setTimeout(
        () =>
          reset({
            hasCoApplicant: "No",
            xDiffAddressThanApplicant: "No",
            xInstalationAddressSame: false,
            isMailingAdressSame: false,
            SSN: "",
          }),
        1000
      );
    }
  }, [
    location.pathname,
    id,
    reset,
    prequalifiedOfferId,
    redirectToApplicationSummary,
  ]);

  const xInstalationAddressSame = watch("xInstalationAddressSame");
  const hasCoApplicant = watch("hasCoApplicant");
  const isMailingAdressSame = watch("isMailingAdressSame");
  const xDiffAddressThanApplicant = watch("xDiffAddressThanApplicant");
  const isUSCitizen = watch("isUSCitizen");
  const coBorrowerusCitizen = watch("coBorrowerusCitizen");
  const initialCoBorrowerData = watch("initialCoBorrowerData");

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
      <h3 className="h3 text-primary">Solar Loan Application</h3>
      <hr className="text-primary height-2px" />
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onSubmitError())}
      >
        <ConvertApplicationGuard application={requestDetail as IApplication} />
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Loan Information</h4>
          </Col>
          <Col xs={12} md={6}>
            <UseFormSelect
              list={getProductsList(
                authUserStatus,
                user?.role,
                user?.userDetails?.accessManagement,
                "modify",
                requestDetail?.loanInformation?.currentLoanVersion
                  ?.commonLoanInfo?.xprogramtype
              )}
              control={control}
              name="loanType"
              placeholder="Select Loan Product"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <UserFormCurrencyField
              placeholder="Loan Amount"
              control={control}
              type="number"
              name="loanAmount"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Home Ownership</h4>
          </Col>
          <Col xs={12} className="mt-3">
            <UseFormSelect
              list={OWNERSHIP_LIST}
              control={control}
              name="xStatedInstallationHomeOwner"
              placeholder="Who is the current owner of the installation address?"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Your Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="First Name"
              control={control}
              name="firstName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="Last Name"
              control={control}
              name="lastName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormTextField
              placeholder="Email"
              control={control}
              name="email"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <MyInputMask
              control={control}
              defaultValue={""}
              name="mobilePhone"
              mask={MOBILE_MASK}
              placeholder="Mobile Phone"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormSelect
              list={CITIZENSHIP}
              control={control}
              name="isUSCitizen"
              placeholder="Are you a US citizen"
            />
          </Col>
          {isUSCitizen === "No" && (
            <Col xs={12} md={6} className="mt-3">
              <UseFormSelect
                list={CITIZENSHIP}
                control={control}
                name="xIsPermanentResident"
                placeholder="Are you a Permanent Resident?"
              />
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Current Address</h4>
          </Col>
          <Col xs={12}>
            <GoogleAutoComplete
              placeholder="Street"
              control={control}
              name="addressLine1"
              onSelect={(address) => {
                setValue("addressLine1", address.street);
                setValue("city", address.city);
                setValue("state", address.state);
                setValue("postalCode", address.zipCode);
              }}
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="City"
              control={control}
              name="city"
            />
          </Col>
          <Col xs={6} md={3} className="mt-3">
            <UseFormTextField
              placeholder="State"
              control={control}
              name="state"
            />
          </Col>
          <Col xs={6} md={3} className="mt-3">
            <UseFormTextField
              placeholder="Zip Code"
              control={control}
              name="postalCode"
            />
          </Col>
        </Row>
        <Row className="mt-3 d-flex align-items-center">
          <Col xs={4} sm={4} lg={3}>
            <label className="form-check-label">
              Time with Current Residence:
            </label>
          </Col>
          <Col xs={6} sm={4} lg={3}>
            <UseFormSelect
              list={YEARS_ARRAY}
              control={control}
              name="yearsAtResidence"
              placeholder="Years"
              placeholderValue={""}
            />
          </Col>
          <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
            <UseFormSelect
              list={MONTHS_ARRAY}
              control={control}
              name="monthsAtResidence"
              placeholder="Months"
              placeholderValue={""}
            />
          </Col>
        </Row>

        <Row>
          {typeof xInstalationAddressSame != "undefined" && (
            <Col xs={12} md={6} className="mt-3">
              <UseFormCheck
                control={control}
                controlName="xInstalationAddressSame"
                content="My Installation address is different than the current address."
              />
            </Col>
          )}
          {xInstalationAddressSame && (
            <>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Installation Address</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="xInstallationAddressLine"
                  onSelect={(address) => {
                    setValue("xInstallationAddressLine", address.street);
                    setValue("xInstallationCity", address.city);
                    setValue("xInstallationState", address.state);
                    setValue("xInstallationZipCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="xInstallationCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="xInstallationState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="xInstallationZipCode"
                />
              </Col>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Installation Address Residency</h4>
              </Col>
              <Col xs={12} className="mb-4">
                <UseFormSelect
                  list={INSTALLATION_RESIDENCY}
                  control={control}
                  name="installationResidency"
                  defaultValue={0}
                  placeholderValue={0}
                  placeholder="What is the residency type of the installation address?"
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          {typeof isMailingAdressSame != "undefined" && (
            <Col xs={12} className="mt-3">
              <UseFormCheck
                control={control}
                controlName="isMailingAdressSame"
                content="My Mailing address is different than my current address."
              />
            </Col>
          )}
          {isMailingAdressSame && (
            <>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Mailing Address</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="mailingAddressLine1"
                  onSelect={(address) => {
                    setValue("mailingAddressLine1", address.street);
                    setValue("mailingCity", address.city);
                    setValue("mailingState", address.state);
                    setValue("mailingZipCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="mailingCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="mailingState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="mailingZipCode"
                />
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <h4 className="font-bold">Gross Annual Income</h4>
          </Col>
          <Col xs={12}>
            <UserFormCurrencyField
              placeholder="Gross Annual Income"
              control={control}
              name="grossIncome"
              type="number"
              disabled
            />
          </Col>
          <Col xs={12} className="mt-3">
            <p className="subdetails">
              Please enter annual income, which may include any income that is
              verifiable and reported to the Government, such as employment
              income, social security, pensions, etc. Do not include household
              income from another borrower or non-applicant.
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">
              Employer Information (If Retired, type Retired)
            </h4>
          </Col>
          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <UseFormTextField
              placeholder="Employer Name"
              control={control}
              name="employerName"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="mt-3">
            <div className="d-flex align-items-center flex-wrap">
              <label className="form-check-label me-3">
                Time with Current Employer / Length of Time Retired:
              </label>
              <div className="d-flex gap-3">
                <div className="width-150px">
                  <UseFormSelect
                    list={YEARS_ARRAY}
                    control={control}
                    name="yearsEmployed"
                    placeholder="Years"
                  />
                </div>
                <div className="width-150px">
                  <UseFormSelect
                    list={MONTHS_ARRAY}
                    control={control}
                    name="monthsEmployed"
                    placeholder="Months"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <UserConsent
          applicant={ApplicantType.Borrower}
          type={ApplicationType.Full}
          authorization={
            <UseFormCheck
              control={control}
              controlName="authorization"
              content="Yes, I have read and agree to the following credit authorization:"
            />
          }
          consent={
            <UseFormCheck
              control={control}
              controlName="consent"
              content="Yes, I have read and agree to the following consents and disclosures:"
            />
          }
        />
        {typeof hasCoApplicant == "string" && (
          <Row className="py-4 mt-4 border-y g-0">
            <Col>
              <div className="d-flex align-items-center">
                <h4 className="font-bold me-4 mb-0">Add a Co-Borrower:</h4>
                <div className="ml-3 d-flex">
                  <UseFormRadio
                    control={control}
                    elementName="co-borrower-group"
                    controlName="hasCoApplicant"
                    content="Yes"
                    checked={hasCoApplicant === "Yes" ? true : false}
                  />
                  <UseFormRadio
                    control={control}
                    elementName="co-borrower-group"
                    controlName="hasCoApplicant"
                    content="No"
                    checked={hasCoApplicant === "No" ? true : false}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
        {hasCoApplicant === "Yes" ? (
          <>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Co-Borrower Information</h4>
              </Col>
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="First Name"
                  control={control}
                  name="coBorrowerFirstName"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="Last Name"
                  control={control}
                  name="coBorrowerLastName"
                />
              </Col>
              {!initialCoBorrowerData && (
                <>
                  <Col xs={12} md={6} className="mt-3">
                    <MyInputMask
                      control={control}
                      name="coBorrowerssn"
                      mask={SSN_MASK}
                      placeholder="SSN"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormDatePicker
                      placeholder="Date of Birth"
                      maxDate={new Date()}
                      control={control}
                      controlName="coBorrowerdob"
                    />
                  </Col>
                </>
              )}
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="Email"
                  control={control}
                  name="coBorrowerEmail"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <MyInputMask
                  control={control}
                  name="coBorrowerMobilePhone"
                  mask={MOBILE_MASK}
                  placeholder="Mobile Phone"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormSelect
                  list={CITIZENSHIP}
                  control={control}
                  name="coBorrowerusCitizen"
                  placeholder="Are you a US citizen"
                />
              </Col>
              {coBorrowerusCitizen === "No" && (
                <Col xs={12} md={6} className="mt-3">
                  <UseFormSelect
                    list={CITIZENSHIP}
                    control={control}
                    name="coBorrowerxIsPermanentResident"
                    placeholder="Are you a Permanent Resident?"
                  />
                </Col>
              )}
            </Row>
            {typeof xDiffAddressThanApplicant !== "undefined" && (
              <Row>
                <Col className="mt-4">
                  <h4 className="font-bold mr-4 mb-0">Co-Residency </h4>
                  <h4 className="fw-normal">
                    Does coborrower reside at the same residence as the primary
                    borrower?{" "}
                  </h4>
                  <div className="d-flex flex-column">
                    <UseFormRadio
                      control={control}
                      elementName="co-residency"
                      controlName="xDiffAddressThanApplicant"
                      content="Yes"
                      checked={
                        xDiffAddressThanApplicant === "Yes" ? true : false
                      }
                    />
                    <UseFormRadio
                      control={control}
                      elementName="co-residency"
                      controlName="xDiffAddressThanApplicant"
                      content="No"
                      checked={
                        xDiffAddressThanApplicant === "No" ? true : false
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}

            {xDiffAddressThanApplicant === "No" && (
              <>
                <Row>
                  <Col xs={12} className="mt-3">
                    <h4 className="font-bold">Coborrower Address</h4>
                  </Col>
                  <Col xs={12}>
                    <GoogleAutoComplete
                      placeholder="Street"
                      control={control}
                      name="coBorrowerAddressLine1"
                      onSelect={(address) => {
                        setValue("coBorrowerAddressLine1", address.street);
                        setValue("coBorrowerCity", address.city);
                        setValue("coBorrowerState", address.state);
                        setValue("coBorrowerPostalCode", address.zipCode);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormTextField
                      placeholder="City"
                      control={control}
                      name="coBorrowerCity"
                    />
                  </Col>
                  <Col xs={6} md={3} className="mt-3">
                    <UseFormTextField
                      placeholder="State"
                      control={control}
                      name="coBorrowerState"
                    />
                  </Col>
                  <Col xs={6} md={3} className="mt-3">
                    <UseFormTextField
                      placeholder="Zip Code"
                      control={control}
                      name="coBorrowerPostalCode"
                    />
                  </Col>
                </Row>
                <Row className="mt-3 d-flex align-items-center">
                  <Col xs={4} sm={4} lg={3}>
                    <label className="form-check-label">
                      Time with Current Residence:
                    </label>
                  </Col>
                  <Col xs={6} sm={4} lg={3}>
                    <UseFormSelect
                      list={YEARS_ARRAY}
                      control={control}
                      name="coBorrowerYearsAtResidence"
                      placeholder="Years"
                      placeholderValue={""}
                    />
                  </Col>
                  <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
                    <UseFormSelect
                      list={MONTHS_ARRAY}
                      control={control}
                      name="coBorrowerMonthsAtResidence"
                      placeholder="Months"
                      placeholderValue={""}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row className="mt-4">
              <Col xs={12} sm={6}>
                <h4 className="font-bold">Co-Borrower Gross Annual Income</h4>
                <UserFormCurrencyField
                  placeholder="Gross Annual Income"
                  control={control}
                  name="coBorrowerGrossIncome"
                  disabled={!!initialCoBorrowerData}
                />
              </Col>
              <p className="mt-2">
                Please enter annual income, which may include any income that is
                verifiable and reported to the Government, such as employment
                income, social security, pensions, etc.
              </p>
            </Row>
            <Row>
              <Col xs={12}>
                <h4 className="font-bold">
                  Co-Borrower Employer Information (If Retired, type Retired)
                </h4>
              </Col>
              <Col xs={12}>
                <UseFormTextField
                  placeholder="Employer Name"
                  control={control}
                  name="coBorrowerEmployerName"
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="mt-3">
                <div className="d-flex align-items-center flex-wrap">
                  <label className="form-check-label me-3">
                    Time with Current Employer / Length of Time Retired:
                  </label>
                  <div className="d-flex gap-3">
                    <div className="width-150px">
                      <UseFormSelect
                        list={YEARS_ARRAY}
                        control={control}
                        name="coBorrowerYear"
                        placeholder="Years"
                      />
                    </div>
                    <div className="width-150px">
                      <UseFormSelect
                        list={MONTHS_ARRAY}
                        control={control}
                        name="coBorrowerMonths"
                        placeholder="Months"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}

        {hasCoApplicant === "Yes" && (
          <UserConsent
            applicant={ApplicantType.CoBorrower}
            type={ApplicationType.Full}
            authorization={
              <UseFormCheck
                control={control}
                controlName="coBorrowerAuthorization"
                content="Yes, I have read and agree to the following credit authorization:"
              />
            }
            consent={
              <UseFormCheck
                control={control}
                controlName="coBorrowerConsent"
                content="Yes, I have read and agree to the following consents and disclosures:"
              />
            }
          />
        )}

        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit Application
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default ConvertToFullApplication;
