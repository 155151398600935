import React from "react";
import { Row, Col } from "react-bootstrap";

const SoftCreditPull = () => {
  return (
    <Col xs={11} className="mx-auto terms">
      <div className="">
        <Row className="">
          <Col xs={12}>
            <strong>Last Updated: January 23, 2024</strong>
            <br />
            <br />
            I, as the person(s) making this inquiry, understand, acknowledge,
            and consent under the Fair Credit Reporting Act that SunRisa
            Solutions LLC dba Flic Financial, A+ Federal Credit Union, Texas Dow
            Employees Credit Union or NASA Federal Credit Union may review
            my/our prequalification request to obtain a consumer credit report
            on me from one or more consumer reporting agencies to process my
            prequalification request. I/we further understand, acknowledge, and
            consent that such consumer credit report will be obtained by means
            of a soft credit inquiry that will not affect my credit score, that
            this inquiry is not an application for credit, and that an
            application for credit will require a hard credit pull
            authorization.
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default SoftCreditPull;
