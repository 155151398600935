import InlineLoader from "@shared/components/InlineLoader";
import { STIPULATION_ALLOWED } from "@shared/constants/app.constant";
import {
  getType,
  getLabelIfDocIsDownloadable,
  isDocumentUploaded,
  isStipulationFailed,
} from "@shared/helpers/documents.helper";
import { IApplication, IStipulation } from "@shared/interfaces/Application";
import { useAppSelector } from "@store/hooks";
import {
  DownloadDocumentPayload,
  documentDownloadingSelector,
  documentUploadingSelector,
  documentsMetadataSelector,
} from "@store/slices/loan.slice";
import { ChangeEvent, useRef } from "react";
import { Download, Search } from "react-bootstrap-icons";

export const StipulationCard = ({
  requestDetail,
  stipulation,
  onDocumentInputFileChange,
  docId,
  errorMessage,
  uploadingDocType,
  downloadDocument,
  canBeModified,
}: {
  requestDetail: IApplication | undefined;
  stipulation: IStipulation;
  uploadingDocType: string;
  errorMessage: string;
  docId: string;
  onDocumentInputFileChange: (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => Promise<void>;
  downloadDocument: (value: DownloadDocumentPayload) => void;
  canBeModified: boolean;
}) => {
  const uploadIdFile = useRef<HTMLInputElement>(null);
  const isDocumentUploading = useAppSelector(documentUploadingSelector);
  const isDocumentDownloading = useAppSelector(documentDownloadingSelector);
  const documentsMetadata = useAppSelector(documentsMetadataSelector);

  const docLabel: string[] | undefined = getLabelIfDocIsDownloadable(
    stipulation,
    documentsMetadata
  );
  return (
    <div>
      <div className="d-flex flex-column flex-sm-row justify-content-between mt-3">
        <div>
          <div className="d-flex">
            <p className="mb-0">{stipulation.stipName}</p> &nbsp;&nbsp;&nbsp;
            {getType(stipulation?.stipulationType) && (
              <p className="bg-primary status-padding text-light radius-16 ">
                {getType(stipulation?.stipulationType)}
              </p>
            )}
          </div>
          {stipulation?.stipDescription && (
            <p className="stip-description text-wrap ">
              {stipulation?.stipDescription}
            </p>
          )}
          {requestDetail &&
            isDocumentUploaded(requestDetail, stipulation.stipName) && (
              <p className="text-success">Submitted</p>
            )}
          {requestDetail &&
            !isDocumentUploaded(requestDetail, stipulation.stipName) && (
              <p className="text-warning">Required</p>
            )}
          {isDocumentUploading && uploadingDocType === stipulation.stipName && (
            <InlineLoader />
          )}
          {isStipulationFailed(stipulation) && (
            <div className="text-danger">{stipulation.comments}</div>
          )}
          {errorMessage && stipulation.stipName === docId && (
            <div className="text-danger">{errorMessage}</div>
          )}
        </div>
        <div className="d-flex align-self-center text-center my-3 my-sm-0">
          {canBeModified && (
            <>
              <button
                className="btn btn-light width-110px"
                onClick={() => {
                  uploadIdFile?.current?.click();
                }}
                disabled={
                  isDocumentUploading &&
                  uploadingDocType === stipulation.stipName
                }
              >
                Upload File
              </button>
              <input
                accept={STIPULATION_ALLOWED}
                className="d-none"
                id={`upload-${stipulation.stipName.replace(/\s/g, "")}`}
                type="file"
                ref={uploadIdFile}
                // ref={(el) => (uploadIdFile.current = el)}
                onChange={(event) => {
                  onDocumentInputFileChange(event, stipulation.stipName, () => {
                    if (uploadIdFile?.current?.value)
                      uploadIdFile.current.value = "";
                  });
                }}
              />
            </>
          )}
          {requestDetail?.appNumber && docLabel && docLabel.length > 0 && (
            <>
              <button
                className="btn btn-light ms-2"
                onClick={() => {
                  requestDetail?.appNumber &&
                    downloadDocument({
                      appId: requestDetail?.appNumber,
                      label: docLabel,
                      case: "view",
                    });
                }}
                disabled={isDocumentDownloading || isDocumentUploading}
              >
                <Search />
              </button>
              <button
                className="btn btn-light ms-2"
                onClick={() => {
                  requestDetail?.appNumber &&
                    downloadDocument({
                      appId: requestDetail?.appNumber,
                      label: docLabel,
                      case: "download",
                    });
                }}
                disabled={isDocumentDownloading || isDocumentUploading}
              >
                <Download />
              </button>
            </>
          )}
        </div>
      </div>
      <hr className="bg-secondary" />
    </div>
  );
};
