import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UseFormDatePicker } from "@shared/components/UserFormDatePicker";
import {
  CITIZENSHIP,
  MOBILE_MASK,
  MONTHS_ARRAY,
  SSN_MASK,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import { MyInputMask } from "@shared/components/MyInputMask";
import { useHistory, useParams } from "react-router-dom";
import { loanService } from "@services/loan.service";
import {
  maskMobile,
  removeSpaces,
  unmaskMobile,
  useQuery,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { addApplicantAction } from "@store/slices/loan.slice";
import { newAppAddCoBorrowerSchema } from "@shared/schema/request";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import UserConsent from "@shared/components/UserConsent";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import { IApplication } from "@shared/interfaces/Application";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { formatDateForDl4Submission } from "@utils/date";

const CoBorrower = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [requestDetail, setRequestDetail] = useState<IApplication>();
  const [type, setType] = useState<any>("");
  let query = useQuery();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newAppAddCoBorrowerSchema),
  });

  const onSubmit = (event: any) => {
    const employment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      employerName: event.employerName,
      monthsEmployed: event.monthsEmployed ? Number(event.monthsEmployed) : 0,
      yearsEmployed: event.yearsEmployed ? Number(event.yearsEmployed) : 0,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };

    let coBorrowerAddress;
    if (event.xDiffAddressThanApplicant === "No") {
      coBorrowerAddress = {
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Primary",
          label: "Primary",
          value: 1,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.coBorrowerPostalCode,
        addressLine1: event.coBorrowerAddressLine1,
        city: event.coBorrowerCity,
        state: event.coBorrowerState,
        yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
        monthsAtResidence: Number(event.coBorrowerMonthsAtResidence),
      };
    }

    const address = requestDetail?.primaryApplicant?.addresses.find(
      (x) => x.addressType.label === "Primary"
    );
    const commonLoanInfo =
      requestDetail?.loanInformation?.currentLoanVersion?.commonLoanInfo;
    let primaryAddress = [];
    if (address) {
      primaryAddress.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Primary",
          label: "Primary",
          value: 1,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: address.postalCode,
        addressLine1: address.addressLine1,
        city: address.city,
        state: address.state,
        yearsAtResidence: Number(address.yearsAtResidence),
        monthsAtResidence: Number(address.monthsAtResidence),
      });
    }
    const model = {
      content: {
        jsonType: "Applicant",
        applicantType: {
          name: "coBorrower",
          label: "Co-Borrower",
          value: 2,
        },
        lastName: event.lastName,
        firstName: event.firstName,
        ...(type !== "edit" && {
          // As we will be appending values from backend for edit
          SSN: removeSpaces(event.SSN),
          dateOfBirth: formatDateForDl4Submission(event.dateOfBirth),
        }),
        email: event.email,
        isUSCitizen: event.isUSCitizen === "Yes" ? true : false,
        xIsPermanentResident:
          event.isUSCitizen === "Yes"
            ? null
            : event.xIsPermanentResident === "Yes"
            ? true
            : false,

        mobilePhone: unmaskMobile(event.mobilePhone),
        addresses:
          event.xDiffAddressThanApplicant === "No"
            ? [coBorrowerAddress]
            : primaryAddress,
        xDiffAddressThanApplicant:
          event.xDiffAddressThanApplicant === "Yes" ? false : true,
        employments: [employment],
        xInstallationHomeOwner: 1,

        ...(type === "edit" && {
          internalClientId: requestDetail?.internalClientId,
        }),
        ...(type === "edit" && {
          applicationId: requestDetail?.coapplicant?.applicationId,
        }),
        ...(type === "edit" && {
          applicantId: requestDetail?.coapplicant?.applicantId,
        }),
      },
      redecisionRequested: 0,
    };

    const loanModel = {
      loanInformation: {
        loanVersions: [
          {
            Prequal: false,
            totalCashPrice:
              requestDetail?.loanInformation?.currentLoanVersion
                ?.amountFinanced,
            commonLoanInfo: {
              xprogramtype: commonLoanInfo?.xprogramtype,
              xInstallationAddressLine:
                commonLoanInfo?.xInstallationAddressLine,
              xInstallationCity: commonLoanInfo?.xInstallationCity,
              xInstallationState: commonLoanInfo?.xInstallationState,
              xInstallationZipCode: commonLoanInfo?.xInstallationZipCode,
              xInstalationAddressSame: commonLoanInfo?.xInstalationAddressSame
                ? true
                : false,
              xStatedInstallationHomeOwner:
                commonLoanInfo?.xStatedInstallationHomeOwner ||
                commonLoanInfo?.xInstalaltionHomeOwner,
              installationResidency: commonLoanInfo?.installationResidency,
            },
          },
        ],
      },
      xInstallationAddressLine: commonLoanInfo?.xInstallationAddressLine,
      xInstallationCity: commonLoanInfo?.xInstallationCity,
      xInstallationState: commonLoanInfo?.xInstallationState,
      xInstallationZipCode: commonLoanInfo?.xInstallationZipCode,
      xInstalationAddressSame: commonLoanInfo?.xInstalationAddressSame
        ? true
        : false,
      xStatedInstallationHomeOwner:
        commonLoanInfo?.xStatedInstallationHomeOwner ||
        commonLoanInfo?.xInstalaltionHomeOwner,
      installationResidency: commonLoanInfo?.installationResidency,
    };

    dispatch(
      addApplicantAction({
        model,
        loanInfo: loanModel,
        appNumber: id,
        history,
        path: "/new-application/confirmation",
        isEdit: type === "edit",
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const param = query.get("type");

      const response = await loanService.getRequestById(id);
      setRequestDetail(response?.data?.data);

      const applicant = response?.data?.data?.coapplicant;
      const coBorrowerAddress = applicant?.addresses?.[0];

      const primaryApplicant = response?.data?.data?.primaryApplicant;

      if (param !== "edit") {
        // For Adding new CoBorrower
        reset({
          isModify: false,
          primaryApplicantEmail: primaryApplicant?.email || "",
        });
        return;
      }
      setType(param);

      reset({
        isModify: true,
        firstName: applicant?.firstName,
        lastName: applicant?.lastName,
        email: applicant?.email,
        mobilePhone: maskMobile(applicant?.mobilePhone || ""),
        isUSCitizen: applicant?.isUSCitizen ? "Yes" : "No",
        ...(typeof applicant?.isUSCitizen == "boolean" &&
          !applicant?.isUSCitizen && {
            xIsPermanentResident: applicant.xIsPermanentResident ? "Yes" : "No",
          }),
        grossIncome: applicant?.currentEmployments?.[0]?.grossIncome,
        xDiffAddressThanApplicant: applicant?.xDiffAddressThanApplicant
          ? "No"
          : "Yes",
        employerName: applicant?.employments[0]?.employerName,

        yearsEmployed: Number(applicant?.employments[0]?.yearsEmployed),
        monthsEmployed: Number(applicant?.employments[0]?.monthsEmployed),
        primaryApplicantEmail: primaryApplicant?.email,
        coBorrowerAddressLine1: coBorrowerAddress?.addressLine1 ?? "",
        coBorrowerCity: coBorrowerAddress?.city ?? "",
        coBorrowerState: coBorrowerAddress?.state ?? "",
        coBorrowerPostalCode: coBorrowerAddress?.postalCode ?? "",
        coBorrowerYearsAtResidence: coBorrowerAddress?.yearsAtResidence,
        coBorrowerMonthsAtResidence: coBorrowerAddress?.monthsAtResidence,
      });
    };
    getRequestData(id);
    if (query.get("type") !== "edit") {
      setTimeout(() => reset({ xDiffAddressThanApplicant: "Yes" }), 1000);
    }
  }, [id, query, reset]);

  const xDiffAddressThanApplicant = watch("xDiffAddressThanApplicant");
  const isUSCitizen = watch("isUSCitizen");

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={requestDetail as IApplication} />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">
          {type === "edit" ? "Modify" : "Add"} CoBorrower
        </h3>
        <hr className="text-primary height-2px" />
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Applicant Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="First Name"
              control={control}
              name="firstName"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormTextField
              placeholder="Last Name"
              control={control}
              name="lastName"
            />
          </Col>
          {type !== "edit" && (
            <>
              <Col xs={12} md={6} className="mt-3">
                <MyInputMask
                  control={control}
                  mask={SSN_MASK}
                  name="SSN"
                  placeholder="SSN"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormDatePicker
                  placeholder="Date of Birth"
                  maxDate={new Date()}
                  control={control}
                  controlName="dateOfBirth"
                />
              </Col>
            </>
          )}
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormTextField
              placeholder="Email"
              control={control}
              name="email"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <MyInputMask
              control={control}
              defaultValue={""}
              name="mobilePhone"
              mask={MOBILE_MASK}
              placeholder="Mobile Phone"
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <UseFormSelect
              list={CITIZENSHIP}
              control={control}
              name="isUSCitizen"
              placeholder="Are you a US citizen"
            />
          </Col>
          {isUSCitizen === "No" && (
            <Col xs={12} md={6} className="mt-3">
              <UseFormSelect
                list={CITIZENSHIP}
                control={control}
                name="xIsPermanentResident"
                placeholder="Are you a Permanent Resident?"
              />
            </Col>
          )}
        </Row>
        {typeof xDiffAddressThanApplicant !== "undefined" && (
          <Row>
            <Col className="mt-4">
              <h4 className="font-bold mr-4 mb-0">Co-Residency </h4>
              <h4 className="fw-normal">
                Does coborrower reside at the same residence as the primary
                borrower?{" "}
              </h4>
              <div className="d-flex flex-column">
                <UseFormRadio
                  control={control}
                  elementName="co-residency"
                  controlName="xDiffAddressThanApplicant"
                  content="Yes"
                  checked={xDiffAddressThanApplicant === "Yes" ? true : false}
                />
                <UseFormRadio
                  control={control}
                  elementName="co-residency"
                  controlName="xDiffAddressThanApplicant"
                  content="No"
                  checked={xDiffAddressThanApplicant === "No" ? true : false}
                />
              </div>
            </Col>
          </Row>
        )}
        {xDiffAddressThanApplicant === "No" && (
          <>
            <Row>
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Coborrower Address</h4>
              </Col>
              <Col xs={12}>
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="coBorrowerAddressLine1"
                  onSelect={(address) => {
                    setValue("coBorrowerAddressLine1", address.street);
                    setValue("coBorrowerCity", address.city);
                    setValue("coBorrowerState", address.state);
                    setValue("coBorrowerPostalCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="coBorrowerCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="coBorrowerState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="coBorrowerPostalCode"
                />
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col xs={4} sm={4} lg={3}>
                <label className="form-check-label">
                  Time with Current Residence:
                </label>
              </Col>
              <Col xs={6} sm={4} lg={3}>
                <UseFormSelect
                  list={YEARS_ARRAY}
                  control={control}
                  name="coBorrowerYearsAtResidence"
                  placeholder="Years"
                  placeholderValue={""}
                />
              </Col>
              <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
                <UseFormSelect
                  list={MONTHS_ARRAY}
                  control={control}
                  name="coBorrowerMonthsAtResidence"
                  placeholder="Months"
                  placeholderValue={""}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-4">
          <Col xs={12} sm={6}>
            <h4 className="font-bold">Co-Borrower Gross Annual Income</h4>
            <UserFormCurrencyField
              placeholder="Gross Annual Income"
              control={control}
              name="grossIncome"
              type="number"
              disabled={type === "edit"}
            />
          </Col>
          <p className="mt-2">
            Please enter annual income, which may include any income that is
            verifiable and reported to the Government, such as employment
            income, social security, pensions, etc.
          </p>
        </Row>
        <Row>
          <Col xs={12}>
            <h4 className="font-bold">
              Co-Borrower Employer Information (If Retired, type Retired)
            </h4>
          </Col>
          <Col xs={12}>
            <UseFormTextField
              placeholder="Employer Name"
              control={control}
              name="employerName"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-3">
            <div className="d-flex align-items-center flex-wrap">
              <label className="form-check-label me-3">
                Time with Current Employer / Length of Time Retired:
              </label>
              <div className="d-flex gap-3">
                <div className="width-150px">
                  <UseFormSelect
                    list={YEARS_ARRAY}
                    control={control}
                    name="yearsEmployed"
                    placeholder="Years"
                  />
                </div>
                <div className="width-150px">
                  <UseFormSelect
                    list={MONTHS_ARRAY}
                    control={control}
                    name="monthsEmployed"
                    placeholder="Months"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <UserConsent
          applicant={ApplicantType.CoBorrower}
          type={ApplicationType.Full}
          authorization={
            <UseFormCheck
              control={control}
              controlName="authorization"
              content="Yes, I have read and agree to the following credit authorization:"
            />
          }
          consent={
            <UseFormCheck
              control={control}
              controlName="consent"
              content="Yes, I have read and agree to the following consents and disclosures:"
            />
          }
        />

        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default CoBorrower;
