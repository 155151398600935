import { Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useHistory, useParams } from "react-router-dom";
import { getProductsList, onSubmitError } from "@shared/helpers/global.helper";
import {
  modifyLoanAction,
  prequalModifyLoanAction,
} from "@store/slices/loan.slice";
import { useEffect, useState } from "react";
import { modifyLoanSchema } from "@shared/schema/request";
import { loanService } from "@services/loan.service";
import {
  INSTALLATION_RESIDENCY,
  OWNERSHIP_LIST,
} from "@shared/constants/app.constant";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import { IApplication } from "@shared/interfaces/Application";
import { UseFormTextField } from "@shared/components/TextInput";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { appUserSelector } from "@store/slices/app.slice";
import { useAuthUserContext } from "@contexts/AuthContext";
import {
  generateLoanObjForMultiPrequal,
  getXPrequalProducts,
  isPrequalApplication,
} from "@shared/helpers/application.helper";
import { UseFormCheck } from "@shared/components/UserFormCheck";

const ModifyLoan = () => {
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState<IApplication | null>(null);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [initialInstAddressFields, setInitialInstAddressFields] = useState({
    xInstallationAddressLine: "",
    xInstallationCity: "",
    xInstallationState: "",
    xInstallationZipCode: "",
  });
  const { authUserStatus } = useAuthUserContext();

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(modifyLoanSchema),
  });
  const user = useAppSelector(appUserSelector);

  const isFullApplication = watch("isFull");

  const onSubmit = (event: any) => {
    if (!application) {
      return;
    }

    const model = {
      loanInformation: {
        loanVersions: [
          {
            prequalStage: isFullApplication ? 0 : 1,
            totalCashPrice: isFullApplication
              ? parseInt(event.loanAmount)
              : undefined,
            commonLoanInfo: {
              xprogramtype: event?.loanType,
              ...(!isFullApplication
                ? {}
                : {
                    xStatedInstallationHomeOwner: Number(
                      event.xStatedInstallationHomeOwner
                    ),
                    xInstallationAddressLine: event.xInstallationAddressLine,
                    xInstallationCity: event.xInstallationCity,
                    xInstallationState: event.xInstallationState,
                    xInstallationZipCode: event.xInstallationZipCode,
                    installationResidency:
                      event.installationResidency ||
                      INSTALLATION_RESIDENCY[0].value,
                  }),
            },
          },
        ],
      },
      ...(!isFullApplication
        ? {}
        : {
            xStatedInstallationHomeOwner: Number(
              event.xStatedInstallationHomeOwner
            ),
            xInstallationAddressLine: event.xInstallationAddressLine,
            xInstallationCity: event.xInstallationCity,
            xInstallationState: event.xInstallationState,
            xInstallationZipCode: event.xInstallationZipCode,
            installationResidency:
              event.installationResidency || INSTALLATION_RESIDENCY[0].value,
          }),
    };
    const isInstallationAddressUpdated =
      initialInstAddressFields.xInstallationAddressLine !==
        watch("xInstallationAddressLine") ||
      initialInstAddressFields.xInstallationCity !==
        watch("xInstallationCity") ||
      initialInstAddressFields.xInstallationState !==
        watch("xInstallationState") ||
      initialInstAddressFields.xInstallationZipCode !==
        watch("xInstallationZipCode");

    const path = isFullApplication
      ? "/new-application/confirmation"
      : "/pre-qualification/confirmation";

    if (isFullApplication) {
      dispatch(
        modifyLoanAction({
          model,
          id,
          history,
          path,
          isInstallationAddressUpdated,
        })
      );
    } else {
      const isMultiPrequal = event.isMultiPrequal;
      const { xPrequalProductsSelected, xPrequalProductsAll } =
        getXPrequalProducts(user, event.loanType);
      const xprogramtype = Number(event?.loanType);

      dispatch(
        prequalModifyLoanAction({
          appNumber: id,
          history,
          loanInfo: generateLoanObjForMultiPrequal(application, {
            xprogramtype,
            xPrequalProducts: xPrequalProductsSelected,
          }),
          path,
          isMultiPrequal,
          xprogramtype,
          xPrequalProducts: xPrequalProductsAll,
        })
      );
    }
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      const data = response?.data?.data;
      setApplication(data);
      reset({
        isFull: !isPrequalApplication(data),
        loanAmount: data?.loanInformation?.currentLoanVersion?.amountFinanced,
        loanType:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xprogramtype,
        xStatedInstallationHomeOwner:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xStatedInstallationHomeOwner ||
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstalaltionHomeOwner ||
          undefined,
        xInstallationAddressLine:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationAddressLine || undefined,
        xInstallationCity:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationCity || undefined,
        xInstallationState:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationState || undefined,
        xInstallationZipCode:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationZipCode || undefined,
        installationResidency:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.installationResidency || INSTALLATION_RESIDENCY[0].value,
      });
      setInitialInstAddressFields({
        xInstallationAddressLine:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationAddressLine || "",
        xInstallationCity:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationCity || "",
        xInstallationState:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationState || "",
        xInstallationZipCode:
          data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationZipCode || "",
      });
    };
    getRequestData(id);
  }, [reset, id]);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard application={application} />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">Modify Loan Information</h3>
        <hr className="text-primary height-2px" />

        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Loan Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormSelect
              list={getProductsList(
                authUserStatus,
                user?.role,
                user?.userDetails?.accessManagement,
                "modify",
                application?.loanInformation?.currentLoanVersion?.commonLoanInfo
                  ?.xprogramtype
              )}
              control={control}
              name="loanType"
              placeholder="Select Loan Product"
              disabled={isFullApplication}
            />
          </Col>
          {isFullApplication && (
            <Col xs={12} md={6} className="mt-3 ">
              <UserFormCurrencyField
                placeholder="Loan Amount"
                control={control}
                name="loanAmount"
                type="number"
              />
            </Col>
          )}
        </Row>
        {isFullApplication === false && (
          <Row className="mt-4">
            <Col>
              <UseFormCheck
                control={control}
                controlName="isMultiPrequal"
                content="If I am not prequalified for the above product, try to prequalify me for other products."
              />
            </Col>
          </Row>
        )}
        {isFullApplication && (
          <>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Home Ownership</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <UseFormSelect
                  list={OWNERSHIP_LIST}
                  control={control}
                  name="xStatedInstallationHomeOwner"
                  placeholder="Who is the current owner of the installation address?"
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Installation Address</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="xInstallationAddressLine"
                  onSelect={(address) => {
                    setValue("xInstallationAddressLine", address.street);
                    setValue("xInstallationCity", address.city);
                    setValue("xInstallationState", address.state);
                    setValue("xInstallationZipCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="xInstallationCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="xInstallationState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="xInstallationZipCode"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Installation Address Residency</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <UseFormSelect
                  list={INSTALLATION_RESIDENCY}
                  control={control}
                  name="installationResidency"
                  defaultValue={INSTALLATION_RESIDENCY[0].value}
                  placeholderValue={0}
                  placeholder="What is the residency type of the installation address?"
                />
              </Col>
            </Row>
          </>
        )}
        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default ModifyLoan;
