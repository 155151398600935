import { Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import {
  createUrl,
  getProgramType,
  isSalesRole,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { systemInfoSchema } from "@shared/schema/request";
import { useEffect, useMemo, useState } from "react";
import NumberFormat from "@shared/components/NumberFormat";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { JointApplication } from "@shared/enums/JointApplication";
import { appUserSelector } from "@store/slices/app.slice";
import {
  getTypesAction,
  issueLoanDocTypeSelector,
} from "@store/slices/commonData.slice";
import { toasterService } from "@services/toaster.service";
import { loanService } from "@services/loan.service";
import { RequestDetailsTabs } from "../request-detail/RequestDetail";
import { MESSAGES, QUERY_PARAMS_KEYS } from "@shared/constants/app.constant";
import { IApplication } from "@shared/interfaces/Application";
import IssueLoanDocsGuard from "@shared/components/IssueLoanDocsGuard";

const IssueDocs = () => {
  const issueLoanDocType = useAppSelector(issueLoanDocTypeSelector);
  const user = useAppSelector(appUserSelector);
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState<IApplication | null>(null);
  const [isJoinInvalid, setisJoinInvalid] = useState(false);

  const history = useHistory();
  let { id } = useParams<{ id: string }>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(systemInfoSchema),
  });

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      const data = response?.data?.data;
      setApplication(data);
    };
    getRequestData(id);
  }, [id]);

  const panelQuantity = watch("panelQuantity");
  const moduleType = watch("moduleType");
  const batteryQuantity = watch("batteryQuantity");
  const batteryType = watch("batteryType");
  const batteryExpansionQuantity = watch("batteryExpansionQuantity");
  const batteryExpansionType = watch("batteryExpansionType");

  const {
    totalBatterySize,
    totalSolarSystemSize,
    moduleSize,
    batterySize,
    batteryExpansionSize,
  } = useMemo(() => {
    const moduleSize =
      issueLoanDocType?.moduleType.find((i) => i.value === Number(moduleType))
        ?.size || 0;
    const batterySize =
      issueLoanDocType?.batteryType.find((i) => i.value === Number(batteryType))
        ?.size || 0;

    const batteryExpansionSize =
      issueLoanDocType?.batteryExpansionType.find(
        (i) => i.value === Number(batteryExpansionType)
      )?.size || 0;

    return {
      totalSolarSystemSize: Number(panelQuantity) * (Number(moduleSize) / 1000),
      totalBatterySize:
        Number(batteryQuantity) * Number(batterySize) +
        Number(batteryExpansionQuantity) * Number(batteryExpansionSize),
      moduleSize,
      batterySize,
      batteryExpansionSize,
    };
  }, [
    issueLoanDocType?.moduleType,
    issueLoanDocType?.batteryType,
    issueLoanDocType?.batteryExpansionType,
    panelQuantity,
    batteryQuantity,
    batteryExpansionQuantity,
    moduleType,
    batteryType,
    batteryExpansionType,
  ]);

  useEffect(() => {
    if (
      !issueLoanDocType?.batteryType.length ||
      !issueLoanDocType?.inverterType?.length ||
      !issueLoanDocType?.moduleType?.length ||
      !issueLoanDocType?.batteryExpansionType?.length
    ) {
      dispatch(getTypesAction());
    }
  }, [dispatch, issueLoanDocType]);

  const isJoint = () => {
    return (
      application?.xInstallationState === "VA" &&
      application?.coapplicant &&
      application?.primaryApplicant
    );
  };

  const onSubmit = async (event: any) => {
    try {
      let xVaSurvivorshipElection = 0;
      if (isJoint()) {
        if (!event.xVaSurvivorshipElection) {
          setisJoinInvalid(true);
          return;
        }
        setisJoinInvalid(false);
        xVaSurvivorshipElection =
          event.xVaSurvivorshipElection ===
          JointApplication.JointWithSurvivorship
            ? 1
            : 2;
      }
      const model = {
        content: {
          xmoduleType: event.moduleType,
          xpanelQuantity: event.moduleType ? Number(event.panelQuantity) : 0,
          xinverterType: event.inverterType,
          xInverterQuantity: event.inverterType
            ? Number(event.inverterQuantity)
            : event.inverterType,
          xbatteryType: event.batteryType,
          xbatteryQuantity: event.batteryType
            ? Number(event.batteryQuantity)
            : 0,
          xBatteryExpansionType: event.batteryExpansionType,
          xBatteryExpansionQuantity: event.batteryExpansionType
            ? Number(event.batteryExpansionQuantity)
            : 0,
          xOtherText: event.other,
          xQuantityofOtherItems: event.other ? Number(event.otherQuantity) : 0,

          xTotalSystemSizeDecimal: totalSolarSystemSize,
          xmoduleWattageDecimal: moduleSize,
          xbatterySize: batterySize,
          xBatteryExpansionSize: batteryExpansionSize,
          xTotalBatterySize: totalBatterySize,
          xVaSurvivorshipElection,
        },
        transformName: "updateContractPrep",
      };

      const response: any = await loanService.uploadContract(id, model);
      if (response) {
        history.push(
          createUrl(
            `/request-detail/${id}?${QUERY_PARAMS_KEYS.REQUEST_DETAILS_TAB}=${
              isSalesRole(user?.role)
                ? RequestDetailsTabs.noticeToProceed
                : RequestDetailsTabs.documents
            }`
          )
        );
      } else {
        toasterService.error(MESSAGES.DEFAULT_ERROR);
      }
    } catch (error) {
      console.error(error);
      toasterService.error(MESSAGES.DEFAULT_ERROR);
    }
  };

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto preQual-request">
      <IssueLoanDocsGuard application={application} />

      <div className="mt-3">
        <h3 className="h3 text-primary-dark">Issue Loan Documents</h3>
        <hr className="bg-primary-dark height-2px opacity-1" />
        <div className="mt-4 loan-info-docs">
          <h4 className="font-bold mb-4">Loan Information for Docs</h4>
          <p>
            Loan Product:{" "}
            <span className="text-primary">
              {application?.loanInformation?.currentLoanVersion?.commonLoanInfo
                ?.xprogramtype
                ? getProgramType(
                    application?.loanInformation?.currentLoanVersion
                      ?.commonLoanInfo?.xprogramtype
                  )
                : null}
            </span>
          </p>
          <p>
            Loan Amount:{" "}
            <span className="text-primary">
              {" "}
              <NumberFormat
                amount={
                  application?.loanInformation?.currentLoanVersion
                    ?.amountFinanced
                }
              />{" "}
            </span>
          </p>
        </div>
      </div>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onSubmitError())}
      >
        <div className="mt-5">
          <h3 className="h3 text-primary-dark">System Information</h3>
          <hr className="bg-primary-dark height-2px opacity-1" />
          <Row className="mt-4">
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Module Type</h4>
              <UseFormSelect
                list={issueLoanDocType?.moduleType}
                control={control}
                name="moduleType"
                placeholder="Module Type"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Panel Quantity</h4>
              <UseFormTextField
                placeholder="Panel Quantity"
                type="number"
                control={control}
                name="panelQuantity"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Inverter Type</h4>
              <UseFormSelect
                list={issueLoanDocType?.inverterType}
                control={control}
                name="inverterType"
                placeholder="Inverter Model Number"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Inverter Quantity</h4>
              <UseFormTextField
                placeholder="Inverter Quantity"
                type="number"
                control={control}
                name="inverterQuantity"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Type</h4>
              <UseFormSelect
                list={issueLoanDocType?.batteryType}
                control={control}
                name="batteryType"
                placeholder="Battery Model Number"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Quantity</h4>
              <UseFormTextField
                placeholder="Battery Quantity"
                type="number"
                control={control}
                name="batteryQuantity"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Expansion Type</h4>
              <UseFormSelect
                list={issueLoanDocType?.batteryExpansionType}
                control={control}
                name="batteryExpansionType"
                placeholder="Battery Expansion Type "
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Expansion Quantity </h4>
              <UseFormTextField
                placeholder="Battery Expansion Quantity"
                type="number"
                control={control}
                name="batteryExpansionQuantity"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Other</h4>
              <UseFormTextField
                placeholder="other"
                control={control}
                name="other"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Other Quantity </h4>
              <UseFormTextField
                placeholder="Other Quantity"
                type="number"
                control={control}
                name="otherQuantity"
              />
            </Col>
          </Row>

          <Row className="system-size mt-4">
            <Col xs={12}>
              <h4 className="font-bold">System Size </h4>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Total Solar System Size:{" "}
                {totalSolarSystemSize ? (
                  <NumberFormat
                    amount={totalSolarSystemSize || undefined}
                    isCurrency={false}
                    symbol=" kW"
                    minimumFractionDigits={0}
                  />
                ) : null}
              </p>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Module Wattage:{" "}
                {moduleSize ? (
                  <NumberFormat
                    amount={moduleSize || undefined}
                    isCurrency={false}
                    symbol=" W"
                    minimumFractionDigits={0}
                  />
                ) : null}
              </p>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Total Battery Size:{" "}
                {totalBatterySize ? (
                  <NumberFormat
                    amount={totalBatterySize || undefined}
                    isCurrency={false}
                    symbol=" kWh"
                    minimumFractionDigits={0}
                  />
                ) : null}
              </p>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Unit Battery Size:{" "}
                {batterySize ? (
                  <NumberFormat
                    amount={batterySize || undefined}
                    isCurrency={false}
                    symbol=" kWh"
                    minimumFractionDigits={0}
                  />
                ) : null}
              </p>
            </Col>
          </Row>

          {isJoint() && (
            <div className="mt-5">
              <h3 className="h3 text-primary-dark">New Account Elections</h3>
              <hr className="bg-primary-dark height-2px opacity-1" />
              <Row className="mt-4">
                <Col xs={12}>
                  <h4 className="font-bold">
                    Credit Union Share Survivorship Election
                  </h4>
                  <p>
                    As part of the loan origination process, a share savings
                    account will be opened with the credit union originating the
                    borrowers’ solar loan. As Virigina residents, they may elect
                    to have this account opened as joint with survivorship or
                    joint with no survivorship. Please specify the borrowers’
                    election:
                  </p>
                </Col>
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="ml-3 d-flex flex-column">
                      <UseFormRadio
                        control={control}
                        elementName="xVaSurvivorshipElection-group"
                        controlName="xVaSurvivorshipElection"
                        content={JointApplication.JointWithSurvivorship}
                        checked={false}
                      />
                      <UseFormRadio
                        control={control}
                        elementName="xVaSurvivorshipElection-group"
                        controlName="xVaSurvivorshipElection"
                        content={JointApplication.JointWithNoSurvivorship}
                        checked={false}
                      />
                      {isJoinInvalid && (
                        <p className="text-danger">Field is required</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div className="mt-5 d-flex justify-content-center mb-5">
            <Link to={`/request-detail/${id}`}>
              <Button variant="outline-primary" type="button" className="me-4">
                Cancel
              </Button>
            </Link>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Issue Loan Documents
            </Button>
          </div>
        </div>
      </form>
    </Col>
  );
};

export default IssueDocs;
