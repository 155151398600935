import { ApplicantType, ApplicationType } from '@shared/enums/ApplicationStatus'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import AppModal from './AppModal'
import ElectronicDisclosure from './ElectronicDisclosure'
import HardCreditPull from './HardCreditPull'
import PrivacyPolicy from './PrivacyPolicy'
import SoftCreditPull from './SoftCreditPull'
import TermsOfUse from './TermsOfUse'

const UserConsent = ({ applicant, type, authorization, consent }: { applicant?: ApplicantType, type: ApplicationType, authorization?: any, consent?: any }) => {
  const [openTermsModal, setOpenTermsModal] = useState(false)
  const [openHardCreditModal, setOpenHardCreditModal] = useState(false)
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)
  const [openSoftCreditModal, setOpenSoftCreditModal] = useState(false)
  const [openDisclosure, setOpenDisclosure] = useState(false)

  return (
    <>
      {type === ApplicationType.Full && (
        <AppModal show={openHardCreditModal} title={'Hard Credit Pull Authorization'} content={<HardCreditPull />}
          onHide={() => setOpenHardCreditModal(false)} />
      )}
      {type === ApplicationType.PreQual && (
        <>
          <AppModal show={openSoftCreditModal} title={'Soft Credit Pull Authorization'} content={<SoftCreditPull />}
            onHide={() => setOpenSoftCreditModal(false)} />

        </>
      )}
      <AppModal show={openDisclosure} title={'Electronic Disclosure Authorization'} content={<ElectronicDisclosure />}
        onHide={() => setOpenDisclosure(false)} />
      <AppModal show={openTermsModal} title={'Terms of Use'} content={<TermsOfUse />}
        onHide={() => setOpenTermsModal(false)} />
      <AppModal show={openPrivacyPolicy} title={'Privacy Policy'} content={<PrivacyPolicy />}
        onHide={() => setOpenPrivacyPolicy(false)} />

      <div className="d-flex flex-column mt-2 ">
        <Row className="mt-4">
          <Col md={12}>
            <h4 className="font-bold">{applicant} Credit Pull Authorization</h4>
            {authorization}
          </Col>
          <Col md={12}>
            {type === ApplicationType.Full && (
              <a href="#/" onClick={() => setOpenHardCreditModal(true)} className="text-primary hover">Hard Pull Credit Authorization</a>
            )}
            {type === ApplicationType.PreQual && (
              <a href="#/" onClick={() => setOpenSoftCreditModal(true)} className="text-primary hover">Soft Pull Credit Authorization</a>
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <h4 className="font-bold">{applicant} Consent</h4>
            {consent}
          </Col>
          <Col md={12}>
            <div className="d-flex flex-column">
              <a href="#/" onClick={() => setOpenDisclosure(true)} className="text-primary hover" >Electronic Disclosure Authorization</a>
              <a href="#/" onClick={() => setOpenTermsModal(true)} className="text-primary hover" >Terms Of Use</a>
              <a href="#/" onClick={() => setOpenPrivacyPolicy(true)} className="text-primary hover">Privacy Policy</a>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default UserConsent