import { IpWhitelist } from "@shared/interfaces/IpWhitelist";
import { AccessManagement, User } from "@shared/interfaces/User";
import { IAuthData } from "./slices/app.slice";

export type PaginationParams = {
  field?: string;
  limit: number;
  page: number;
  search?: string;
};

export type GetUsersParams = PaginationParams & {
  filterByRegion?: string;
  filterByUserStatus?: string;
};

export type GetPaginationResult<T> = {
  results: T[];
  total: number;
};

export interface UserState {
  userData: IGetUserResponse | null;
  usersData: {
    results: IGetUsersList[];
    total: number;
  };
}

export interface IpWhitelistState {
  ipWhitelistData: {
    results: IpWhitelist[];
    total: number;
  };
}

export interface AwardcoState {
  balance: number;
  success: boolean;
}

export enum LoginMethod {
  DEFAULT = "DEFAULT",
  TOTP = "TOTP",
  SMS = "SMS",
}

export interface ILoginSuccessful extends IAuthData {}

export enum LoginChallenge {
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
  SMS_MFA = "SMS_MFA",
}

export interface ILoginWithChallengeResponse {
  challengeName: LoginChallenge;
  session: string;
}

export type LoginUpdatePassword = string;

export interface ILoginResponse {
  code: number;
  response:
    | ILoginWithChallengeResponse
    | ILoginSuccessful
    | LoginUpdatePassword;
}

export interface ILoginSuccessfulResponse {
  code: number;
  response: ILoginSuccessful;
}

export interface IGetUserResponse {
  user: User;
  cognitoData: {
    PreferredMfaSetting?: string;
  };
}

export interface IGetUsersList extends User {
  preferredMfaSetting?: string;
}

export interface IPrequalLoanOffers {
  id: string;
}
