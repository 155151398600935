import { ConditionalRender } from "@shared/components/ConditionalRender";
import { getIssueDocTypeLabel } from "@shared/helpers/documents.helper";
import { IApplication } from "@shared/interfaces/Application";
import { useAppSelector } from "@store/hooks";
import { issueLoanDocTypeSelector } from "@store/slices/commonData.slice";

export const SystemInfo = ({
  requestDetail,
}: {
  requestDetail: IApplication | undefined;
}) => {
  const issueLoanDocType = useAppSelector(issueLoanDocTypeSelector);
  return (
    <div>
      <h4 className="font-bold-800">System Info</h4>
      <p>
        Panels:{" "}
        <ConditionalRender
          value={[
            requestDetail?.contractPrepInfo?.commonLoanInfo?.xpanelQuantity,
            getIssueDocTypeLabel(
              requestDetail?.contractPrepInfo?.commonLoanInfo?.xmoduleType,
              issueLoanDocType?.moduleType
            ),
          ]
            .join(" ")
            .trim()}
        />
      </p>
      <p>
        Inverters:{" "}
        <ConditionalRender
          value={[
            requestDetail?.contractPrepInfo?.commonLoanInfo?.xInverterQuantity,
            getIssueDocTypeLabel(
              requestDetail?.contractPrepInfo?.commonLoanInfo?.xinverterType,
              issueLoanDocType?.inverterType
            ),
          ]
            .join(" ")
            .trim()}
        />
      </p>
      <p>
        Batteries:{" "}
        <ConditionalRender
          value={[
            requestDetail?.contractPrepInfo?.commonLoanInfo?.xbatteryQuantity,
            getIssueDocTypeLabel(
              requestDetail?.contractPrepInfo?.commonLoanInfo?.xbatteryType,
              issueLoanDocType?.batteryType
            ),
          ]
            .join(" ")
            .trim()}
        />
      </p>
    </div>
  );
};
