import { RequestStatus } from "@shared/enums/RequestType";
import { UserAuthStatus } from "@shared/enums/UserState";
import { createUrl } from "@shared/helpers/global.helper";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { errorDetailSelector, setError } from "@store/slices/loan.slice";
import { useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuthUserContext } from "@contexts/AuthContext";
import { isPrequalApplication } from "@shared/helpers/application.helper";

const RequestFailed = () => {
  const errorDetail = useAppSelector(errorDetailSelector);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { authUserStatus } = useAuthUserContext();

  const onExit = () => {
    dispatch(setError(null));
    if (errorDetail?.requestDetail?.appNumber) {
      history.push(
        createUrl(`/request-detail/${errorDetail?.requestDetail?.appNumber}`)
      );
    } else {
      history.push(createUrl("/pipelines"));
    }
  };

  useEffect(() => {
    if (!errorDetail) {
      history.push(createUrl("/pipelines"));
    }
  }, [errorDetail, history]);
  return (
    <div className="mt-4">
      <h6 className="font-bold-800 text-center">{errorDetail?.message}</h6>
      {errorDetail?.type === RequestStatus.Declined &&
        !isPrequalApplication(errorDetail?.requestDetail) && (
          <>
            <Col xs={12} sm={9} md={7} lg={5} className="mx-auto mt-5">
              <p className="sub-title">Reasons for this decision</p>
              <hr className="mb-4 height-2px" />
              {errorDetail?.requestDetail &&
              errorDetail?.requestDetail?.appDenialReasons?.length > 0 ? (
                <div className="reason">
                  {errorDetail?.requestDetail?.appDenialReasons?.map((item) => {
                    return (
                      <div className="d-flex align-items-center mb-3">
                        <p>{item?.description}</p>
                        <span className="ms-3 badge rounded-pill bg-primary">
                          {item.category}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center mb-3 reason">
                  <p className="sub-title">No reasons are available</p>
                </div>
              )}
            </Col>
          </>
        )}
      <div className="text-center mt-5">
        {authUserStatus !== UserAuthStatus.Public && (
          <Button
            onClick={() => {
              onExit();
            }}
          >
            Exit
          </Button>
        )}
      </div>
    </div>
  );
};

export default RequestFailed;
