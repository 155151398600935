import { UseFormTextField } from "@shared/components/TextInput";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { onSubmitError } from "@shared/helpers/global.helper";
import { UseFormTextAreaField } from "@shared/components/TextArea";
import { loanService } from "@services/loan.service";
import { useEffect, useState } from "react";
import { IApplication } from "@shared/interfaces/Application";
import { toasterService } from "@services/toaster.service";
import {
  addLenderNoteSchema,
  updateBookingConfirmationSchema,
} from "@shared/schema/request";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequestDetailsTabs } from "@features/request-detail/RequestDetail";
import { QUERY_PARAMS_KEYS } from "@shared/constants/app.constant";
import { DecisionTabs } from "@features/request-detail/components/Decision";
import { enableBookingConfirmation } from "@shared/helpers/application.helper";
import { appUserSelector } from "@store/slices/app.slice";
import { useAppSelector } from "@store/hooks";

const UpdateBooking = () => {
  const history = useHistory();
  let { id } = useParams<{ id: string }>();
  const user = useAppSelector(appUserSelector);

  const [applicationData, setApplicationData] = useState<IApplication>();
  const { control, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(updateBookingConfirmationSchema),
  });

  const isBusy = formState.isSubmitting;

  useEffect(() => {
    (async () => {
      const response = await loanService.getRequestById(id);
      const applicationData = response?.data?.data;
      if (!enableBookingConfirmation(applicationData, user)) {
        history.push(`/request-detail/${id}`);
        return;
      }

      setApplicationData(response?.data?.data);
    })();
  }, [history, id, user]);

  const onSubmit = async (event: any) => {
    try {
      if (isBusy || !applicationData?.appNumber) {
        return;
      }

      const response = await loanService.completeFundinng(
        {
          xMemberAccountNumber: event.xMemberAccountNumber,
          xNewLoanNumber: event.xNewLoanNumber,
        },
        applicationData?.appNumber
      );
      if (response) {
        toasterService.success("Booking Information updated successfully!");
        history.push(`/request-detail/${id}`);
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center mt-3">
      <div className="login-container form-container">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-8 offset-md-2">
              <div className="d-flex justify-content">
                <h3 className="h3 flex-grow-1">Core System Boarding Info</h3>
              </div>
              <Row className="mb-3">
                <Col xs={12} sm={12} md={6}>
                  <label className="form-input-label mb-3">Member Number</label>
                  <UseFormTextField
                    placeholder="Lender Member Number"
                    control={control}
                    name="xMemberAccountNumber"
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <label className="form-input-label mb-3">
                    New Loan Number
                  </label>
                  <UseFormTextField
                    placeholder="Lender New Loan Number"
                    control={control}
                    name="xNewLoanNumber"
                  />
                </Col>
              </Row>

              <div className="mt-5">
                <h3 className="h3 flex-grow-1">Loan Funding and Boarding</h3>
                <p className="update-booking-boarding-text">
                  Clicking update booking info will update the application with
                  boarding info. The Flic team must still be notified to
                  complete the funding process.
                </p>
              </div>

              <Row className="justify-content-center mt-5">
                <Col xs={3} className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => history.goBack()}
                    disabled={formState.isSubmitting}
                  >
                    Exit
                  </Button>
                </Col>
                <Col xs={6} className="">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={formState.isSubmitting}
                  >
                    Update Booking Information
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateBooking;
