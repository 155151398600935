import { getAdditionalDocumentation } from "@shared/helpers/documents.helper";
import { IApplication } from "@shared/interfaces/Application";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  DownloadDocumentPayload,
  documentsMetadataSelector,
} from "@store/slices/loan.slice";
import { DocumentCard } from "./DocumentCard";
import { useEffect, useMemo } from "react";
import {
  appLendersSelector,
  appUserSelector,
  getLendersAction,
} from "@store/slices/app.slice";
import { getLenderLabel } from "@shared/helpers/user.helper";
import { UserRole } from "@shared/interfaces/User";

export const AdditionalDocumentation = ({
  requestDetail,
  downloadDocument,
}: {
  requestDetail: IApplication | undefined;
  downloadDocument: (value: DownloadDocumentPayload) => void;
}) => {
  const dispatch = useAppDispatch();
  const documentMetadata = useAppSelector(documentsMetadataSelector);
  const lendersData = useAppSelector(appLendersSelector);
  const user = useAppSelector(appUserSelector);

  useEffect(() => {
    if (!lendersData.length && user?.role === UserRole.financialInstitution) {
      // To fetch if the Lenders data is not fetched
      dispatch(getLendersAction(null));
    }
  }, [dispatch, lendersData.length, user?.role]);

  const filteredAdditionalDocumentation = useMemo(() => {
    const additionalDocumentation = documentMetadata
      ? getAdditionalDocumentation(documentMetadata)
      : [];

    if (user?.role === UserRole.financialInstitution) {
      const userLenderId = user?.userDetails?.lenderId;
      return additionalDocumentation.filter(
        (item) =>
          !item.category.startsWith(`xLender:`) ||
          (userLenderId && item.category.startsWith(`xLender:${userLenderId}`))
      );
    }
    return additionalDocumentation;
  }, [documentMetadata, user?.role, user?.userDetails?.lenderId]);

  return (
    <div>
      <h3 className="h3 text-primary-dark">Additional Documentation</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      {filteredAdditionalDocumentation &&
      filteredAdditionalDocumentation.length > 0 ? (
        <h4 className="font-bold-800 mt-4">
          Credit Reports and Other Supporting Documents Include:
        </h4>
      ) : (
        <h4 className="font-bold-800 mt-4">
          There are no additional documents for the application.
        </h4>
      )}

      <div>
        {filteredAdditionalDocumentation.map((item, i) => (
          <DocumentCard
            documentMetadata={item}
            key={i}
            requestDetail={requestDetail}
            downloadDocument={downloadDocument}
          />
        ))}
      </div>
    </div>
  );
};
