import { DocumentMetadata, IApplication } from "@shared/interfaces/Application";
import { useAppSelector } from "@store/hooks";
import {
  DownloadDocumentPayload,
  documentDownloadingSelector,
  documentUploadingSelector,
} from "@store/slices/loan.slice";
import { Download, Search } from "react-bootstrap-icons";

export const DocumentCard = ({
  documentMetadata,
  requestDetail,
  downloadDocument,
}: {
  documentMetadata: DocumentMetadata;
  requestDetail: IApplication | undefined;
  downloadDocument: (value: DownloadDocumentPayload) => void;
}) => {
  const isDocumentDownloading = useAppSelector(documentDownloadingSelector);
  const isDocumentUploading = useAppSelector(documentUploadingSelector);
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-between mt-3">
      <div>
        <div className="d-flex">
          <p>{documentMetadata.label}</p> &nbsp;&nbsp;&nbsp;
        </div>
        <p className="text-success">Received.</p>
      </div>
      <div className="text-center my-3 my-sm-0">
        {requestDetail?.appNumber && documentMetadata.label && (
          <>
            <button
              className="btn btn-light ms-2"
              onClick={() => {
                requestDetail?.appNumber &&
                  downloadDocument({
                    appId: requestDetail?.appNumber,
                    label: [documentMetadata.label],
                    case: "view",
                  });
              }}
              disabled={isDocumentDownloading || isDocumentUploading}
            >
              <Search />
            </button>
            <button
              className="btn btn-light ms-2"
              onClick={() => {
                requestDetail?.appNumber &&
                  downloadDocument({
                    appId: requestDetail?.appNumber,
                    label: [documentMetadata.label],
                    case: "download",
                  });
              }}
              disabled={isDocumentDownloading || isDocumentUploading}
            >
              <Download />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
