import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { networkService } from "@services/network-service";
import { RootState } from "@store/store";
import { RouteComponentProps } from "react-router-dom";
import { toasterService } from "@services/toaster.service";
import { MESSAGES } from "@shared/constants/app.constant";
import { setProcessing } from "./app.slice";
import { loanService } from "@services/loan.service";
import { createUrl } from "@shared/helpers/global.helper";
import {
  DocumentMetadata,
  IApplication,
  IStipulation,
  IModifyLoanPayload,
  IXPrequalProductsTypeValues,
} from "@shared/interfaces/Application";
import { downloadDocumentBlob } from "@shared/helpers/documents.helper";
import { RequestStatus } from "@shared/enums/RequestType";
import { generateLoanObjForMultiPrequal } from "@shared/helpers/application.helper";
import { IPrequalLoanOffers } from "@store/types";

export interface IIssueDocType {
  label: string;
  value: number;
}

export type ApplicationErrorType = {
  type?: RequestStatus;
  message?: string;
  requestDetail?: IApplication;
  offerData?: any;
};

export interface IPipelineApplicationResponse {
  applicationNumber: string; // appNumber: string
  applicationStatus: string; // applicationStatus obj
  decisionStatus: number; // decisionStatus obj
  decisionStatusString: string; // decisionStatus obj
  name: string[]; // primaryApplicant obj
  requestedAmount: number; // response?.loanInformation?.currentLoanVersion?.amountFinanced
  stipulations: IStipulation[]; // appStipulations
  createdOn: number;
  inReviewWithSublender: boolean; // Set by Flip portal backend
}
export interface IPipelineData {
  total: number;
  results: IPipelineApplicationResponse[];
  newApplication: Array<any>;
  newSubmittedApplication: Array<any>;
}

const initialState: any = {
  request: null, // as Partial<IApplication>,
  loanOffer: {} as any,
  prequalLoanOffers: [] as IPrequalLoanOffers[],
  pipelineData: {} as Partial<IPipelineData>,
  documents: [] as any[],
  error: {} as ApplicationErrorType | null,
  requestDraft: null,
  draftDetail: null,
  IsDraftValidated: false,
  IsDocumentUploading: false,
  isDocumentDownloading: false,
  duplicateApps: [] as any[],
  currentApplicationSubmissionData: undefined,
  documentsMetadata: [] as DocumentMetadata[],
  isMultiPrequal: false,
};

export interface DownloadDocumentPayload {
  appId: string;
  label: string[];
  case: "view" | "download";
}

export const postRequestAction = createAsyncThunk(
  "loan/postRequest",
  async (
    {
      model,
      history,
      successUrl,
      force,
      isMultiPrequal,
      xPrequalProducts,
    }: {
      model: any;
      successUrl: string;
      history: RouteComponentProps["history"];
      force?: boolean;
      isMultiPrequal?: boolean;
      xPrequalProducts?: IXPrequalProductsTypeValues[];
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("SUBMITTING", model, isMultiPrequal, xPrequalProducts);
      let appNumber;
      dispatch(setProcessing(true));
      dispatch(setDuplicateApps([]));

      const response: any = await networkService.post(
        `/application${force ? "?force=1" : ""}`,
        model
      );

      // There are duplicates of the submission
      if (response.data?.data?.duplicates?.length > 0) {
        dispatch(
          setDuplicateAppsandCurrentAppSubmissionData({
            duplicateApps: response.data.data.duplicates,
            currentApplicationSubmissionData: {
              model,
              successUrl,
              xPrequalProducts: xPrequalProducts ?? undefined,
              isMultiPrequal,
            },
          })
        );
        dispatch(setProcessing(false));
        return;
      }

      //if no response, keep checking for status
      if (!response.data?.data?.response) {
        const requestStatus: any =
          await loanService.getRequestStatusUntillSuccess(
            response?.data?.data?.id
          );
        if (
          requestStatus.data?.data?.response &&
          requestStatus?.data?.data?.response?.appNumber
        ) {
          appNumber = requestStatus?.data?.data?.response?.appNumber;
        }
      } else {
        if (
          response.data?.data?.response &&
          response.data?.data?.response?.taskStatusLabel !== "Failed"
        ) {
          //if has response and status is completed
          if (response.data?.data?.response?.taskStatusLabel === "Completed") {
            appNumber = response?.data?.data?.response?.appNumber;
          } else {
            //no response, request in progress, keep checking untill get success && for certain attempts
            loanService.resetCount();
            const statusResponse: any =
              await loanService.getRequestStatusUntillSuccess(
                response?.data?.data?.id
              );
            if (
              statusResponse?.data?.data?.response?.taskStatusLabel ===
              "Completed"
            ) {
              appNumber = statusResponse?.data?.data?.response?.appNumber;
            }
          }
        } else {
          const messge =
            response?.data?.response?.ex || response.data?.data?.response?.ex;
          toasterService.error(messge ? messge : MESSAGES.REQUEST_FAILED);
        }
      }

      // got the response and appNumber

      if (appNumber) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (!actionData?.data?.requestDetail) {
          console.error("Not able to get Application data");
          return;
        }
        const isSuccess = await loanService.createTempApplication(
          response?.data?.data.internalAppId,
          actionData?.data?.requestDetail
        );

        // Apply for loans if multi-prequal selected
        if (!isSuccess) {
          dispatch(setProcessing(false));
          dispatch(setError(actionData?.data));
          history.push(createUrl("/request-failed"));
          return;
        }
        if (actionData?.decision === "approved") {
          dispatch(setLoanOffer(actionData.data?.offerData));
          history.push(createUrl(`${successUrl}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else if (
          actionData?.decision === "declined" &&
          isMultiPrequal &&
          xPrequalProducts
        ) {
          // Apply for Multi Prequal
          dispatch(
            updatePrequalForMultiPrequalAction({
              appNumber,
              history,
              loanInfo: generateLoanObjForMultiPrequal(
                actionData?.data?.requestDetail,
                {
                  xPrequalProducts,
                }
              ),
              path: successUrl,
              isMultiPrequal,
            })
          );
          return;
        } else {
          dispatch(setProcessing(false));
          dispatch(setError(actionData?.data));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(MESSAGES.REQUEST_FAILED);
      }

      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const prequalModifyLoanAction = createAsyncThunk(
  "loan/prequalModifyLoanAction",
  async (
    {
      appNumber,
      loanInfo,
      path,
      isMultiPrequal,
      xPrequalProducts,
      history,
      xprogramtype,
    }: {
      loanInfo: any;
      appNumber: string;
      path: string;
      isMultiPrequal?: boolean;
      xPrequalProducts?: IXPrequalProductsTypeValues[];
      history: RouteComponentProps["history"];
      xprogramtype: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let isFailed = false;
      dispatch(setProcessing(true));

      const updatePrequalResponse =
        await loanService.updatePrequalForMultiPrequal(appNumber, loanInfo);

      if (!updatePrequalResponse) {
        isFailed = true;
      }

      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (!actionData?.data?.requestDetail) {
          console.error("Not able to get Application data");
          return;
        }
        if (actionData?.decision === "approved") {
          dispatch(setLoanOffer(actionData.data?.offerData));
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else if (
          actionData?.decision === "declined" &&
          isMultiPrequal &&
          xPrequalProducts
        ) {
          // Apply for Multi Prequal
          dispatch(
            updatePrequalForMultiPrequalAction({
              appNumber,
              history,
              loanInfo: generateLoanObjForMultiPrequal(
                actionData?.data?.requestDetail,
                {
                  xPrequalProducts,
                  xprogramtype,
                }
              ),
              path: path,
              isMultiPrequal,
            })
          );
          return;
        } else {
          dispatch(setProcessing(false));
          dispatch(setError(actionData?.data));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(MESSAGES.REQUEST_FAILED);
      }

      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const updatePrequalForMultiPrequalAction = createAsyncThunk(
  "loan/updatePrequal",
  async (
    {
      loanInfo,
      appNumber,
      history,
      path,
      isMultiPrequal,
    }: {
      loanInfo: any;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
      isMultiPrequal: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false;
      let message;

      if (!isFailed && loanInfo) {
        const updatePrequalResponse =
          await loanService.updatePrequalForMultiPrequal(appNumber, loanInfo);

        if (!updatePrequalResponse) {
          isFailed = true;
          message = MESSAGES.DEFAULT_ERROR;
        }
      }
      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        dispatch(setIsMultiPrequal(isMultiPrequal));
        if (actionData?.decision === "approved") {
          dispatch(setLoanOffer(actionData.data?.offerData));
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const convertRequestAction = createAsyncThunk(
  "loan/convertRequest",
  async (
    {
      primaryApplicant,
      coApplicant,
      loanInfo,
      appNumber,
      history,
      path,
      isEditOfCoborrower,
    }: {
      primaryApplicant: any;
      coApplicant: any;
      loanInfo: any;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
      isEditOfCoborrower: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false,
        message;
      const response1: any = await networkService.put(
        `/application/${appNumber}/applicants`,
        primaryApplicant,
        {
          params: {
            isEdit: 1,
          },
        }
      );
      if (response1.data?.data?.response?.taskStatusLabel === "Failed") {
        isFailed = true;
        message =
          response1?.data?.response?.ex || response1.data?.data?.response?.ex;
      }
      if (!isFailed && coApplicant) {
        const response2: any = await networkService.put(
          `/application/${appNumber}/applicants`,
          coApplicant,
          {
            params: {
              isEdit: isEditOfCoborrower ? 1 : undefined,
            },
          }
        );
        if (response2.data?.data?.response?.taskStatusLabel === "Failed") {
          isFailed = true;
          message =
            response2?.data?.response?.ex || response2.data?.data?.response?.ex;
        }
      }
      if (!isFailed && loanInfo) {
        const response3 = await networkService.put(
          `/application/${appNumber}/modify-loan`,
          loanInfo
        );
        if (!response3.data?.data?.success) {
          isFailed = true;
          message = response3.data?.data?.message;
        }
      }
      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (actionData?.decision === "approved") {
          dispatch(setLoanOffer(actionData.data?.offerData));
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const switchPrequalToFullApp = createAsyncThunk(
  "loan/switchPrequalToFullApp",
  async (
    {
      loanInfo,
      appNumber,
      prequalifiedOfferId,
      history,
      path,
      isEditOfCoborrower,
    }: {
      loanInfo: any;
      appNumber: string;
      prequalifiedOfferId: number;
      path: string;
      history: RouteComponentProps["history"];
      isEditOfCoborrower: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false,
        message;

      const switchPrequalToFullAppResponse =
        await loanService.switchPrequalToFullApp(
          appNumber,
          prequalifiedOfferId,
          loanInfo
        );

      if (!switchPrequalToFullAppResponse) {
        isFailed = true;
        message = MESSAGES.DEFAULT_ERROR;
      }

      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (actionData?.decision === "approved") {
          dispatch(setLoanOffer(actionData.data?.offerData));
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const resubmitApplicationAction = createAsyncThunk(
  "loan/resubmitApplication",
  async (
    {
      loanInfo,
      appNumber,
      history,
      path,
    }: {
      loanInfo: IModifyLoanPayload;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false;
      let message: string = "";
      const response = await networkService.put<any>(
        `/application/${appNumber}/modify-loan`,
        loanInfo
      );
      if (!response.data?.data?.success) {
        isFailed = true;
        message = response.data?.data?.message;
      }

      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (actionData?.decision === "approved") {
          dispatch(setLoanOffer(actionData.data?.offerData));
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const addApplicantAction = createAsyncThunk(
  "loan/addApplicant",
  async (
    {
      model,
      loanInfo,
      appNumber,
      history,
      path,
      isEdit,
    }: {
      model: any;
      loanInfo: any;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
      isEdit: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      const response1: any = await networkService.post(
        `/application/${appNumber}/applicants`,
        model,
        {
          params: {
            isEdit: !!isEdit ? 1 : undefined,
          },
        }
      );
      let isFailed = false,
        message;
      if (response1.data?.data?.response?.taskStatusLabel === "Failed") {
        isFailed = true;
        message =
          response1?.data?.response?.ex || response1.data?.data?.response?.ex;
      }

      if (!isFailed) {
        const response2 = await networkService.put(
          `/application/${appNumber}/modify-loan`,
          loanInfo
        );
        if (!response2.data?.data?.success) {
          isFailed = true;
          message = response2.data?.data?.message;
        }
      }
      if (!isFailed) {
        const requestStatus = await loanService.processRequest(response1);
        if (!requestStatus) {
          const actionData = await loanService.validateRequestStatus(appNumber);
          if (actionData?.decision === "approved") {
            dispatch(setLoanOffer(actionData.data?.offerData));
            history.push(createUrl(`${path}/${appNumber}`));
          } else if (actionData?.decision === "pending") {
            dispatch(setError(actionData?.data));
            history.push(createUrl(`/no-decision`));
          } else {
            dispatch(setError(actionData?.data || null));
            history.push(createUrl("/request-failed"));
          }
        } else {
          toasterService.error(MESSAGES.REQUEST_FAILED);
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

// Add or Modify Applicants Info
export const editApplicantAction = createAsyncThunk(
  "loan/modifyCoBorrower",
  async (
    {
      model,
      loanInfo,
      appNumber,
      history,
      path,
    }: {
      model: any;
      loanInfo: any;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      const response1: any = await networkService.put(
        `/application/${appNumber}/applicants`,
        model,
        {
          params: {
            isEdit: 1,
          },
        }
      );
      let isFailed = false,
        message;
      if (response1.data?.data?.response?.taskStatusLabel === "Failed") {
        isFailed = true;
        message =
          response1?.data?.response?.ex || response1.data?.data?.response?.ex;
      }

      if (!isFailed) {
        const response2 = await networkService.put(
          `/application/${appNumber}/modify-loan`,
          loanInfo
        );
        if (!response2.data?.data?.success) {
          isFailed = true;
          message = response2.data?.data?.message;
        }
      }

      if (!isFailed) {
        const requestStatus = await loanService.processRequest(response1);
        if (!requestStatus) {
          const actionData = await loanService.validateRequestStatus(appNumber);
          if (actionData?.decision === "approved") {
            dispatch(setLoanOffer(actionData.data?.offerData));
            history.push(createUrl(`${path}/${appNumber}`));
          } else if (actionData?.decision === "pending") {
            dispatch(setError(actionData?.data));
            history.push(createUrl(`/no-decision`));
          } else {
            dispatch(setError(actionData?.data || null));
            history.push(createUrl("/request-failed"));
          }
        } else {
          toasterService.error(MESSAGES.REQUEST_FAILED);
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }

      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

//rehashv2 endpoint
export const modifyLoanAction = createAsyncThunk(
  "loan/modifyLoan",
  async (
    {
      model,
      history,
      id,
      path,
      isInstallationAddressUpdated,
    }: {
      model: any;
      history: RouteComponentProps["history"];
      id: string;
      path: string;
      isInstallationAddressUpdated?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      const response = await networkService.put(
        `/application/${id}/loan`,
        model,
        {
          params: {
            ...(isInstallationAddressUpdated !== undefined && {
              isInstallationAddressUpdated: isInstallationAddressUpdated
                ? 1
                : 0,
            }),
          },
        }
      );
      let requestStatus = false;
      if (!response?.data?.data?.success) {
        requestStatus = await loanService.getRequestStatusUntillSuccess(id);
      }
      if (response?.data?.data?.success || requestStatus) {
        const actionData = await loanService.validateRequestStatus(id);
        if (actionData?.decision === "approved") {
          dispatch(setLoanOffer(actionData.data?.offerData));
          history.push(createUrl(`${path}/${id}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        const messge =
          response?.data?.response?.ex || response.data?.data?.response?.ex;
        toasterService.error(messge ? messge : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);
export const getRequestAction = createAsyncThunk(
  "loan/getRequest",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await loanService.getRequestById(id);
      dispatch(setRequestData(response?.data?.data));
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocumentsMetadataAction = createAsyncThunk(
  "loan/getDocumentsMetadataAction",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await loanService.getDocumentsMetadata(id);

      dispatch(setDocumentsMetadata(response?.data?.data ?? []));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadDocumentAction = createAsyncThunk(
  "loan/downloadDocumentAction",
  async (payload: DownloadDocumentPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setIsDocumentDownloading(true));

      const promises = await Promise.all(
        payload.label.map((label) =>
          loanService.downloadDocument(payload.appId, label)
        )
      );

      promises.forEach(({ data, headers, config }) =>
        downloadDocumentBlob(data, headers, config, payload)
      );
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    } finally {
      dispatch(setIsDocumentDownloading(false));
    }
  }
);

export const getLoanOfferAction = createAsyncThunk(
  "loan/getLoanOffer",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await networkService.get(
        `/application/${id}/offers`
      );
      dispatch(setLoanOffer(response?.data?.data));
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const emailLinkAction = createAsyncThunk(
  "loan/emailLink",
  async (
    {
      model,
      history,
      type,
    }: { model: any; type: string; history: RouteComponentProps["history"] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response: any = await networkService.post(`/send-email`, model);
      history.push(`/email-link/success/${type}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPipelinesAction = createAsyncThunk(
  "loan/getPipeline",
  async (
    {
      page,
      limit,
      searchCriteria,
      search,
      tab,
    }: {
      page: number;
      limit: any;
      searchCriteria?: string;
      search?: string;
      tab?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response: any = await networkService.get(`/application/v2`, {
        params: {
          page,
          maxApps: limit,
          searchCriteria: !!searchCriteria ? searchCriteria : undefined,
          search: !!search ? search : undefined,
          tab: !!tab ? tab : undefined,
        },
      });
      dispatch(setPipelineData(response?.data?.data));
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    resetLoanData(state) {
      Object.assign(state, initialState);
    },
    setRequestData: (
      state: any,
      { payload }: PayloadAction<IApplication | null>
    ) => {
      state.request = payload;
    },
    setLoanOffer: (state: any, { payload }: PayloadAction<any>) => {
      state.loanOffer = payload;
    },
    setIsMultiPrequal: (state: any, { payload }: PayloadAction<boolean>) => {
      state.isMultiPrequal = payload;
    },
    setError: (
      state: any,
      { payload }: PayloadAction<ApplicationErrorType | null>
    ) => {
      state.error = payload;
    },
    setDocumentDetail: (state: any, { payload }: PayloadAction<any>) => {
      state.documents = payload;
    },
    setIsDocumentUploading: (state: any, { payload }: PayloadAction<any>) => {
      state.IsDocumentUploading = payload;
    },
    setIsDocumentDownloading: (
      state: any,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDocumentDownloading = payload;
    },
    setRequestDraft: (state: any, { payload }: PayloadAction<any>) => {
      state.requestDraft = payload;
    },
    setIsDraftValidated: (state: any, { payload }: PayloadAction<any>) => {
      state.IsDraftValidated = payload;
    },
    setDraftDetail: (state: any, { payload }: PayloadAction<any>) => {
      state.draftDetail = payload;
    },
    setDocumentsMetadata: (
      state: any,
      { payload }: PayloadAction<DocumentMetadata[]>
    ) => {
      state.documentsMetadata = payload;
    },
    setPipelineData: (state: any, { payload }: PayloadAction<any>) => {
      state.pipelineData = payload;
    },
    setDuplicateAppsandCurrentAppSubmissionData: (
      state: any,
      {
        payload,
      }: PayloadAction<{
        duplicateApps: any[];
        currentApplicationSubmissionData:
          | {
              model: object;
              successUrl: string;
              xPrequalProducts?: IXPrequalProductsTypeValues[];
              isMultiPrequal?: boolean;
            }
          | undefined;
      }>
    ) => {
      state.duplicateApps = payload.duplicateApps;
      state.currentApplicationSubmissionData =
        payload.currentApplicationSubmissionData;
    },
    setDuplicateApps: (state: any, { payload }: PayloadAction<any[]>) => {
      state.duplicateApps = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { reducer: loanReducer } = loanSlice;
export const {
  setRequestData,
  setLoanOffer,
  setDraftDetail,
  setPipelineData,
  setRequestDraft,
  setIsDraftValidated,
  setIsDocumentUploading,
  setIsDocumentDownloading,
  setError,
  setDocumentDetail,
  setDuplicateApps,
  setDuplicateAppsandCurrentAppSubmissionData,
  setDocumentsMetadata,
  resetLoanData,
  setIsMultiPrequal,
} = loanSlice.actions;

export const loanRequestSelector = (
  rootState: RootState
): Partial<IApplication> => rootState.loan.request;
export const pipelineDataSelector = (
  rootState: RootState
): Partial<IPipelineData> => rootState.loan.pipelineData;
export const loanOfferelector = (rootState: RootState) =>
  rootState.loan.loanOffer;
export const isMultiPrequalSelector = (rootState: RootState): boolean =>
  rootState.loan.isMultiPrequal;
export const errorDetailSelector = (
  rootState: RootState
): ApplicationErrorType | null => rootState.loan.error;
export const documentsSelector = (rootState: RootState) =>
  rootState.loan.documents;
export const loanSliceSelector = (rootState: RootState) => rootState.loan;
export const documentsMetadataSelector = (
  rootState: RootState
): DocumentMetadata[] | undefined => rootState.loan.documentsMetadata;
export const documentDownloadingSelector = (rootState: RootState): boolean =>
  rootState.loan.isDocumentDownloading;
export const documentUploadingSelector = (rootState: RootState): boolean =>
  rootState.loan.IsDocumentUploading;
