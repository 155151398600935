import { ConditionalRender } from "@shared/components/ConditionalRender";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isPending, maskMobile } from "@shared/helpers/global.helper";
import { NA } from "@shared/constants/app.constant";
import NumberFormat from "@shared/components/NumberFormat";
import { useAppSelector } from "@store/hooks";
import { isNoDecisionRefId } from "@shared/helpers/documents.helper";
import { IApplication } from "@shared/interfaces/Application";
import { isPrequalApplication } from "@shared/helpers/application.helper";
import { PendingDecision } from "./PendingDecision";
import LoanOptions from "./application/LoanOptions";
import { CoBorrowerData } from "./application/CoBorrowerData";
import { LoanInformation } from "./application/LoanInformation";

export const RequestApplication = ({
  data,
  id,
  canBeModified,
  canBeCoverted,
}: {
  data: IApplication | undefined;
  id: string;
  canBeModified: boolean;
  canBeCoverted: boolean;
}) => {
  const loanSlice = useAppSelector((state) => state.loan);
  const isLoading = !data;
  const isPrequal = isPrequalApplication(data);

  const primaryData = data?.primaryApplicant;
  const primaryAddress = primaryData?.addresses.find(
    (x) => x.addressType.label === "Primary"
  );
  const mailingAddress = primaryData?.addresses.find(
    (x) => x.addressType.label === "Mailing"
  );
  const stipulations = data?.appStipulations?.filter((item: any) =>
    isNoDecisionRefId(item)
  );
  const pendingDocumentInstructions = stipulations?.map(
    (u) => u.stipDescription
  );

  return (
    <>
      <div className="mt-5">
        <PendingDecision
          data={data}
          pendingDocumentInstructions={pendingDocumentInstructions}
        />

        {}

        {isPrequal &&
          data?.decisionStatus?.label &&
          !isPending(data?.decisionStatus?.label) && (
            <LoanOptions
              loanOffers={data?.prequalifiedOffers || []}
              data={data}
              canBeCoverted={canBeCoverted}
            />
          )}

        {/* For Full application */}
        {!isPrequal && (
          <LoanInformation data={data} id={id} isPrequal={isPrequal} />
        )}

        {id !== "new" && !isLoading && canBeModified && !isPrequal && (
          <Row>
            <Col xs={12} className="text-center text-md-end mt-4">
              <Link to={`/modify-loan/${id}`}>
                <Button variant="outline-primary-dark">
                  Modify Application
                </Button>
              </Link>
            </Col>
          </Row>
        )}
        <h3 className="h3 text-primary-dark">Primary Borrower Information</h3>
        <hr className="bg-primary-dark height-2px opacity-1" />
        <Row className="mt-3">
          <Col xs={12} sm={6} md={4}>
            <h4 className="font-bold-800">Demographics</h4>

            {id === "new" && (
              <p>
                {loanSlice.draftDetail?.first_name}&nbsp;
                {loanSlice.draftDetail?.last_name}
              </p>
            )}
            {id !== "new" && <p>{primaryData?.fullName}</p>}
            <p>
              Citizenship:&nbsp;
              <ConditionalRender
                value={
                  typeof primaryData?.isUSCitizen === "boolean"
                    ? primaryData?.isUSCitizen
                      ? "US Citizen"
                      : "Non US Citizen"
                    : null
                }
              />
            </p>
            {typeof primaryData?.isUSCitizen === "boolean" &&
              !primaryData?.isUSCitizen && (
                <p>
                  Permanent Resident:&nbsp;
                  {primaryData?.xIsPermanentResident ? "Yes" : "No"}
                </p>
              )}
          </Col>
          <Col xs={12} sm={6} md={4}>
            <h4 className="font-bold-800">Contact</h4>
            {id === "new" && (
              <p>
                Mobile:&nbsp;
                <ConditionalRender
                  value={maskMobile(loanSlice.draftDetail?.mobile_number)}
                />
              </p>
            )}
            {id !== "new" && (
              <p>
                Mobile:&nbsp;
                <ConditionalRender
                  value={maskMobile(primaryData?.mobilePhone || "")}
                />
              </p>
            )}

            {id === "new" && (
              <p className="break-word">
                Email:&nbsp;
                <ConditionalRender value={loanSlice.draftDetail?.email} />
              </p>
            )}
            {id !== "new" && (
              <p className="break-word">
                Email: <ConditionalRender value={primaryData?.email} />
              </p>
            )}
          </Col>
          <Col xs={12} sm={6} md={4}>
            <h4 className="font-bold-800">Income and Employment</h4>
            <p>
              Gross Annual Income:&nbsp;
              <NumberFormat
                amount={primaryData?.currentEmployments[0]?.grossIncome}
              />
            </p>
            <p>
              Employer:&nbsp;
              <ConditionalRender
                value={primaryData?.currentEmployments[0]?.employerName}
              />
            </p>
            <p>
              Time On Job:&nbsp;
              {primaryData?.currentEmployments[0]?.yearsEmployed
                ? primaryData?.currentEmployments[0]?.yearsEmployed + " Years"
                : NA}
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} sm={6} md={4}>
            <h4 className="font-bold-800">Current Address</h4>
            {primaryAddress ? (
              <p>
                {primaryAddress?.addressLine1 + ", "}
                {primaryAddress?.city + ", "}
                {primaryAddress?.state + " "}
                {primaryAddress?.postalCode}
              </p>
            ) : (
              <p>{NA}</p>
            )}
          </Col>
          {id !== "new" && (
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Mailing Address</h4>
              {mailingAddress && (
                <p>
                  {mailingAddress?.addressLine1 + ", "}
                  {mailingAddress?.city + ", "}
                  {mailingAddress?.state + " "}
                  {mailingAddress?.postalCode}
                </p>
              )}
              {!mailingAddress && <p>Same as current address</p>}
            </Col>
          )}
          <Col></Col>
        </Row>
        {id !== "new" && !isLoading && canBeModified && (
          <Row>
            <Col xs={12} className="text-center text-md-end mt-3">
              {/* {isPrequal && (
                <Link to={`/pre-qualification/edit/${id}`}>
                  <Button variant="outline-primary-dark">
                    Modify Applicant
                  </Button>
                </Link>
              )} */}
              {!isPrequal && (
                <Link to={`/new-application/edit/${id}`}>
                  <Button variant="outline-primary-dark">
                    Modify Applicant
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
        )}

        <CoBorrowerData
          canBeModified={canBeModified}
          data={data}
          id={id}
          isLoading={isLoading}
          isPrequal={isPrequal}
        />
      </div>
    </>
  );
};
