import React from "react";
import { ConditionalRender } from "@shared/components/ConditionalRender";
import NumberFormat from "@shared/components/NumberFormat";
import { Link } from "react-router-dom";
import { NA } from "@shared/constants/app.constant";
import { maskMobile } from "@shared/helpers/global.helper";
import { IApplication } from "@shared/interfaces/Application";
import { Button, Col, Row } from "react-bootstrap";

export const CoBorrowerData = ({
  data,
  id,
  canBeModified,
  isLoading,
  isPrequal,
}: {
  data: IApplication | undefined;
  id: string;
  canBeModified: boolean;
  isLoading: boolean;
  isPrequal: boolean;
}) => {
  const coBorrowerData = data?.coapplicant;
  const coBorrowerAddress = data?.coapplicant?.addresses?.[0];
  const primaryData = data?.primaryApplicant;

  return (
    <>
      <h3 className="h3 text-primary-dark">CoBorrower Information</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      {coBorrowerData ? (
        <>
          <Row className="mt-3">
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Demographics</h4>
              <p>{coBorrowerData?.fullName}</p>
              <p>
                Citizenship:&nbsp;
                <ConditionalRender
                  value={primaryData?.isUSCitizen ? "Yes" : "No"}
                />
              </p>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Contact</h4>
              <p>
                Mobile:&nbsp;
                <ConditionalRender
                  value={maskMobile(coBorrowerData?.mobilePhone)}
                />
              </p>
              <p>
                Email: <ConditionalRender value={coBorrowerData?.email} />
              </p>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Income and Employment</h4>
              <p>
                Gross Annual Income:&nbsp;
                <NumberFormat
                  amount={coBorrowerData?.currentEmployments[0]?.grossIncome}
                />
              </p>
              <p>
                Employer:&nbsp;
                <ConditionalRender
                  value={coBorrowerData?.currentEmployments[0]?.employerName}
                />
              </p>
              <p>
                Time On Job:&nbsp;
                {coBorrowerData?.currentEmployments[0]?.yearsEmployed
                  ? coBorrowerData?.currentEmployments[0]?.yearsEmployed +
                    " Years"
                  : NA}
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Current Address</h4>
              {coBorrowerAddress ? (
                <p>
                  {coBorrowerAddress?.addressLine1 + ", "}
                  {coBorrowerAddress?.city + ", "}
                  {coBorrowerAddress?.state + " "}
                  {coBorrowerAddress?.postalCode}
                </p>
              ) : (
                <p>{NA}</p>
              )}
            </Col>
          </Row>
          {id !== "new" && !isLoading && canBeModified && !isPrequal && (
            <Row>
              <Col xs={12} className="d-flex justify-content-end">
                <Link
                  to={
                    isPrequal
                      ? `/pre-qualification/co-borrower/${id}?type=edit`
                      : `/new-application/co-borrower/${id}?type=edit`
                  }
                >
                  <Button variant="outline-primary-dark">
                    Modify Borrower
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <>
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 ">
            <div>
              <p className="">There is no borrower associated with loan</p>
            </div>
            {id !== "new" && !isLoading && canBeModified && !isPrequal && (
              <div>
                <Link
                  to={
                    isPrequal
                      ? `/pre-qualification/co-borrower/${id}`
                      : `/new-application/co-borrower/${id}`
                  }
                >
                  <Button variant="outline-primary-dark">Add Borrower</Button>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
