import { ConditionalRender } from "@shared/components/ConditionalRender";
import {
  getConsumerStatement,
  getConsumerStatementManualClearStatusColor,
  getConsumerStatementStatusColor,
  getCoreLogicPropertyTypeCode,
  getIsManufacturedHome,
  getIsManufacturedHomeStatusColor,
  getIsOwnedByLlc,
  getIsOwnedByLlcStatusColor,
  getOfacAlert,
  getOfacAlertStatusColor,
  getOfacManualClear,
  getOfacManualClearStatusColor,
  getStatementManuallyCleared,
  getUnderWritingExceptionStatusColor,
  getUnderwritingException,
} from "@shared/helpers/evaluations.helper";
import { IApplication } from "@shared/interfaces/Application";
import clsx from "clsx";
import { Col, Row } from "react-bootstrap";

type ApplicationDetailsType1 = Array<{
  label: JSX.Element;
  value1: JSX.Element;
  value2: JSX.Element;
  isHeader?: boolean;
}>;

type ApplicationDetailsType2 = Array<{
  label: JSX.Element;
  value1: JSX.Element;
  isHeader?: boolean;
}>;

const RequestTable = ({ data }: { data: ApplicationDetailsType1 }) => (
  <>
    {data.map((item, index) => (
      <Row className="" key={index}>
        <Col xs={6}>{item.isHeader ? item.label : <p>{item.label}</p>}</Col>

        <Col xs={3}>{item.value1}</Col>

        <Col xs={3}>{item.value2}</Col>
      </Row>
    ))}
  </>
);

const RequestTable2 = ({ data }: { data: ApplicationDetailsType2 }) => (
  <>
    {data.map((item, index) => (
      <Row className="" key={index}>
        <Col xs={6}>{item.isHeader ? item.label : <p>{item.label}</p>}</Col>

        <Col xs={6}>{item.value1}</Col>
      </Row>
    ))}
  </>
);

export const VerificationsAndExceptions = ({
  data,
}: {
  data: IApplication | undefined;
}) => {
  const primaryApplicant = data?.primaryApplicant;
  const coapplicant = data?.coapplicant;
  const commonLoanInfo =
    data?.loanInformation?.currentLoanVersion?.commonLoanInfo;

  const primaryApplicantConsumerStatementNotes = `${
    primaryApplicant?.xConsStmtNotes ?? ""
  }`;
  const coApplicantConsumerStatementNotes = `${
    coapplicant?.xConsStmtNotes ?? ""
  }`;

  const consumerStatements: ApplicationDetailsType1 = [
    {
      label: <h4>Consumer Statements</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>Consumer Statement:</>,
      value1: (
        <p
          className={clsx(
            getConsumerStatementStatusColor(primaryApplicant?.xConsStmtExist)
          )}
        >
          {getConsumerStatement(primaryApplicant?.xConsStmtExist)}
        </p>
      ),
      value2: (
        <p
          className={clsx(
            getConsumerStatementStatusColor(coapplicant?.xConsStmtExist)
          )}
        >
          {getConsumerStatement(coapplicant?.xConsStmtExist)}
        </p>
      ),
    },
    {
      label: <>Statement Manually Cleared:</>,
      value1: (
        <p
          className={clsx(
            getConsumerStatementManualClearStatusColor(
              getStatementManuallyCleared(primaryApplicant)
            )
          )}
        >
          {getStatementManuallyCleared(primaryApplicant)}
        </p>
      ),
      value2: (
        <p
          className={clsx(
            getConsumerStatementManualClearStatusColor(
              getStatementManuallyCleared(coapplicant)
            )
          )}
        >
          {getStatementManuallyCleared(coapplicant)}
        </p>
      ),
    },
  ];

  const ofacProcessing: ApplicationDetailsType1 = [
    {
      label: <h4>OFAC Processing</h4>,
      value1: <h4>App</h4>,
      value2: <h4>CoApp</h4>,
      isHeader: true,
    },
    {
      label: <>OFAC Alert: </>,
      value1: (
        <p
          className={clsx(
            getOfacAlertStatusColor(primaryApplicant?.xOFACMatch)
          )}
        >
          {getOfacAlert(primaryApplicant?.xOFACMatch)}
        </p>
      ),
      value2: (
        <p className={clsx(getOfacAlertStatusColor(coapplicant?.xOFACMatch))}>
          {getOfacAlert(coapplicant?.xOFACMatch)}
        </p>
      ),
    },
    {
      label: <>OFAC Manually Cleared:</>,
      value1: (
        <p
          className={clsx(
            getOfacManualClearStatusColor(getOfacManualClear(primaryApplicant))
          )}
        >
          {getOfacManualClear(primaryApplicant)}
        </p>
      ),
      value2: (
        <p
          className={clsx(
            getOfacManualClearStatusColor(getOfacManualClear(coapplicant))
          )}
        >
          {getOfacManualClear(coapplicant)}
        </p>
      ),
    },
  ];

  const proofOfOwnership: ApplicationDetailsType1 = [
    {
      label: <h4>Proof of Ownership</h4>,
      value1: <h4>First</h4>,
      value2: <h4>Last</h4>,
      isHeader: true,
    },
    {
      label: <>Corelogic Owners:</>,
      value1: (
        <p>
          <ConditionalRender
            value={commonLoanInfo?.corelogicFirstNameAndMiddleInitial}
          />
        </p>
      ),
      value2: (
        <p>
          <ConditionalRender value={commonLoanInfo?.corelogicLastName} />
        </p>
      ),
    },
    ...(commonLoanInfo?.coreLogicFirstNameAndMiddleInitial2 ||
    commonLoanInfo?.coreLogicLastName2
      ? [
          {
            label: <></>,
            value1: commonLoanInfo?.coreLogicFirstNameAndMiddleInitial2 ? (
              <p>
                <ConditionalRender
                  value={commonLoanInfo?.coreLogicFirstNameAndMiddleInitial2}
                />
              </p>
            ) : (
              <></>
            ),
            value2: commonLoanInfo?.coreLogicLastName2 ? (
              <p>
                <ConditionalRender value={commonLoanInfo?.coreLogicLastName2} />
              </p>
            ) : (
              <></>
            ),
          },
        ]
      : []),
  ];

  const propertyTypeVerification: ApplicationDetailsType2 = [
    {
      label: <h4>Property Type Verification</h4>,
      value1: <h4>Installation Property</h4>,
      isHeader: true,
    },
    {
      label: <>Core Logic Proprty Type:</>,
      value1: (
        <p>
          {getCoreLogicPropertyTypeCode(
            commonLoanInfo?.corelogicPropertyTypeCode
          )}
        </p>
      ),
    },
    {
      label: <>Is a Manufactured Home:</>,
      value1: (
        <p
          className={clsx(
            getIsManufacturedHomeStatusColor(
              commonLoanInfo?.corelogicPropertyIsManufacturedHome
            )
          )}
        >
          {getIsManufacturedHome(
            commonLoanInfo?.corelogicPropertyIsManufacturedHome
          )}
        </p>
      ),
    },
  ];

  const UnderwritingException = () => (
    <div>
      <h4>Underwriting Exception</h4>
      <p>
        Exception on file:{" "}
        <span
          className={clsx(
            getUnderWritingExceptionStatusColor(data?.xHasUwException)
          )}
        >
          {getUnderwritingException(data?.xHasUwException)}
        </span>
      </p>
      {data?.xException && <p>Reason: {data?.xException}</p>}
    </div>
  );

  return (
    <div className="">
      <Row className="">
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable data={consumerStatements} />
          <p>{primaryApplicantConsumerStatementNotes}</p>
          <p>{coApplicantConsumerStatementNotes}</p>
        </Col>
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable data={ofacProcessing} />
        </Col>
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable data={proofOfOwnership} />
          <p>
            Core Logic Ownership Rights Code:{" "}
            <ConditionalRender
              value={commonLoanInfo?.coreLogicOwnershipRightsCode}
            />
          </p>
          <p>
            Core Logic Is Owned By LLC?:{" "}
            <span
              className={clsx(
                getIsOwnedByLlcStatusColor(commonLoanInfo?.corelogicIsCorporate)
              )}
            >
              {getIsOwnedByLlc(commonLoanInfo?.corelogicIsCorporate)}
            </span>
          </p>
        </Col>
        <Col xs={12} md={12} lg={6} xl={6} className="mt-4">
          <RequestTable2 data={propertyTypeVerification} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          <UnderwritingException />
        </Col>
      </Row>
    </div>
  );
};
