import moment from "moment";
import { Link } from "react-router-dom";
import { IApplication, ICustomNotes } from "@shared/interfaces/Application";
import clsx from "clsx";

const lenderNotesTableColSpanClasses = {
  subject: "col-4 border-0",
  date: "col-2",
  note: "col-6",
};

const LenderNotesRow = ({ note }: { note: ICustomNotes }) => {
  return (
    <div className="row lender-notes-table-row" key={note.dateCreatedinMS}>
      <div className={clsx(lenderNotesTableColSpanClasses.subject, "subject")}>
        Loan Note: {note?.title}
      </div>
      <div className={clsx(lenderNotesTableColSpanClasses.date, "date")}>
        {note.dateCreatedinMS &&
          moment(note.dateCreatedinMS).format("MMM Do, YYYY")}
      </div>
      <div className={clsx(lenderNotesTableColSpanClasses.note, "note")}>
        {note?.comments}
      </div>
    </div>
  );
};

export const LenderNotes = ({
  notes,
  data,
}: {
  notes: ICustomNotes[];
  data: IApplication | undefined;
}) => {
  return (
    <div className="p-3 mt-6">
      <div className="mb-4">
        <Link
          to={`/application/${data?.appNumber}/add-note`}
          className="add-lender-notes-link"
        >
          Add Notes
        </Link>
      </div>
      <div>
        <div className="row evaluation-tab-table-header">
          <div
            className={clsx(
              lenderNotesTableColSpanClasses.subject,
              "text-center fw-bold"
            )}
          >
            Subject
          </div>
          <div className={clsx(lenderNotesTableColSpanClasses.date)}>Date</div>
          <div className={clsx(lenderNotesTableColSpanClasses.note)}>Note</div>
        </div>

        {/* Rows */}
        {notes.map((note) => (
          <LenderNotesRow note={note} key={note.dateCreatedinMS} />
        ))}

        {notes.length === 0 && (
          <p className="text-center m-5">No Notes Added.</p>
        )}
      </div>
    </div>
  );
};
