import { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UseFormDatePicker } from "@shared/components/UserFormDatePicker";
import {
  CITIZENSHIP,
  INSTALLATION_RESIDENCY,
  MOBILE_MASK,
  MONTHS_ARRAY,
  OWNERSHIP_LIST,
  PRIVATE_PROGRAM_TYPE,
  SSN_MASK,
  YEARS_ARRAY,
} from "@shared/constants/app.constant";
import {
  removeSpaces,
  unmaskMobile,
  maskMobile,
  onSubmitError,
  getProductsList,
} from "@shared/helpers/global.helper";
import {
  postRequestAction,
  loanSliceSelector,
  setDuplicateAppsandCurrentAppSubmissionData,
} from "@store/slices/loan.slice";
import { MyInputMask } from "@shared/components/MyInputMask";
import { newRequestSchema } from "@shared/schema/request";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import UserConsent from "@shared/components/UserConsent";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { appUserSelector } from "@store/slices/app.slice";
import { UserAuthStatus } from "@shared/enums/UserState";
import PrivateLoanProductRedirect from "@shared/components/PrivateLoanProductRedirect";
import { useAuthUserContext } from "@contexts/AuthContext";
import { formatDateForDl4Submission } from "@utils/date";

const Request = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loanSlice = useAppSelector(loanSliceSelector);
  const { authUserStatus } = useAuthUserContext();

  const user = useAppSelector(appUserSelector);

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    resolver: yupResolver(newRequestSchema),
  });

  const onSubmit = (event: any) => {
    const applicants = [];
    const applicantAddresses = [];
    const currentAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: event.postalCode,
      addressLine1: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };
    applicantAddresses.push(currentAddress);
    if (event.isMailingAdressSame) {
      applicantAddresses.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Mailing",
          label: "Mailing",
          value: 3,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.mailingZipCode,
        addressLine1: event.mailingAddressLine1,
        city: event.mailingCity,
        state: event.mailingState,
      });
    }
    const applicanEmployment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      monthsEmployed: event.monthsEmployed ? Number(event.monthsEmployed) : 0,
      yearsEmployed: event.yearsEmployed ? Number(event.yearsEmployed) : 0,
      employerName: event.employerName,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };
    const coBorrowerEmployment = {
      employmentType: {
        name: "Seconday",
        label: "Seconday",
        value: 1,
      },
      grossIncome: Number(event.coBorrowerGrossIncome),
      monthsEmployed: event.coBorrowerMonths
        ? Number(event.coBorrowerMonths)
        : 0,
      yearsEmployed: event.coBorrowerYear ? Number(event.coBorrowerYear) : 0,
      employerName: event.coBorrowerEmployerName,
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };
    applicants.push({
      applicantType: 1,
      lastName: event.lastName,
      firstName: event.firstName,
      SSN: removeSpaces(event.SSN),
      dateOfBirth: formatDateForDl4Submission(event.dateOfBirth),
      email: event.email,
      isUSCitizen: event.isUSCitizen === "Yes" ? true : false,
      xIsPermanentResident:
        event.isUSCitizen === "Yes"
          ? null
          : event.xIsPermanentResident === "Yes"
          ? true
          : false,
      mobilePhone: unmaskMobile(event.mobilePhone),
      addresses: applicantAddresses,
      employments: [applicanEmployment],
      xInstallationHomeOwner: 1, // it should be always 1 as home owner
    });
    if (event.hasCoApplicant === "Yes") {
      let coBorrowerAddress;
      if (event.xDiffAddressThanApplicant === "No") {
        coBorrowerAddress = {
          ownershipType: {
            name: "Other",
            label: "Other",
            value: 4,
          },
          addressType: {
            name: "Primary",
            label: "Primary",
            value: 1,
          },
          addressFormat: {
            name: "Postal Standard",
            label: "Postal Standard",
            value: 5,
          },
          postalCode: event.coBorrowerPostalCode,
          addressLine1: event.coBorrowerAddressLine1,
          city: event.coBorrowerCity,
          state: event.coBorrowerState,
          yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
          monthsAtResidence: Number(event.coBorrowerMonthsAtResidence),
        };
      }
      applicants.push({
        applicantType: 2,
        lastName: event.coBorrowerLastName,
        firstName: event.coBorrowerFirstName,
        SSN: removeSpaces(event.coBorrowerssn),
        dateOfBirth: formatDateForDl4Submission(event.coBorrowerdob),
        email: event.coBorrowerEmail,
        isUSCitizen: event.coBorrowerusCitizen === "Yes" ? true : false,
        xIsPermanentResident:
          event.coBorrowerusCitizen === "Yes"
            ? null
            : event.coBorrowerxIsPermanentResident === "Yes"
            ? true
            : false,
        mobilePhone: unmaskMobile(event.coBorrowerMobilePhone),
        addresses:
          event.xDiffAddressThanApplicant === "Yes"
            ? applicantAddresses
            : [coBorrowerAddress],
        employments: [coBorrowerEmployment],
        xInstallationHomeOwner: 1, // it should be always 1 as home owner
        xDiffAddressThanApplicant:
          event.xDiffAddressThanApplicant === "Yes" ? false : true,
      });
    }
    const model = {
      Prequal: false,
      xSecondSubmission: false,
      appSource: "Flic Frontend",
      disclosureAck: event.disclosureAck,
      hasCoApplicant: event.hasCoApplicant === "Yes" ? true : false,
      ...(event.hasCoApplicant === "Yes" && { isJoint: true }),
      applicants: applicants,
      loanInformation: {
        loanVersions: [
          {
            totalCashPrice: parseInt(event.loanAmount),
            commonLoanInfo: {
              xprogramtype: Number(event?.loanType),
              xInstallationAddressLine: xInstalationAddressSame
                ? event.xInstallationAddressLine
                : event.addressLine1,
              xInstallationCity: xInstalationAddressSame
                ? event.xInstallationCity
                : event.city,
              xInstallationState: xInstalationAddressSame
                ? event.xInstallationState
                : event.state,
              xInstallationZipCode: xInstalationAddressSame
                ? event.xInstallationZipCode
                : event.postalCode,
              xInstalationAddressSame: !event.xInstalationAddressSame,
              xStatedInstallationHomeOwner: Number(
                event.xStatedInstallationHomeOwner
              ),
              installationResidency: event.installationResidency || 1,
            },
          },
        ],
      },
      xInstallationAddressLine: xInstalationAddressSame
        ? event.xInstallationAddressLine
        : event.addressLine1,
      xInstallationCity: xInstalationAddressSame
        ? event.xInstallationCity
        : event.city,
      xInstallationState: xInstalationAddressSame
        ? event.xInstallationState
        : event.state,
      xInstallationZipCode: xInstalationAddressSame
        ? event.xInstallationZipCode
        : event.postalCode,
      xInstalationAddressSame: !event.xInstalationAddressSame,
      xStatedInstallationHomeOwner: Number(event.xStatedInstallationHomeOwner),
      installationResidency: event.installationResidency || 1,
    };

    // create new request flow
    // For customers, we would not display Duplicate applications
    const force = authUserStatus === UserAuthStatus.Public ? true : false;
    dispatch(
      postRequestAction({
        model: {
          content: model,
          loanType: "Solar loan",
        },
        history,
        successUrl: "/new-application/confirmation",
        force,
      })
    );
  };

  useEffect(() => {
    const bindDraftData = () => {
      reset({
        firstName: loanSlice.requestDraft?.firstName,
        lastName: loanSlice.requestDraft?.lastName,
        loanType: loanSlice.requestDraft?.loanProduct,
        loanAmount: loanSlice.requestDraft?.loanAmount,
        email: loanSlice.requestDraft?.email,
        mobilePhone: maskMobile(loanSlice.requestDraft?.mobileNumber),
        hasCoApplicant: "No",
        xDiffAddressThanApplicant: "Yes",
        SSN: "",
      });
    };

    reset({
      isMailingAdressSame: undefined,
      xDiffAddressThanApplicant: undefined,
    });
    if (loanSlice.requestDraft) {
      bindDraftData();
      return;
    }

    setTimeout(
      () =>
        reset({
          hasCoApplicant: "No",
          xDiffAddressThanApplicant: "Yes",
          xInstalationAddressSame: false,
          isMailingAdressSame: false,
          SSN: "",
        }),
      1000
    );
  }, [loanSlice.requestDraft, reset]);

  const ssn = watch("SSN");
  const xInstalationAddressSame = watch("xInstalationAddressSame");
  const hasCoApplicant = watch("hasCoApplicant");
  const isMailingAdressSame = watch("isMailingAdressSame");
  const xDiffAddressThanApplicant = watch("xDiffAddressThanApplicant");
  const isUSCitizen = watch("isUSCitizen");
  const coBorrowerusCitizen = watch("coBorrowerusCitizen");
  const loanType = watch("loanType");

  useEffect(() => {
    if (ssn && loanSlice.duplicateApps?.length > 0) {
      history.push("/duplicate/applications");
    }
  }, [loanSlice.duplicateApps, ssn, history]);

  useEffect(() => {
    if (!ssn) {
      dispatch(
        setDuplicateAppsandCurrentAppSubmissionData({
          duplicateApps: [],
          currentApplicationSubmissionData: undefined,
        })
      );
    }
  }, [dispatch, ssn]);

  const isExternalLoanProduct = !!PRIVATE_PROGRAM_TYPE.find(
    (product) =>
      loanType &&
      product.id === loanType?.toString() &&
      product.type === "EXTERNAL"
  );

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
      <h3 className="h3 text-primary">Solar Loan Application</h3>
      <hr className="text-primary height-2px" />
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onSubmitError())}
      >
        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Loan Information</h4>
          </Col>
          <Col xs={12} md={6}>
            <UseFormSelect
              list={getProductsList(
                authUserStatus,
                user?.role,
                user?.userDetails?.accessManagement,
                "new",
                loanSlice.requestDraft?.loanProduct
                  ? Number(loanSlice.requestDraft?.loanProduct)
                  : undefined
              )}
              control={control}
              name="loanType"
              placeholder="Select Loan Product"
            />
          </Col>
          {!isExternalLoanProduct && (
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UserFormCurrencyField
                placeholder="Loan Amount"
                control={control}
                type="number"
                name="loanAmount"
              />
            </Col>
          )}
        </Row>
        {isExternalLoanProduct && <PrivateLoanProductRedirect />}
        {!isExternalLoanProduct && (
          <>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Home Ownership</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <UseFormSelect
                  list={OWNERSHIP_LIST}
                  control={control}
                  name="xStatedInstallationHomeOwner"
                  placeholder="Who is the current owner of the installation address?"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Your Information</h4>
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="First Name"
                  control={control}
                  name="firstName"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="Last Name"
                  control={control}
                  name="lastName"
                />
              </Col>

              <Col xs={12} md={6} className="mt-3">
                <MyInputMask
                  control={control}
                  defaultValue={""}
                  name="SSN"
                  mask={SSN_MASK}
                  placeholder="SSN"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormDatePicker
                  placeholder="Date of Birth"
                  maxDate={new Date()}
                  control={control}
                  controlName="dateOfBirth"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3 ">
                <UseFormTextField
                  placeholder="Email"
                  control={control}
                  name="email"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3 ">
                <MyInputMask
                  control={control}
                  defaultValue={""}
                  name="mobilePhone"
                  mask={MOBILE_MASK}
                  placeholder="Mobile Phone"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormSelect
                  list={CITIZENSHIP}
                  control={control}
                  name="isUSCitizen"
                  placeholder="Are you a US citizen"
                />
              </Col>
              {isUSCitizen === "No" && (
                <Col xs={12} md={6} className="mt-3">
                  <UseFormSelect
                    list={CITIZENSHIP}
                    control={control}
                    name="xIsPermanentResident"
                    placeholder="Are you a Permanent Resident?"
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Current Address</h4>
              </Col>
              <Col xs={12}>
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="addressLine1"
                  onSelect={(address) => {
                    setValue("addressLine1", address.street);
                    setValue("city", address.city);
                    setValue("state", address.state);
                    setValue("postalCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="city"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="state"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="postalCode"
                />
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col xs={4} sm={4} lg={3}>
                <label className="form-check-label">
                  Time with Current Residence:
                </label>
              </Col>
              <Col xs={6} sm={4} lg={3}>
                <UseFormSelect
                  list={YEARS_ARRAY}
                  control={control}
                  name="yearsAtResidence"
                  placeholder="Years"
                  placeholderValue={""}
                />
              </Col>
              <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
                <UseFormSelect
                  list={MONTHS_ARRAY}
                  control={control}
                  name="monthsAtResidence"
                  placeholder="Months"
                  placeholderValue={""}
                />
              </Col>
            </Row>
            <Row>
              {typeof xInstalationAddressSame != "undefined" && (
                <Col xs={12} md={6} className="mt-3">
                  <UseFormCheck
                    control={control}
                    controlName="xInstalationAddressSame"
                    content="My Installation address is different than the current address."
                  />
                </Col>
              )}
              {xInstalationAddressSame && (
                <>
                  <Col xs={12} className="mt-3">
                    <h4 className="font-bold">Installation Address</h4>
                  </Col>
                  <Col xs={12}>
                    <GoogleAutoComplete
                      placeholder="Street"
                      control={control}
                      name="xInstallationAddressLine"
                      onSelect={(address) => {
                        setValue("xInstallationAddressLine", address.street);
                        setValue("xInstallationCity", address.city);
                        setValue("xInstallationState", address.state);
                        setValue("xInstallationZipCode", address.zipCode);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormTextField
                      placeholder="City"
                      control={control}
                      name="xInstallationCity"
                    />
                  </Col>
                  <Col xs={6} md={3} className="mt-3">
                    <UseFormTextField
                      placeholder="State"
                      control={control}
                      name="xInstallationState"
                    />
                  </Col>
                  <Col xs={6} md={3} className="mt-3">
                    <UseFormTextField
                      placeholder="Zip Code"
                      control={control}
                      name="xInstallationZipCode"
                    />
                  </Col>
                  <Col xs={12} className="mt-3">
                    <h4 className="font-bold">
                      Installation Address Residency
                    </h4>
                  </Col>
                  <Col xs={12}>
                    <UseFormSelect
                      list={INSTALLATION_RESIDENCY}
                      control={control}
                      name="installationResidency"
                      defaultValue={0}
                      placeholderValue={0}
                      placeholder="What is the residency type of the installation address?"
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              {typeof isMailingAdressSame != "undefined" && (
                <Col xs={12} className="mt-3">
                  <UseFormCheck
                    control={control}
                    controlName="isMailingAdressSame"
                    content="My Mailing address is different than my current address."
                  />
                </Col>
              )}
              {isMailingAdressSame && (
                <>
                  <Col xs={12} className="mt-3">
                    <h4 className="font-bold">Mailing Address</h4>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <GoogleAutoComplete
                      placeholder="Street"
                      control={control}
                      name="mailingAddressLine1"
                      onSelect={(address) => {
                        setValue("mailingAddressLine1", address.street);
                        setValue("mailingCity", address.city);
                        setValue("mailingState", address.state);
                        setValue("mailingZipCode", address.zipCode);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormTextField
                      placeholder="City"
                      control={control}
                      name="mailingCity"
                    />
                  </Col>
                  <Col xs={6} md={3} className="mt-3">
                    <UseFormTextField
                      placeholder="State"
                      control={control}
                      name="mailingState"
                    />
                  </Col>
                  <Col xs={6} md={3} className="mt-3">
                    <UseFormTextField
                      placeholder="Zip Code"
                      control={control}
                      name="mailingZipCode"
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Gross Annual Income</h4>
              </Col>
              <Col xs={12}>
                <UserFormCurrencyField
                  placeholder="Gross Annual Income"
                  control={control}
                  name="grossIncome"
                  type="number"
                />
              </Col>
              <Col xs={12} className="mt-3">
                <p className="subdetails">
                  Please enter annual income, which may include any income that
                  is verifiable and reported to the Government, such as
                  employment income, social security, pensions, etc. Do not
                  include household income from another borrower or
                  non-applicant.
                </p>
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col xs={12}>
                <h4 className="font-bold">
                  Employer Information (If Retired, type Retired)
                </h4>
              </Col>
              <Col xs={12} className="mt-3 mt-md-0">
                <UseFormTextField
                  placeholder="Employer Name"
                  control={control}
                  name="employerName"
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="mt-3">
                <div className="d-flex align-items-center flex-wrap">
                  <label className="form-check-label me-3">
                    Time with Current Employer / Length of Time Retired:
                  </label>
                  <div className="d-flex gap-3">
                    <div className="width-150px">
                      <UseFormSelect
                        list={YEARS_ARRAY}
                        control={control}
                        name="yearsEmployed"
                        placeholder="Years"
                      />
                    </div>
                    <div className="width-150px">
                      <UseFormSelect
                        list={MONTHS_ARRAY}
                        control={control}
                        name="monthsEmployed"
                        placeholder="Months"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <UserConsent
              applicant={ApplicantType.Borrower}
              type={ApplicationType.Full}
              authorization={
                <UseFormCheck
                  control={control}
                  controlName="authorization"
                  content="Yes, I have read and agree to the following credit authorization:"
                />
              }
              consent={
                <UseFormCheck
                  control={control}
                  controlName="consent"
                  content="Yes, I have read and agree to the following consents and disclosures:"
                />
              }
            />
            {typeof hasCoApplicant == "string" && (
              <Row className="py-4 mt-4 border-y g-0">
                <Col>
                  <div className="d-flex align-items-center">
                    <h4 className="font-bold me-4 mb-0">Add a Co-Borrower:</h4>
                    <div className="ml-3 d-flex">
                      <UseFormRadio
                        control={control}
                        elementName="co-borrower-group"
                        controlName="hasCoApplicant"
                        content="Yes"
                        checked={hasCoApplicant === "Yes" ? true : false}
                      />
                      <UseFormRadio
                        control={control}
                        elementName="co-borrower-group"
                        controlName="hasCoApplicant"
                        content="No"
                        checked={hasCoApplicant === "No" ? true : false}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {hasCoApplicant === "Yes" && (
              <>
                <Row className="mt-4">
                  <Col xs={12}>
                    <h4 className="font-bold">Co-Borrower Information</h4>
                  </Col>
                  <Col xs={12} md={6} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="First Name"
                      control={control}
                      name="coBorrowerFirstName"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="Last Name"
                      control={control}
                      name="coBorrowerLastName"
                    />
                  </Col>

                  <Col xs={12} md={6} className="mt-3">
                    <MyInputMask
                      control={control}
                      mask={SSN_MASK}
                      name="coBorrowerssn"
                      placeholder="SSN"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormDatePicker
                      placeholder="Date of Birth"
                      maxDate={new Date()}
                      control={control}
                      controlName="coBorrowerdob"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormTextField
                      placeholder="Email"
                      control={control}
                      name="coBorrowerEmail"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <MyInputMask
                      control={control}
                      defaultValue={""}
                      name="coBorrowerMobilePhone"
                      mask={MOBILE_MASK}
                      placeholder="Mobile Phone"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-3">
                    <UseFormSelect
                      list={CITIZENSHIP}
                      control={control}
                      name="coBorrowerusCitizen"
                      placeholder="Are you a US citizen"
                    />
                  </Col>
                  {coBorrowerusCitizen === "No" && (
                    <Col xs={12} md={6} className="mt-3">
                      <UseFormSelect
                        list={CITIZENSHIP}
                        control={control}
                        name="coBorrowerxIsPermanentResident"
                        placeholder="Are you a Permanent Resident?"
                      />
                    </Col>
                  )}
                </Row>
                {typeof xDiffAddressThanApplicant !== "undefined" && (
                  <Row>
                    <Col className="mt-4">
                      <h4 className="font-bold mr-4 mb-0">Co-Residency </h4>
                      <h4 className="fw-normal">
                        Does coborrower reside at the same residence as the
                        primary borrower?{" "}
                      </h4>
                      <div className="d-flex flex-column">
                        <UseFormRadio
                          control={control}
                          elementName="co-residency"
                          controlName="xDiffAddressThanApplicant"
                          content="Yes"
                          checked={
                            xDiffAddressThanApplicant === "Yes" ? true : false
                          }
                        />
                        <UseFormRadio
                          control={control}
                          elementName="co-residency"
                          controlName="xDiffAddressThanApplicant"
                          content="No"
                          checked={
                            xDiffAddressThanApplicant === "No" ? true : false
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {xDiffAddressThanApplicant === "No" && (
                  <>
                    <Row>
                      <Col xs={12} className="mt-3">
                        <h4 className="font-bold">Coborrower Address</h4>
                      </Col>
                      <Col xs={12}>
                        <GoogleAutoComplete
                          placeholder="Street"
                          control={control}
                          name="coBorrowerAddressLine1"
                          onSelect={(address) => {
                            setValue("coBorrowerAddressLine1", address.street);
                            setValue("coBorrowerCity", address.city);
                            setValue("coBorrowerState", address.state);
                            setValue("coBorrowerPostalCode", address.zipCode);
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} className="mt-3">
                        <UseFormTextField
                          placeholder="City"
                          control={control}
                          name="coBorrowerCity"
                        />
                      </Col>
                      <Col xs={6} md={3} className="mt-3">
                        <UseFormTextField
                          placeholder="State"
                          control={control}
                          name="coBorrowerState"
                        />
                      </Col>
                      <Col xs={6} md={3} className="mt-3">
                        <UseFormTextField
                          placeholder="Zip Code"
                          control={control}
                          name="coBorrowerPostalCode"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 d-flex align-items-center">
                      <Col xs={4} sm={4} lg={3}>
                        <label className="form-check-label">
                          Time with Current Residence:
                        </label>
                      </Col>
                      <Col xs={6} sm={4} lg={3}>
                        <UseFormSelect
                          list={YEARS_ARRAY}
                          control={control}
                          name="coBorrowerYearsAtResidence"
                          placeholder="Years"
                          placeholderValue={""}
                        />
                      </Col>
                      <Col xs={6} sm={4} lg={3} className="mt-0 mt-sm-0">
                        <UseFormSelect
                          list={MONTHS_ARRAY}
                          control={control}
                          name="coBorrowerMonthsAtResidence"
                          placeholder="Months"
                          placeholderValue={""}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="mt-4">
                  <Col xs={12} sm={6}>
                    <h4 className="font-bold">
                      Co-Borrower Gross Annual Income
                    </h4>
                    <UserFormCurrencyField
                      placeholder="Gross Annual Income"
                      control={control}
                      name="coBorrowerGrossIncome"
                    />
                  </Col>
                  <p className="mt-2">
                    Please enter annual income, which may include any income
                    that is verifiable and reported to the Government, such as
                    employment income, social security, pensions, etc.
                  </p>
                </Row>
                <Row>
                  <Col xs={12}>
                    <h4 className="font-bold">
                      Co-Borrower Employer Information (If Retired, type
                      Retired)
                    </h4>
                  </Col>
                  <Col xs={12}>
                    <UseFormTextField
                      placeholder="Employer Name"
                      control={control}
                      name="coBorrowerEmployerName"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mt-3">
                    <div className="d-flex align-items-center flex-wrap">
                      <label className="form-check-label me-3">
                        Time with Current Employer / Length of Time Retired:
                      </label>
                      <div className="d-flex gap-3">
                        <div className="width-150px">
                          <UseFormSelect
                            list={YEARS_ARRAY}
                            control={control}
                            name="coBorrowerYear"
                            placeholder="Years"
                          />
                        </div>
                        <div className="width-150px">
                          <UseFormSelect
                            list={MONTHS_ARRAY}
                            control={control}
                            name="coBorrowerMonths"
                            placeholder="Months"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {hasCoApplicant === "Yes" && (
              <UserConsent
                applicant={ApplicantType.CoBorrower}
                type={ApplicationType.Full}
                authorization={
                  <UseFormCheck
                    control={control}
                    controlName="coBorrowerAuthorization"
                    content="Yes, I have read and agree to the following credit authorization:"
                  />
                }
                consent={
                  <UseFormCheck
                    control={control}
                    controlName="coBorrowerConsent"
                    content="Yes, I have read and agree to the following consents and disclosures:"
                  />
                }
              />
            )}

            <div className="mt-5 d-flex justify-content-center mb-5">
              <Button variant="primary" type="submit">
                Submit Application
              </Button>
            </div>
          </>
        )}
      </form>
    </Col>
  );
};

export default Request;
