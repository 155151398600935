import { Row, Col, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  sendLinkFullAppSchema,
  sendLinkPreQualSchema,
} from "@shared/schema/request";
import { MOBILE_MASK } from "@shared/constants/app.constant";
import { emailLinkAction } from "@store/slices/loan.slice";
import { ApplicationType } from "@shared/enums/ApplicationStatus";
import {
  getInternalProductsList,
  maskMobile,
  onSubmitError,
  unmaskMobile,
  useQuery,
} from "@shared/helpers/global.helper";
import { useEffect } from "react";
import { loanService } from "@services/loan.service";
import { MyInputMask } from "@shared/components/MyInputMask";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import { appUserSelector } from "@store/slices/app.slice";

const SendLink = () => {
  let { type } = useParams<{ type: string }>();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector(appUserSelector);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(
      type === ApplicationType.Full
        ? sendLinkFullAppSchema
        : sendLinkPreQualSchema
    ),
  });

  const onSubmit = (event: any) => {
    const model = {
      ...(query.get("id") && { applicationId: query.get("id") }),
      firstName: event?.firstName,
      lastName: event?.lastName,
      email: event?.email,
      mobileNumber: unmaskMobile(event?.mobileNumber),
      loanProduct: event?.loanProduct,
      loanAmount: event?.loanAmount,
      projectID: event?.projectId,
      applicationType: type,
    };
    dispatch(emailLinkAction({ model, history, type }));
  };

  useEffect(() => {
    const id = query.get("id");
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      const primaryData = response?.data?.data?.primaryApplicant;
      const loanInformation = response?.data?.data?.loanInformation;

      reset({
        firstName: primaryData?.firstName,
        lastName: primaryData?.lastName,
        mobilePhone: maskMobile(primaryData?.mobilePhone || ""),
        email: primaryData?.email,
        loanAmount: loanInformation?.loanVersions[0]?.amountFinanced,
        loanProduct:
          response?.data?.data.loanInformation?.currentLoanVersion
            ?.commonLoanInfo?.xprogramtype,
      });
    };
    if (id) {
      getRequestData(id);
    }
  }, [query, reset]);

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto preQual-request">
      <div className="mt-3">
        <h3 className="h3 text-primary">Send {type} Application</h3>
        <hr className="text-primary height-2px" />
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <Row className="mt-4">
            <Col xs={12}>
              <h4 className="font-bold">Customer Contact</h4>
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UseFormTextField
                placeholder="First Name"
                control={control}
                name="firstName"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UseFormTextField
                placeholder="Last Name"
                control={control}
                name="lastName"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <UseFormTextField
                placeholder="Email"
                control={control}
                name="email"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <MyInputMask
                control={control}
                defaultValue={""}
                name="mobileNumber"
                mask={MOBILE_MASK}
                placeholder="Mobile Phone"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <h4 className="font-bold">Loan Product</h4>
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UseFormSelect
                list={getInternalProductsList(
                  user?.role,
                  user?.userDetails?.accessManagement
                )}
                control={control}
                name="loanProduct"
                placeholder="Loan Product"
              />
            </Col>
            {type === ApplicationType.Full && (
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <UserFormCurrencyField
                  placeholder="Loan Amount"
                  control={control}
                  name="loanAmount"
                />
              </Col>
            )}
          </Row>
          {type === ApplicationType.PreQual && (
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold mb-0">Loan Amount (Optional)</h4>
                <p className="sub-detail mb-0">
                  If loan amount is provided, customer will be able to convert
                  prequal application to a full application after pre-qual. If
                  omitted, a separate application will need to be sent to the
                  borrower to complete the full application.
                </p>
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UserFormCurrencyField
                  placeholder="Loan Amount"
                  control={control}
                  name="loanAmount"
                />
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col xs={12}>
              <h4 className="font-bold">Project ID Information (Optional)</h4>
            </Col>

            <Col xs={12} md={6}>
              <UseFormTextField
                placeholder="Installer Project Identifier"
                control={control}
                name="projectId"
              />
            </Col>
          </Row>

          <div className="mt-5 d-flex justify-content-center mb-5">
            <Button variant="primary" type="submit">
              Email {type} Application Link
            </Button>
          </div>
        </form>
      </div>
    </Col>
  );
};

export default SendLink;
