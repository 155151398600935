import { IApplication } from "@shared/interfaces/Application";
import { useState } from "react";
import { QcReviewTriggerName, loanService } from "@services/loan.service";
import { Button } from "react-bootstrap";
import {
  enableBookingConfirmation,
  enableQcPassFail,
} from "@shared/helpers/application.helper";
import ChoiceModal from "@shared/components/ChoiceModal";
import { toasterService } from "@services/toaster.service";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { useHistory } from "react-router-dom";

export const UpdateBookingConfirmation = ({
  data,
}: {
  data: IApplication | undefined;
}) => {
  const history = useHistory();
  const user = useAppSelector(appUserSelector);

  if (!enableBookingConfirmation(data, user)) {
    return null;
  }

  const handleRedirectCompleteFunding = () => {
    if (!data?.appNumber) {
      return;
    }
    history.push(`/application/${data?.appNumber}/update-booking`);
  };

  return (
    <div className="d-flex mt-4">
      <Button
        className=""
        variant="primary"
        type="button"
        onClick={handleRedirectCompleteFunding}
      >
        Update Booking Information
      </Button>
    </div>
  );
};
