import { getLoanDocuments } from "@shared/helpers/documents.helper";
import { IApplication } from "@shared/interfaces/Application";
import { useAppSelector } from "@store/hooks";
import {
  DownloadDocumentPayload,
  documentsMetadataSelector,
} from "@store/slices/loan.slice";
import { DocumentCard } from "./DocumentCard";

export const LoanDocPackage = ({
  requestDetail,
  downloadDocument,
}: {
  requestDetail: IApplication | undefined;
  downloadDocument: (value: DownloadDocumentPayload) => void;
}) => {
  const documentMetadata = useAppSelector(documentsMetadataSelector);
  const loanDocuments = documentMetadata
    ? getLoanDocuments(documentMetadata)
    : [];

  return (
    <div>
      <h4 className="font-bold-800">Doc Package</h4>
      <div>
        {loanDocuments.map((item, i) => (
          <DocumentCard
            documentMetadata={item}
            key={item.documentId}
            requestDetail={requestDetail}
            downloadDocument={downloadDocument}
          />
        ))}
      </div>
    </div>
  );
};
