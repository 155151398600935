import { LOAN_PRODUCTS_CLASSIFICATION } from "@shared/constants/app.constant";
import {
  DealerIdTypes,
  IApplication,
  ICustomNotes,
  IModifyLoanPayload,
  IPrequalifiedOffer,
  IXPrequalProductsTypeValues,
  Nillable,
} from "@shared/interfaces/Application";
import { UserRole } from "@shared/interfaces/User";
import { IAuthData } from "@store/slices/app.slice";
import { getInternalProductsList, getProgramType } from "./global.helper";

export const generateModifyLoanPayload = (
  application: IApplication
): IModifyLoanPayload => {
  const commonLoanInfo =
    application?.loanInformation?.currentLoanVersion?.commonLoanInfo;
  const payload: IModifyLoanPayload = {
    loanInformation: {
      loanVersions: [
        {
          prequalStage: isPrequalApplication(application) ? 1 : 0,
          totalCashPrice:
            application?.loanInformation?.currentLoanVersion?.amountFinanced ||
            0,
          commonLoanInfo: {
            xprogramtype: commonLoanInfo?.xprogramtype || 0,
            ...(!isPrequalApplication(application) && {
              xInstallationAddressLine:
                commonLoanInfo?.xInstallationAddressLine,
              xInstallationCity: commonLoanInfo?.xInstallationCity,
              xInstallationState: commonLoanInfo?.xInstallationState,
              xInstallationZipCode: commonLoanInfo?.xInstallationZipCode,
              xInstalationAddressSame: commonLoanInfo?.xInstalationAddressSame
                ? true
                : false,
              xStatedInstallationHomeOwner:
                commonLoanInfo?.xStatedInstallationHomeOwner ||
                commonLoanInfo?.xInstalaltionHomeOwner,
              installationResidency: commonLoanInfo?.installationResidency,
            }),
          },
        },
      ],
    },
    ...(!isPrequalApplication(application) && {
      xInstallationAddressLine: commonLoanInfo?.xInstallationAddressLine,
      xInstallationCity: commonLoanInfo?.xInstallationCity,
      xInstallationState: commonLoanInfo?.xInstallationState,
      xInstallationZipCode: commonLoanInfo?.xInstallationZipCode,
      xInstalationAddressSame: commonLoanInfo?.xInstalationAddressSame
        ? true
        : false,
      xStatedInstallationHomeOwner:
        commonLoanInfo?.xStatedInstallationHomeOwner ||
        commonLoanInfo?.xInstalaltionHomeOwner ||
        0,
      installationResidency: commonLoanInfo?.installationResidency || 0,
    }),
  };

  return payload;
};

export const enableQcPassFail = (
  requestDetail: IApplication | undefined,
  user: IAuthData | null
): boolean => {
  return (
    !!user?.role &&
    [UserRole.providerAdmin, UserRole.financialInstitution].includes(
      user?.role
    ) &&
    !!requestDetail &&
    (requestDetail?.applicationStatus?.value === 18 ||
      requestDetail?.applicationStatus?.value === 25)
  ); // QC Review and QC Re-review
};

export const enableBookingConfirmation = (
  requestDetail: IApplication | undefined,
  user: IAuthData | null
): boolean => {
  return (
    !!user?.role &&
    [UserRole.providerAdmin, UserRole.financialInstitution].includes(
      user?.role
    ) &&
    !!requestDetail &&
    requestDetail?.applicationStatus?.value === 16
  ); // RFF Issued
};

export const isPrequalApplication = (
  data: IApplication | undefined
): boolean => {
  return data?.Prequal === true || data?.prequalStage?.value === 1;
};

export const generateXPrequalProducts = (
  programTypes: number[],
  dealer: Nillable<DealerIdTypes>
): IXPrequalProductsTypeValues[] => {
  const payload: IXPrequalProductsTypeValues[] = programTypes.map((item) => ({
    ...LOAN_PRODUCTS_CLASSIFICATION[item],
    xFlicPlatformFeePrecent:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xFlicPlatformFeePrecent?.(dealer),
    xSellerPointsPercent:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xSellerPointsPercent?.(dealer),
    userRate: LOAN_PRODUCTS_CLASSIFICATION[item]?.userRate?.(dealer),
    xInitialRate: LOAN_PRODUCTS_CLASSIFICATION[item]?.xInitialRate?.(dealer),
    xFinalRate: LOAN_PRODUCTS_CLASSIFICATION[item]?.xFinalRate?.(dealer),
    xRiskBasedRateTable:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xRiskBasedRateTable?.(dealer),
    xRisckBasedIntialRateTable:
      LOAN_PRODUCTS_CLASSIFICATION[item]?.xRisckBasedIntialRateTable?.(dealer),
  }));
  return payload;
};

export const getXPrequalProducts = (
  user: IAuthData | null,
  xprogramtype: string
): {
  xPrequalProductsSelected: IXPrequalProductsTypeValues[];
  xPrequalProductsAll: IXPrequalProductsTypeValues[];
} => {
  const xPrequalProductsProgramType: number[] = getInternalProductsList(
    user?.role,
    user?.userDetails?.accessManagement
  )?.map((item) => item.value as number);

  // if user doesn't want to apply to prequalify for the other products
  const xPrequalProductsSelected = generateXPrequalProducts(
    [Number(xprogramtype)],
    user?.userDetails?.dealer as Nillable<DealerIdTypes>
  );
  // xPrequalProductsAll will be sent when the system applies for the other prequal loans when the current loan is declined
  const xPrequalProductsAll = generateXPrequalProducts(
    xPrequalProductsProgramType,
    user?.userDetails?.dealer as Nillable<DealerIdTypes>
  );

  return {
    xPrequalProductsSelected,
    xPrequalProductsAll,
  };
};

export const generatePrimaryApplicantPayload = (application: IApplication) => {
  const primaryApplicant = application.primaryApplicant;
  const primaryAddress = primaryApplicant?.addresses[0];

  const applicantAddresses = [];

  const currentAddress = {
    ownershipType: {
      name: "Other",
      label: "Other",
      value: 4,
    },
    addressType: {
      name: "Primary",
      label: "Primary",
      value: 1,
    },
    addressFormat: {
      name: "Postal Standard",
      label: "Postal Standard",
      value: 5,
    },
    postalCode: primaryAddress?.postalCode,
    addressLine1: primaryAddress?.addressLine1,
    city: primaryAddress?.city,
    state: primaryAddress?.state,
    yearsAtResidence: primaryAddress?.yearsAtResidence,
    monthsAtResidence: primaryAddress?.monthsAtResidence,
  };
  applicantAddresses.push(currentAddress);

  const employment = {
    employmentType: {
      name: "Primary",
      label: "Primary",
      value: 1,
    },
    grossIncome: primaryApplicant?.currentEmployments[0]?.grossIncome,
    payPeriod: 5,
    employmentStatus: {
      name: "Undefined",
      label: "Undefined",
      value: 0,
    },
  };

  const primaryApplicantPayload = {
    jsonType: "Applicant",
    applicantType: {
      name: "primary",
      label: "primary",
      value: 1,
    },
    lastName: primaryApplicant?.lastName,
    firstName: primaryApplicant?.firstName,
    addresses: applicantAddresses,
    employments: [employment],
    xInstallationHomeOwner: 1,

    internalClientId: application?.internalClientId,
    applicationId: primaryApplicant?.applicationId,
    applicantId: primaryApplicant?.applicantId,
  };
  return primaryApplicantPayload;
};

export const generateCoBorrowerApplicantPayload = (
  application: IApplication
) => {
  const coApplicant = application.coapplicant;
  if (!coApplicant) {
    return;
  }
  const coBorrowerPrimaryAddress = coApplicant?.addresses?.[0];

  const employment = {
    employmentType: {
      name: "Primary",
      label: "Primary",
      value: 1,
    },
    grossIncome: coApplicant?.currentEmployments[0]?.grossIncome,
    payPeriod: 5,
    employmentStatus: {
      name: "Undefined",
      label: "Undefined",
      value: 0,
    },
  };
  let coBorrowerAddress;
  if (coApplicant.xDiffAddressThanApplicant) {
    coBorrowerAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: coBorrowerPrimaryAddress.postalCode,
      addressLine1: coBorrowerPrimaryAddress.addressLine1,
      city: coBorrowerPrimaryAddress.city,
      state: coBorrowerPrimaryAddress.state,
      yearsAtResidence: coBorrowerPrimaryAddress.yearsAtResidence,
      monthsAtResidence: coBorrowerPrimaryAddress.monthsAtResidence,
    };
  }
  const address = coApplicant?.addresses.find(
    (x) => x.addressType.label === "Primary"
  );
  let primaryAddress = [];
  if (address) {
    primaryAddress.push({
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: address.postalCode,
      addressLine1: address.addressLine1,
      city: address.city,
      state: address.state,
      yearsAtResidence: Number(address.yearsAtResidence),
      monthsAtResidence: Number(address.monthsAtResidence),
    });
  }
  const model = {
    jsonType: "Applicant",
    applicantType: {
      name: "coBorrower",
      label: "Co-Borrower",
      value: 2,
    },
    lastName: coApplicant?.lastName,
    firstName: coApplicant?.firstName,
    addresses: coApplicant.xDiffAddressThanApplicant
      ? [coBorrowerAddress]
      : primaryAddress,
    employments: [employment],
    xInstallationHomeOwner: 1,
    xDiffAddressThanApplicant: coApplicant.xDiffAddressThanApplicant,
    internalClientId: application?.internalClientId,
    applicationId: coApplicant?.applicationId,
    applicantId: coApplicant?.applicantId,
  };
  return model;
};

export const generateLoanObjForMultiPrequal = (
  application: IApplication,
  dataToUpdate?: {
    xprogramtype?: number;
    primaryApplicantModel?: any;
    coBorrowerModel?: any;
    xPrequalProducts?: IXPrequalProductsTypeValues[];
  }
) => {
  const applicants = [];

  const {
    xprogramtype,
    primaryApplicantModel,
    coBorrowerModel,
    xPrequalProducts,
  } = dataToUpdate || {};
  applicants.push(
    primaryApplicantModel || generatePrimaryApplicantPayload(application)
  );

  if (application?.coapplicant || coBorrowerModel) {
    applicants.push(
      coBorrowerModel || generateCoBorrowerApplicantPayload(application)
    );
  }

  const loanObjPayload = {
    applicants,
    loanInformation: {
      loanVersions: [
        {
          commonLoanInfo: {
            xprogramtype:
              xprogramtype ||
              application?.loanInformation?.currentLoanVersion?.commonLoanInfo
                ?.xprogramtype,
          },
        },
      ],
    },
    xprogramtype:
      xprogramtype ||
      application?.loanInformation?.currentLoanVersion?.commonLoanInfo
        ?.xprogramtype,
    xPrequalProducts: xPrequalProducts,
  };

  return loanObjPayload;
};

export const isMultiPrequalApplication = (
  application: IApplication
): boolean => {
  return (
    !!application?.xPrequalProducts && application?.xPrequalProducts.length > 0
  );
};

export const getPrequalifiedOfferFromAppData = (
  application: IApplication,
  prequalifiedOfferId: number
): IPrequalifiedOffer | undefined => {
  return application?.prequalifiedOffers?.find(
    (item) => item.prequalifiedOfferId === prequalifiedOfferId && item.active
  );
};

export const getProductNameForOfferCard = (
  xprogramtype: string
): {
  productName: string;
  productNameInBrackets: string;
} => {
  const originalName = getProgramType(xprogramtype) || "";
  const productName = originalName.split("(", 1)[0];

  const productNameInBrackets =
    originalName.includes("(") && originalName.includes(")")
      ? originalName.substring(
          originalName.indexOf("(") + 1,
          originalName.lastIndexOf(")")
        )
      : "";
  return {
    productName,
    productNameInBrackets,
  };
};

const NOTES_CLIENT_TYPE = "Dealer";

export const getLenderNotes = (
  data: IApplication | undefined
): ICustomNotes[] => {
  return data?.customNotes
    ? data?.customNotes?.filter((i) => i.toClientTypeStr === NOTES_CLIENT_TYPE)
    : [];
};
