import { environment } from "@env/environment";
import { MESSAGES } from "@shared/constants/app.constant";
import { RequestStatus } from "@shared/enums/RequestType";
import {
  isApproved,
  isDeclined,
  isPending,
  isPrequalApproved,
  isPrequalDeclined,
} from "@shared/helpers/global.helper";
import { networkService } from "./network-service";
import {
  ApplicationResults,
  DocumentMetadata,
  IApplication,
} from "@shared/interfaces/Application";
import { ApplicationErrorType, IIssueDocType } from "@store/slices/loan.slice";
import { AxiosResponse } from "axios";
import { toasterService } from "./toaster.service";
import { IIssueDocWithSizeType } from "@store/slices/commonData.slice";

export type ValidateRequestStatusReturnType = {
  data: ApplicationErrorType | null;
  decision: "approved" | "declined" | "pending" | "failed";
};

interface AddLenderNote {
  subject: string;
  noteContent: string;
  noteRecipient: string;
  noteType: string;
}

export enum QcReviewTriggerName {
  Pass_QC_Review = "Pass_QC_Review",
  Fail_QC_Review = "Fail_QC_Review",
}

interface AddQcReview {
  review: QcReviewTriggerName;
}

interface ICompleteFunding {
  xMemberAccountNumber: string;
  xNewLoanNumber: string;
}

class LoanService {
  count: number = 0;

  resetCount() {
    this.count = 0;
  }
  async getRequestById(id: string) {
    return await networkService.get<{
      data: IApplication;
      errors: any;
      message: string;
    }>(`/application/${id}`);
  }
  async getRequestStausById(id: string) {
    return await networkService.get(`/application/${id}/request-status`);
  }

  async uploadDocuments(id: string, model: any) {
    return await networkService.post(`/application/upload/${id}`, model);
  }

  async uoloadDocumentProcess(id: string, model: any) {
    const response = await this.uploadDocuments(id, model);
    // need to handle the reponse if it is already success and uploaded to DL4
    if (response?.data.data.success === true) {
      return true;
    }
    let isSuccess = false;

    if (response.data?.data && response.data?.data?.status !== "failed") {
      this.resetCount();
      const statusResponse: any =
        await this.getUploadDocRequestStatusUntillSuccess(
          response?.data?.data?.id
        );
      if (statusResponse?.data?.data?.status === "success") {
        isSuccess = true;
      }
    }
    return isSuccess;
  }

  async getUploadDocRequestStatusUntillSuccess(id: string) {
    this.count = this.count + 1;
    const response = await networkService.get(
      `/application/upload/${id}/status`
    );
    if (
      (!response.data?.data || response.data?.data?.status !== "success") &&
      this.count < Number(environment?.REACT_APP_STATUS_CHECK_ATTEMPTS)
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for a moment
      console.info(
        `Request inprogress - Checking upload doc status attemp ${this.count}`
      );
      const res: any = await this.getUploadDocRequestStatusUntillSuccess(id);
      return res;
    } else {
      return response;
    }
  }
  async updateProjectId(id: string, model: any) {
    return await networkService.post(`/application/${id}/project`, model);
  }
  async getRequestStatusUntillSuccess(id: string) {
    this.count = this.count + 1;
    const response = await networkService.get(
      `/application/${id}/request-status`
    );

    if (
      response.data?.data?.response &&
      response.data?.data?.response?.taskStatusLabel === "Failed"
    ) {
      return response;
    }

    if (
      (!response.data?.data?.response ||
        response.data?.data?.response?.taskStatusLabel !== "Completed") &&
      this.count < Number(environment?.REACT_APP_STATUS_CHECK_ATTEMPTS)
    ) {
      console.log(
        `Request inprogress - Checking status update attemp ${this.count}`
      );
      const res: any = await this.getRequestStatusUntillSuccess(id);
      return res;
    } else {
      return response;
    }
  }
  async validateRequestStatus(
    id: string
  ): Promise<ValidateRequestStatusReturnType | void> {
    const applicationDetail = await this.getRequestById(id);
    if (!applicationDetail?.data?.data?.decisionStatus?.label) {
      return;
    }

    if (
      isPrequalApproved(applicationDetail?.data?.data?.decisionStatus?.label)
    ) {
      const offerData = applicationDetail?.data?.data?.prequalifiedOffers || [];
      return {
        data: {
          offerData,
          requestDetail: applicationDetail.data.data,
        },
        decision: "approved",
      };
    } else if (
      isPrequalDeclined(applicationDetail?.data?.data?.decisionStatus?.label)
    ) {
      return {
        data: {
          type: RequestStatus.Declined,
          message: MESSAGES.REQUEST_FAILED,
          requestDetail: applicationDetail.data.data,
        },
        decision: "declined",
      };
    } else if (
      isApproved(applicationDetail?.data?.data?.decisionStatus?.label)
    ) {
      // For Full application, the system fetches the offers from the DL4 /offers API
      const offerResponse: any = await networkService.get(
        `/application/${id}/offers`
      );
      const offerData = offerResponse?.data?.data || [];

      return {
        data: {
          offerData,
          requestDetail: applicationDetail.data.data,
        },
        decision: "approved",
      };
    } else if (
      isDeclined(applicationDetail?.data?.data?.decisionStatus?.label)
    ) {
      return {
        data: {
          type: RequestStatus.Declined,
          message: MESSAGES.DECLINED,
          requestDetail: applicationDetail.data.data,
        },
        decision: "declined",
      };
    } else if (
      isPending(applicationDetail?.data?.data?.decisionStatus?.label)
    ) {
      return {
        data: {
          type: RequestStatus.Declined,
          message: MESSAGES.DECLINED,
          requestDetail: applicationDetail.data.data,
        },
        decision: "pending",
      };
    } else {
      return {
        data: {
          type: RequestStatus.Failed,
          message: MESSAGES.REQUEST_FAILED,
          requestDetail: applicationDetail.data.data,
        },
        decision: "failed",
      };
    }
  }

  async uploadContract(id: string, model: any) {
    this.resetCount();
    const response: any = await networkService.post(
      `/application/${id}/contractprep`,
      model
    );

    if (response.data?.data?.response?.taskStatusLabel === "Completed") {
      const esignResponse: any = await networkService.get(
        `/application/${id}/document/esign`,
        {
          params: {
            version: response?.data?.data?.response?.version,
          },
        }
      );
      if (esignResponse.data?.data?.id) {
        const statusResponse: any = await this.getRequestStatusUntillSuccess(
          esignResponse.data?.data?.id
        );
        if (
          statusResponse.data?.data?.response?.taskStatusLabel ===
            "Completed" &&
          statusResponse.data?.data?.response?.taskStatus === 30
        ) {
          return true;
        }
      }
    }
    // TODO: Error handling and displaying error messages can be improved here. Currently, it doesn't display a specific error if contractprep API is failed
    return false;
  }
  async processRequest(response: any) {
    let flag = true;
    if (!response.data?.data?.response) {
      const requestStatus: any = await this.getRequestStatusUntillSuccess(
        response?.data?.data?.id
      );
      if (
        requestStatus.data?.data.status &&
        requestStatus.data?.data?.response
      ) {
        flag = false;
      }
    } else {
      if (
        response.data?.data?.response &&
        response.data?.data?.response?.taskStatusLabel !== "Failed"
      ) {
        //if has response and status is completed
        if (response.data?.data?.response?.taskStatusLabel === "Completed") {
          flag = false;
        } else {
          //no response, request in progress, keep checking untill get success && for certain attempts
          this.resetCount();
          const statusResponse: any = await this.getRequestStatusUntillSuccess(
            response?.data?.data?.id
          );
          if (
            statusResponse?.data?.data?.response?.taskStatusLabel !==
            "Completed"
          ) {
            flag = false;
          }
        }
      }
    }
    return flag;
  }

  async createTempApplication(
    internalAppId: string,
    requestDetail: IApplication
  ) {
    try {
      const model: ApplicationResults = {
        name: [requestDetail?.primaryApplicant?.fullName || ""],
        applicationNumber: requestDetail?.appNumber || "",
        applicationId: requestDetail?.applicationId || 0,
        applicationStatus: requestDetail?.applicationStatus?.label || "",
        createdOn:
          requestDetail?.loanInformation?.currentLoanVersion?.createdOn || 0,
        decisionStatusString: requestDetail?.decisionStatus?.label || "",
        loanTypeName: "Solar Loan",
        dealerName: "test",
        requestedAmount:
          requestDetail?.loanInformation?.currentLoanVersion?.amountFinanced ||
          0,
        stipulations: [],
      };
      await networkService.post(`/application/${internalAppId}/new`, model);
      return true;
    } catch (error) {
      return false;
    }
  }
  async updateInstallerRep(id: string, userId: string) {
    return await networkService.put(`/application/${id}/installer-rep`, {
      userId,
    });
  }

  async downloadDocument(id: string, label: string) {
    return networkService.get<Blob>(`/application/download/${id}`, {
      params: {
        label,
      },
      responseType: "blob",
    });
  }

  async getIssueDocsType() {
    return networkService.get<{
      data: {
        batteryType: IIssueDocWithSizeType[];
        inverterType: IIssueDocType[];
        moduleType: IIssueDocWithSizeType[];
        batteryExpansionType: IIssueDocWithSizeType[];
      };
    }>(`application/loan/type`);
  }

  async getDocumentsMetadata(id: string) {
    return networkService.get<{
      data: DocumentMetadata[];
    }>(`/application/documents/${id}`);
  }

  async addApplicant(appNumber: string, applicant: any, isEdit?: string) {
    return networkService.put(
      `/application/${appNumber}/applicants`,
      applicant,
      {
        params: {
          isEdit,
        },
      }
    );
  }

  async updatePrequalForMultiPrequal(appNumber: string, body: any) {
    this.resetCount();
    const response = await networkService.post(
      `/application/${appNumber}/prequalUpdate`,
      body
    );

    if (response.data?.data?.id) {
      const statusResponse: any = await this.getRequestStatusUntillSuccess(
        response.data?.data?.id
      );
      if (
        statusResponse.data?.data?.response?.taskStatusLabel === "Completed" &&
        statusResponse.data?.data?.response?.taskStatus === 30
      ) {
        return true;
      }
    }
    return false;
  }

  async switchPrequalToFullApp(
    appNumber: string,
    prequalifiedOfferId: number,
    body: any
  ) {
    this.resetCount();
    const response = await networkService.post(
      `/application/${appNumber}/switchPrequalToFullApp`,
      body,
      {
        params: {
          prequalifiedOfferId,
        },
      }
    );

    if (response.data?.data?.id) {
      const statusResponse: any = await this.getRequestStatusUntillSuccess(
        response.data?.data?.id
      );
      if (
        statusResponse.data?.data?.response?.taskStatusLabel === "Completed" &&
        statusResponse.data?.data?.response?.taskStatus === 30
      ) {
        return true;
      }
    }
    return false;
  }

  async addLenderNote(payload: AddLenderNote, applicationId: string) {
    this.resetCount();

    const response = await networkService.post<AddLenderNote, any>(
      `application/${applicationId}/addnote`,
      payload
    );

    if (response.data?.data?.id) {
      const statusResponse: any = await this.getRequestStatusUntillSuccess(
        response.data?.data?.id
      );
      if (
        statusResponse.data?.data?.response?.taskStatusLabel === "Completed" &&
        statusResponse.data?.data?.response?.taskStatus === 30
      ) {
        // Refresh notes api
        return this.refreshLenderNotes(applicationId);
      }
    }
    return false;
  }

  async addQcReview(payload: AddQcReview, applicationId: string) {
    this.resetCount();
    const response = await networkService.post<AddQcReview, AxiosResponse<any>>(
      `application/${applicationId}/qc-review`,
      payload
    );

    if (response.data?.data?.id) {
      const statusResponse: any = await this.getRequestStatusUntillSuccess(
        response.data?.data?.id
      );
      if (
        statusResponse.data?.data?.response?.taskStatusLabel === "Completed" &&
        statusResponse.data?.data?.response?.taskStatus === 30
      ) {
        return true;
      }
    }
    return false;
  }

  async refreshLenderNotes(applicationId: string) {
    this.resetCount();
    const response = await networkService.post<AddQcReview, AxiosResponse<any>>(
      `application/${applicationId}/refresh-notes`
    );

    if (response.data?.data?.id) {
      const statusResponse: any = await this.getRequestStatusUntillSuccess(
        response.data?.data?.id
      );
      if (
        statusResponse.data?.data?.response?.taskStatusLabel === "Completed" &&
        statusResponse.data?.data?.response?.taskStatus === 30
      ) {
        return true;
      }
    }
    return false;
  }

  async completeFundinng(payload: ICompleteFunding, applicationId: string) {
    this.resetCount();
    const response = await networkService.post<
      ICompleteFunding,
      AxiosResponse<any>
    >(`application/${applicationId}/complete-funding`, payload);

    if (response.data?.data?.id) {
      const statusResponse: any = await this.getRequestStatusUntillSuccess(
        response.data?.data?.id
      );
      if (
        statusResponse.data?.data?.response?.taskStatusLabel === "Completed" &&
        statusResponse.data?.data?.response?.taskStatus === 30
      ) {
        return true;
      }

      if (statusResponse.data?.data?.response?.taskStatusLabel === "Failed") {
        toasterService.error(
          statusResponse.data?.data?.response?.msg || MESSAGES.DEFAULT_ERROR
        );
      }
    }
    return false;
  }
}

export const loanService = new LoanService();
