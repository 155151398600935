import { Redirect } from "react-router-dom";
import { IApplication } from "@shared/interfaces/Application";
import { isNonModifiable } from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";

/**
 * This component redirects the user to the Pipelines page
 * if an Application is restricted from being modified
 */
const ApplicationGuard = ({
  application,
}: {
  application?: IApplication | null | any;
}) => {
  const user = useAppSelector(appUserSelector);
  if (
    !!application &&
    isNonModifiable(application as IApplication, user?.role)
  ) {
    return <Redirect to="/pipelines" />;
  }
  return <></>;
};

export default ApplicationGuard;
