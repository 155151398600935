import { environment } from "@env/environment";
import { valueBetween, valueExists } from "./global.helper";
import {
  DocumentMetadata,
  IApplication,
  IStipulation,
} from "@shared/interfaces/Application";
import { LoanDocsStatus } from "@shared/enums/LoanDocsStatus";
import { toasterService } from "@services/toaster.service";
import {
  DownloadDocumentPayload,
  IIssueDocType,
} from "@store/slices/loan.slice";
import { AxiosRequestConfig } from "axios";

const STIPULATION_ATTACHMENT = "STIPULATION_ATTACHMENT";

/**
 * Check whether a Stipulation must be excluded
 */
export const isStipulationExcluded = (stipulation: IStipulation): boolean => {
  // Previously: stipulation.isRulesStip && stipulation.stipulationType !== 4
  return (
    stipulation.attachmentRequired &&
    stipulation.stipulationType !== 3 &&
    stipulation.stipulationType !== 0
  );
};

// created reusable methods
export const isStipulation = (appStipulations: any) => {
  return appStipulations?.some((x: any) =>
    isStipulationExcluded(x as IStipulation)
  );
};
export const isIdentificationRefId = (data: IStipulation) => {
  const stipsEnv = environment.REACT_APP_IDENTIFICATION_REF_ID;
  if (!stipsEnv) return;
  return findStip(stipsEnv, data.stipReferenceId);
};
export const isNoDecisionRefId = (data: any) => {
  const stipsEnv = environment.REACT_APP_NO_DECISON_REF_ID;
  if (!stipsEnv) return;
  return findStip(stipsEnv, data.stipReferenceId);
};

export const isCreditFreezeRefId = (data: any) => {
  const stipsEnv = environment.REACT_APP_RESUBMIT_ON_NO_DECISION_REF_ID;
  if (!stipsEnv) return;
  return findStip(stipsEnv, data.stipReferenceId);
};

export const isSolarContractRefId = (data: any) => {
  const stipsEnv = environment.REACT_APP_SOLAR_CONTRACT_REF_ID;
  if (!stipsEnv) return;
  return findStip(stipsEnv, data.stipReferenceId);
};
export const isInstallationPhotosRefId = (data: any) => {
  const stipsEnv = environment.REACT_APP_INSTALLATION_PHOTOS_REF_ID;
  if (!stipsEnv) return;
  return findStip(stipsEnv, data.stipReferenceId);
};

export const isPtoDocumentRefId = (data: any) => {
  const stipsEnv = environment.REACT_APP_PTO_DOC_REF_ID;
  if (!stipsEnv) return;
  return findStip(stipsEnv, data.stipReferenceId);
};

export const isIssueLoanDocsRefId = (data: any) => {
  const stipsEnv = environment.REACT_APP_ISSUE_DOCS_REF_ID;
  if (!stipsEnv) return;
  return findStip(stipsEnv, data.stipReferenceId);
};

export const findStip = (stipEnv: string, referenceId: number) => {
  const array = stipEnv.split(",");
  return array.find((x: string) => Number(x) === referenceId);
};

export const isStatusUploaded = (data: any) => {
  return data.status === 2 || data.status === 3 || data.status === 4;
};

/**
 * To display the solar stipulations and applicants id stipulations
 * Currently, this function check is removed from getIdentificationStip and getSolarContractStip functions
 * @param data Application response
 * @returns boolean
 */
const matchDocumentStatuses = (data: any) => {
  return (
    valueBetween(data?.applicationStatus.value, 1, 14) &&
    valueExists(data?.decisionStatus.value, [100, 105, 110])
  );
};

export const getCreditFreezStips = (data: IApplication) => {
  return data?.appStipulations?.filter((x) => isCreditFreezeRefId(x)) || [];
};

// methods to check if documents are required
export const getIdentificationStip = (data: IApplication) => {
  return data?.appStipulations?.filter((x) => isIdentificationRefId(x)) || [];
};
export const getSolarContractStip = (data: IApplication) => {
  return data?.appStipulations?.find((x) => isSolarContractRefId(x));
};

export const getInstallationPhotosStip = (data: IApplication) => {
  return data?.appStipulations?.find((x) => isInstallationPhotosRefId(x));
};

export const getPtoDocumentStip = (data: IApplication) => {
  return data?.appStipulations?.find((x) => isPtoDocumentRefId(x));
};

// methods to check if documents are uploaded
export const isStipulationUploaded = (
  appStipulations: any,
  isFullApplication: boolean
) => {
  const stipulations: any[] = appStipulations?.filter((x: any) =>
    isStipulationExcluded(x as IStipulation)
  );
  const allUploaded =
    isFullApplication && stipulations?.every((x: any) => isStatusUploaded(x));
  return allUploaded;
};
export const isIdentificationUploaded = (
  appStipulations: any,
  isFullApplication: boolean
) => {
  const documents: any[] = appStipulations?.filter((x: any) =>
    isIdentificationRefId(x)
  );
  const allUploaded =
    isFullApplication && documents?.every((x: any) => isStatusUploaded(x));
  return allUploaded;
};

export const isSolarContractUploaded = (appStipulations: any) => {
  return appStipulations?.some(
    (x: any) => isStatusUploaded(x) && isSolarContractRefId(x)
  );
};

export const isInstallationPhotosUploaded = (appStipulations: any) => {
  return appStipulations?.some(
    (x: any) => isStatusUploaded(x) && isInstallationPhotosRefId(x)
  );
};

export const isPtoDocUploaded = (appStipulations: any) => {
  return appStipulations?.some(
    (x: any) => isStatusUploaded(x) && isPtoDocumentRefId(x)
  );
};

export const isIssueDocsUploaded = (appStipulations: any) => {
  return appStipulations?.some(
    (x: any) => isStatusUploaded(x) && isIssueLoanDocsRefId(x)
  );
};

export const getLoanDocStatusColor = (requestDetail: IApplication) => {
  switch (
    requestDetail?.loanInformation?.currentLoanVersion?.commonLoanInfo
      ?.xApiEsignStatus
  ) {
    case LoanDocsStatus.Created:
      return "text-primary";
    case LoanDocsStatus.NotGenerated:
      return "text-warning";
    case LoanDocsStatus.Completed:
      return "text-success";
    case LoanDocsStatus.SentToBorrower:
      return "text-info";
    case LoanDocsStatus.Error:
      return "text-danger";
    default:
      return "text-danger";
  }
};

export const isDocumentUploaded = (requestDetail: IApplication, id: string) => {
  return requestDetail?.appStipulations?.find(
    (x: any) => x.stipName === id && isStatusUploaded(x)
  );
};

export const getRequiredStipulations = (data: Array<IStipulation>) => {
  return data.filter((x) => isStipulationExcluded(x));
};
export const getType = (stipulationType: number) => {
  if (stipulationType === 1 || stipulationType === 0) return "Applicant";
  if (stipulationType === 2) return "CoApplicant";
  return null;
};

export const isStipulationFailed = (data: IStipulation) => {
  return data.status === 5;
};

export const isDocumentFailed = (stipulation: IStipulation) => {
  return stipulation?.status === 5;
};

export const getLabelIfDocIsDownloadable = (
  stipulationItem: IStipulation,
  documentsMetadata: DocumentMetadata[] | undefined
): string[] | undefined => {
  if (
    !documentsMetadata ||
    documentsMetadata.length === 0 ||
    !stipulationItem?.stipulationAttachments.length
  ) {
    return undefined;
  }
  const documentIds = stipulationItem.stipulationAttachments.map(
    (item) => item.documentId
  );

  return documentsMetadata
    .filter((item) => documentIds.includes(item.documentId))
    .map((item) => item.label);
};

export const isAdditionalDocumentation = (data: DocumentMetadata) => {
  return (
    data.category !== STIPULATION_ATTACHMENT && data.eSignIntegrationId === null
  );
};

export const getAdditionalDocumentation = (data: Array<DocumentMetadata>) => {
  return data.filter((x) => isAdditionalDocumentation(x));
};

export const isLoanDocument = (data: DocumentMetadata) => {
  return !!data.eSignIntegrationId;
};

export const getLoanDocuments = (data: Array<DocumentMetadata>) => {
  return data.filter((x) => isLoanDocument(x));
};

export const getIssueDocTypeLabel = (
  data: number | undefined,
  moduleTypes: IIssueDocType[] | undefined
): string => {
  return moduleTypes?.find((x) => x.value === data)?.label || "";
};

export const getDocTypes = (
  responseHeaderContentType: string
): {
  mimeType: string;
} => {
  let mimeType = responseHeaderContentType;
  // let contentType = responseHeaders["content-type"];
  switch (mimeType) {
    case "application/pdf":
    case "pdf":
      mimeType = "application/pdf";
      break;
    case "image/jpeg": // Uploading webp format file converted to the jpeg files
    case "jpeg":
      mimeType = "image/jpeg";
      break;
    case "png":
    case "image/png":
      mimeType = "image/png";
      break;
    case "gif":
    case "image/gif":
      mimeType = "image/gif";
      break;
    case "tiff":
    case "image/tiff":
      mimeType = "image/tiff";
      break;
    case "bmp":
    case "image/bmp":
      mimeType = "image/bmp";
      break;

    default:
      throw new Error("Document type not handled");
  }

  // To handle edge cases, incase it returns like 'application/pdf, utf-8'
  if (mimeType.startsWith("application/pdf")) {
    mimeType = "application/pdf";
  } else if (mimeType.startsWith("image/jpeg")) {
    mimeType = "image/jpeg";
  }

  return {
    mimeType,
  };
};

export const downloadDocumentBlob = (
  payload: Blob | Buffer,
  responseHeaders: any,
  axiosRequestConfig: AxiosRequestConfig,
  inputPayload: DownloadDocumentPayload
) => {
  try {
    const { mimeType } = getDocTypes(responseHeaders["content-type"]);
    const blob = new Blob([payload], {
      type: mimeType,
    });
    const dataUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = dataUrl;

    // To handle downloading of document
    if (inputPayload.case === "download") {
      switch (mimeType) {
        case "application/pdf": {
          link.download = axiosRequestConfig.params.label.includes(".pdf")
            ? axiosRequestConfig.params.label
            : `${axiosRequestConfig.params.label}.pdf`; // This is to download document
          break;
        }

        case "image/jpeg": {
          link.download = axiosRequestConfig.params.label.includes(".jpg")
            ? `${axiosRequestConfig.params.label.split(".")[0]}.jpeg`
            : `${axiosRequestConfig.params.label}.jpeg`; // This is to download document
          break;
        }

        case "image/png": {
          link.download = axiosRequestConfig.params.label.includes(".png")
            ? `${axiosRequestConfig.params.label.split(".")[0]}.png`
            : `${axiosRequestConfig.params.label}.png`; // This is to download document
          break;
        }
        case "image/gif": {
          link.download = axiosRequestConfig.params.label.includes(".gif")
            ? `${axiosRequestConfig.params.label.split(".")[0]}.gif`
            : `${axiosRequestConfig.params.label}.gif`; // This is to download document
          break;
        }
        case "image/tiff": {
          link.download = axiosRequestConfig.params.label.includes(".tiff")
            ? `${axiosRequestConfig.params.label.split(".")[0]}.tiff`
            : `${axiosRequestConfig.params.label}.tiff`; // This is to download document
          break;
        }
        case "image/bmp": {
          link.download = axiosRequestConfig.params.label.includes(".bmp")
            ? `${axiosRequestConfig.params.label.split(".")[0]}.bmp`
            : `${axiosRequestConfig.params.label}.bmp`; // This is to download document
          break;
        }

        default:
          throw new Error("Document type not handled in generating doc");
      }
    }

    // To handle view of document
    if (inputPayload.case === "view") {
      link.target = "_blank";
    }

    link.click();
    window.URL.revokeObjectURL(dataUrl);
    link.remove();
  } catch (error) {
    toasterService.error("Something went wrong while parsing document!");
    console.error("Document error", error);
  }
};
