import { ConditionalRender } from "@shared/components/ConditionalRender";
import NumberFormat from "@shared/components/NumberFormat";
import { DATE_FORMAT, NA } from "@shared/constants/app.constant";
import {
  getHomeOwnerShip,
  getProgramType,
} from "@shared/helpers/global.helper";
import { IApplication } from "@shared/interfaces/Application";
import { useAppSelector } from "@store/hooks";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { SystemInfo } from "../documentCards/SystemInfo";
import moment from "moment";

export const LoanInformation = ({
  id,
  data,
  isPrequal,
}: {
  id: string;
  data: IApplication | undefined;
  isPrequal: boolean;
}) => {
  const loanSlice = useAppSelector((state) => state.loan);

  const initialRate =
    data?.loanInformation?.currentLoanVersion?.commonLoanInfo?.xInitialRate;
  const finalRate =
    data?.loanInformation?.currentLoanVersion?.commonLoanInfo?.xFinalRate;

  const homeOwnership = useMemo(
    () =>
      data?.loanInformation?.currentLoanVersion?.commonLoanInfo
        ?.xStatedInstallationHomeOwner ||
      data?.loanInformation?.currentLoanVersion?.commonLoanInfo
        ?.xInstalaltionHomeOwner,
    [data]
  );

  const getExpiry = () => {
    if (data?.xExpirationDate) {
      return moment(data?.xExpirationDate);
    }
    // return moment(data?.loanInformation?.currentLoanVersion?.createdOn).add(
    //   data?.decisionProcessPrefs?.approvalExpirationDays,
    //   "days"
    // );
  };

  return (
    <div>
      <h3 className="h3 text-primary-dark mt-4">Loan Information</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      <Row className="mt-3">
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Product</h4>
          {id === "new" && (
            <p>
              Product Name:&nbsp;
              <ConditionalRender
                value={getProgramType(loanSlice.draftDetail?.loan_product)}
              />
            </p>
          )}
          {id !== "new" && (
            <p>
              Product Name:&nbsp;
              <ConditionalRender
                value={
                  data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                    ?.xprogramtype
                    ? getProgramType(
                        data?.loanInformation?.currentLoanVersion
                          ?.commonLoanInfo.xprogramtype
                      )
                    : null
                }
              />
            </p>
          )}
          <p>
            Term:&nbsp;
            <ConditionalRender
              value={data?.loanInformation?.currentLoanVersion?.term}
            />
          </p>
          {id === "new" ? (
            <p>
              Loan Amount:&nbsp;
              <NumberFormat amount={loanSlice.draftDetail?.loan_amount} />
            </p>
          ) : (
            <p>
              {isPrequal ? "Prequalified For" : "Amount Financed"}
              :&nbsp;
              <NumberFormat
                amount={
                  data?.loanInformation?.currentLoanVersion?.amountFinanced
                }
              />
            </p>
          )}

          <p>
            Tax Credit:&nbsp;
            <NumberFormat
              amount={
                data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                  ?.xTaxCredit
              }
            />
          </p>
          <p>
            Rate:&nbsp;
            <NumberFormat amount={initialRate} isCurrency={false} symbol="%" />
            {initialRate !== finalRate && (
              <>
                &nbsp;|&nbsp;
                <NumberFormat
                  amount={finalRate}
                  isCurrency={false}
                  symbol="%"
                />
              </>
            )}
          </p>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Payment Structure*</h4>
          <p>
            Intro Payment: &nbsp;
            <NumberFormat
              amount={data?.loanInformation?.currentLoanVersion?.payment}
            />
            &nbsp;|&nbsp;
            <NumberFormat
              amount={data?.loanInformation?.currentLoanVersion?.payment}
            />
          </p>
          <p>
            Reg Payment: &nbsp;
            <NumberFormat
              amount={
                data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                  ?.xFullyAmortizedPayment
              }
            />
            &nbsp;|&nbsp;
            <NumberFormat
              amount={
                data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                  ?.xFullyAmortizedPaymentWithV
              }
            />
          </p>
          <p>
            Final Payment: &nbsp;
            <NumberFormat
              amount={
                data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                  ?.xFinalPaymentWithNoVoluntary
              }
            />
            &nbsp;|&nbsp;
            <NumberFormat
              amount={
                data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                  ?.xFinalPaymentWithVoluntary
              }
            />
          </p>
          <p>
            <br />
          </p>
          <p>*(Payment | Payment w/ FTC)</p>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Submission Data</h4>
          <p>
            Submitted:&nbsp;
            {data?.loanInformation?.currentLoanVersion?.createdOn ? (
              <ConditionalRender
                value={
                  <Moment format={DATE_FORMAT}>
                    {data?.loanInformation?.currentLoanVersion?.createdOn}
                  </Moment>
                }
              />
            ) : (
              NA
            )}
          </p>
          <p>
            Expires:&nbsp;
            {data?.xExpirationDate ? (
              <ConditionalRender
                value={<Moment format={DATE_FORMAT}>{getExpiry()}</Moment>}
              />
            ) : (
              NA
            )}
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} sm={6} md={4}>
          <h4 className="font-bold-800">Installation Address</h4>
          {data?.loanInformation?.currentLoanVersion?.commonLoanInfo
            ?.xInstallationAddressLine ? (
            <p>
              {data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                ?.xInstallationAddressLine
                ? data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                    ?.xInstallationAddressLine
                : ""}
              {data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                ?.xInstallationCity
                ? ", " +
                  data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                    ?.xInstallationCity
                : ""}
              {data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                ?.xInstallationState
                ? ", " +
                  data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                    ?.xInstallationState
                : ""}
              {data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                ?.xInstallationZipCode
                ? " " +
                  data?.loanInformation?.currentLoanVersion?.commonLoanInfo
                    ?.xInstallationZipCode
                : ""}
            </p>
          ) : (
            <p>{NA}</p>
          )}
          <p>
            Home Ownership:&nbsp;
            <ConditionalRender value={getHomeOwnerShip(homeOwnership)} />
          </p>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <SystemInfo requestDetail={data} />
        </Col>
      </Row>
    </div>
  );
};
